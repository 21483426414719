#oes_container {
  display: flex;
  height: 100vh;
  overflow: hidden;
  background: #f7f8fa;
  flex-direction: column;
  position: relative;
}

@media screen and (min-width: 100%) {
  #oes_container {
    width: 100%;
  }
}

#oes_inner_container {
  display: flex;
  flex-direction: column;
  gap: 5%;
  margin: 0 24px 24px;
  width: calc(100% - 48px);
  overflow-x: hidden;
  overflow-y: auto;
}

#oes_bottom_view {
  bottom: 0;
  display: flex;
  position: absolute;
  height: 60px;
  align-items: center;
  width: 100%;
  background-color: white;
  justify-content: flex-end;
  padding-top: 10px;
}

#oes_button_view {
  width: 252px;
  height: 48px;
  margin-right: 24px;
}

.oes_rebuttonView {
  width: 252px;
  height: 48px;
  margin-right: 24px;
}

.oes_buttonView {
  width: 252px;
  height: 48px;
  margin-right: 24px;
}

.oes_card_view {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 24px;
}