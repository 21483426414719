.gf_container {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
}

.gf_titleView {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.gf_titleStyle {
  font-family: "RubikRegular";
  font-weight: 400;
  font-size: 16px;
  color: #0b2653;
}

.gf_filterStyle {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 12px;
}

.gf_iconStyle {
  display: flex;
  margin-left: 10px;
  width: 18px;
  height: 18px;
  color: #003049;
}

.gf_content {
  display: flex;
  width: 100%;
  height: 100%;
  background-color: white;
  box-shadow: 0px 4px 12px rgba(1, 63, 96, 0.1);
  border-radius: 8px;
  padding: 24px;
  overflow: hidden;
  flex-direction: column;
  margin-top: 15px;
  margin-bottom: 50px;
}

.gf_emtyView {
  display: flex;
  width: 100%;
  height: 290px;
  align-items: center;
  justify-content: center;
}

.gf_emtyTextStyle {
  font-size: 20px;
  font-family: 'RubikRegular';
  color: #0b2653;
}

.gf_tableInfoView {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  font-size: 12px;
}

.gf_tableView {
  display: flex;
  width: 100%;
  position: relative;
  flex-direction: row;
  background-color: white;
  border-radius: 8px;
  overflow-x: auto;
  border: solid 1px #f7f8fa;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.gf_bg_outerView {
  display: flex;
  width: 100%;
  height: 100%;
  background: white;
  flex-direction: column;
}

.gf_bg_ageView {
  display: flex;
  width: 100%;
  height: 30px;
  background: #f3c970;
}

.gf_bg_goalsView {
  display: 'flex';
  width: 100%;
  height: 200px;
  background: transparent;
}

.gf_bg_balanceView {
  display: flex;
  width: 100%;
  height: 30px;
  background: #fff4cf;
}

.gf_bg_highlightsView {
  display: flex;
  width: 100%;
  height: 30px;
  background: #f7f5f5;
}

.gf_tableContentView {
  position: absolute;
  display: flex;
  flex-direction: row
}

.gf_headerView {
  display: flex;
  width: 80px;
  flex-direction: column;
  position: sticky;
  left: 0;
}

.gf_ageStyle {
  display: flex;
  width: 100%;
  height: 30px;
  font-family: "RubikRegular";
  font-size: 14px;
  color: #0b2653;
  text-align: center;
  align-items: center;
  justify-content: center;
  background-color: #f3c970;
}

.gf_goalStyle {
  display: flex;
  width: 100%;
  height: 200px;
  padding: 10px 5px;
  background: transparent;
  align-items: center;
  justify-content: center;
  background-color: white;
}

.gf_goalTextStyle {
  display: flex;
  margin-top: 25px;
  text-align: center;
  transform: rotate(-90deg);
  font-family: "RubikRegular";
  font-size: 14px;
  color: #0b2653;
}

.gf_balanceStyle {
  display: flex;
  width: 100%;
  height: 30px;
  font-family: "RubikRegular";
  font-size: 14px;
  color: #0b2653;
  text-align: center;
  align-items: center;
  justify-content: center;
  background-color: #fff4cf;
}

.gf_highlightStyle {
  display: flex;
  width: 100%;
  height: 30px;
  font-family: "RubikRegular";
  font-size: 14px;
  color: #0b2653;
  text-align: center;
  align-items: center;
  justify-content: center;
  background-color: #fdfbfd;
}

.gf_tableContents {
  display: flex;
  width: 120px;
  flex-direction: column;
}

.gf_goalItemsView {
  display: flex;
  width: 120px;
  height: 200px;
  gap: 10px;
  padding: 10px 5px;
  overflow-y: scroll;
  flex-direction: column;
}

.gf_goalItemView {
  display: flex;
  width: 90%;
  padding: 3px;
  border: solid 2px #bdd9cd;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 2px;
}

.gf_goalLabelStyle {
  font-family: "RubikRegular";
  font-size: 12px;
  color: #0b2653;
  overflow: hidden;
  display: -webkit-box;
  word-break: break-all;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  width: 80px;
  padding-left: 10px;
}

.gf_goalItemStyle {
  display: flex;
  font-family: "RubikRegular";
  font-size: 10px;
  color: #013f60;
  flex-wrap: nowrap;
  width: 25px;
}

.gf_higelightView {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  width: 100%;
  height: 30px;
  background-color: #fdfbfd;
  align-items: center;
  justify-content: center;
}

.gf_highlightsStyle {
  display: flex;
  height: 10px;
  width: 50px;
  align-self: center;
  border-radius: 5px;
  background-color: #fec745;
}

.gf_modal {
  display: flex;
  margin: 20px;
}

.gf_modal_title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.gf_modal_heading {
  display: flex;
  font-family: 'RubikRegular';
  color: #0B2653;
  padding-top: 4px;
  font-size: 16px;
  font-weight: bold;
  padding-left: 4px;
}

.gf_modal_close {
  display: flex;
  color: #aaa;
  font-size: 25px;
  font-weight: bold;
  font-family: 'RubikRegular';
  justify-content: flex-end;
  align-items: flex-end;
}

.gf_modal_close:hover,
.gf_modal_close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.gf_tableContentsModal {
  display: flex;
  width: 100px;
  flex-direction: column;
}

.gf_goalItemsViewShowModal {
  display: flex;
  width: 100px;
  height: 350px;
  gap: 10px;
  padding: 10px 5px;
  overflow-y: scroll;
  flex-direction: column;
}

.gf_goalStyleModal {
  display: flex;
  width: 100%;
  height: 350px;
  padding: 10px 5px;
  background: transparent;
  align-items: center;
  justify-content: center;
  background-color: white;
}

.gf_filter_view {
  display: flex;
  gap: 10px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.gf_inputView {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.gf_selectionInput {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.gf_close {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.gf_searchInput {
  display: flex;
  flex-direction: column;
  gap: 18px;
  height: 125px;
}

.gf_closeIconStyle {
  display: flex;
  width: 15px;
  height: 15px;
  color: #013f60;
}

.gf_filterHeading {
  font-family: "RubikRegular";
  font-size: 16px;
  color: #013f60;
  flex-direction: row;
  margin-left: 100px;
}

.gf_actionLink {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  flex-direction: column;
}

.gf_retInput {
  display: flex;
  width: 245px;
}

.gf_apply {
  display: flex;
  width: 50%;
}