.h_container {
    display: flex;
    height: 60px;
    padding-left: 24px;
    padding-right: 24px;
    background-color: white;
    box-shadow: 0px 4px 10px rgba(1, 63, 96, 0.1);
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

@media screen and (min-width: 100%) {
    .h_container {
        width: 100%;
    }
}

.h_leftItemsView {
    display: flex;
    align-items: center;
    flex-direction: row;
}

.h_rightItemsView {
    display: flex;
    align-items: center;
    flex-direction: row;
}

.h_logoView {
    display: flex;
    width: 32px;
    height: 32px;
}

.h_titleView {
    margin-left: 16px;
    align-items: center;
    justify-content: center;
}

.h_titleStyle {
    font-family: 'RubikMedium';
    font-weight: 500;
    font-size: 20px;
    color: #0B2653;
}

.h_helpNsupportView {
    display: flex;
    flex-direction: row;
    align-items: center
}

.h_helpNsupportStyle {
    margin-right: 16px;
    font-family: RubikRegular;
    font-size: 14px;
    font-weight: 400;
    color: #0B2653;

}

.h_seperatorStyle {
    margin-left: 16px;
    margin-right: 16px;
    display: flex;
    width: 1px;
    height: 28px;
    background-color: #E0E0E0;
}