.gc_content_view {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 4px;
}
.gc_content_disableview{
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  opacity: 0.5;
  cursor:none;
}

.gc_rowStyle {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 50%;
  position: relative;
  align-items: center;
}

.gc_title {
  padding: 20px;
  font-family: 'RubikRegular';
  font-weight: 600;
  display: flex;
  font-size: 16px;
  color: #0B2653;
  align-self: flex-start;
}

.gc_title_disable {
  padding: 20px;
  font-family: 'RubikRegular';
  font-weight: 600;
  display: flex;
  font-size: 16px;
  color: #0B2653;
  align-self: flex-start;
  cursor: none;
}

.gc_priority_view {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  background: #06496D;
  height: 20px;
  width: 20px;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 4px;
}

.gc_priority_style {
  font-family: 'RubikRegular';
  font-weight: 400;
  font-size: 14px;
  color: #FFFFFF;
  text-align: center;
  vertical-align: middle;
}

.gc_amount_style {
  display: flex;
  font-family: 'RubikRegular';
  font-weight: 500;
  font-size: 20px;
  color: #0B2653;
  margin-left: 16px;
}

.gc_icon_style {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 50px;
  height: 50px;
}

.gc_descView {
  display: flex;
  margin-top: -10px;
  width: 50%;
  margin-left: 20px;
  overflow: hidden;
}

.gc_descStyle {
  font-family: "RubikRegular";
  font-size: 15px;
  color: #0b2653;
  overflow: hidden;
  display: -webkit-box;
  word-break: break-all;
  /* number of lines to show */
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
}

.gc_delete_view {
  display: flex;
  position: absolute;
  top: 1;
  right: 0;
  height: 15px;
  width: 15px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 5px;
}

.gc_delete_icon {
  display: flex;
  width: 15px;
  height: 15px;
}

.gc_more {
  cursor: pointer;
  padding: 2px;
  display: flex;
  align-self: flex-start;
}

.gc_edit_view {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 1px 1px 4px 1px rgba(6, 73, 109, 0.1);
  border-radius: 8px;
  gap: 40px;
  padding: 10px;
  position: absolute;
}

.gc_edit_row {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-self: flex-start;
  margin: 10px 5px;
  cursor: pointer;
}

.gc_edit_text {
  display: flex;
  font-family: "RubikRegular";
  font-weight: 400;
  font-size: 14px;
  color: #51668a;
  cursor: pointer;
}
