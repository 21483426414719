.cv_container {
    display: flex;
    width: 100%;
    height: 100%;
    position: relative;
}

.cv_chartView {
    display: flex;
    width: 100%;
    height: 100%;
}

.cv_chart_doughnut_view {
    display: flex;
    padding: 12px 0px;
}