.chome_container {
    display: flex;
    height: 100vh;
    overflow: hidden;
    background: #f7f8fa;
    flex-direction: column;
    position: relative;
  }
  
  .chome_content_view {
    display: flex;
    width: calc(100% - 48px);
    margin: 0px 24px 24px 24px;
    overflow-y: scroll;
    flex-direction: column;
    margin-bottom: 50px;
  }
  
  .chome_profile_view {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 70px;
  }
  
  .chome_icon {
    display: flex;
    margin-bottom: 16px;
  }
  
  .chome_info_view {
    font-family: "RubikRegular";
    font-weight: 400;
    font-size: 16px;
    color: #51668a;
    width: 579px;
    height: 44px;
    text-align: center;
  }
  
  .chome_add_property {
    display: flex;
    width: 300px;
    margin-left: 24px;
    margin-top: 20px;
    height: 48px;
  }

  @media screen and (min-width: 100%) {
    .chome_container {
      width: 100% 
    }
  }