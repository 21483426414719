.breadcrumb {
  background: #F7F8FA;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: 'RubikRegular';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #51668A;
}

.breadcrumb_style{
  display: flex;
  padding-right: 5px;
}

.arrowStyle{
  display: flex;
  margin-left: 12px;
  color: white;
}