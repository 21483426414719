.ndp_container {
  display: flex;
  height: 100vh;
  overflow: hidden;
  background: #F7F8FA;
  flex-direction: column;
  position:relative;
}

.ndp_content_view {
  display: flex;
  width: calc(100% - 48px);
  margin: 0px 24px 24px 24px;
  overflow-y: scroll;
  flex-direction: column;
  margin-bottom: 50px;
}

.ndp_input {
  display: flex;
  width: 429px;
  margin-right: 24px;
}

.ndp_detail_view {
  display: flex;
  flex-direction: column;
}

.ndp_detail_text {
  display: flex;
  font-family: 'RubikRegular';
  font-weight: 500;
  font-size: 18px;
  color: #0B2653;
}

.ndp_description_view {
  display: flex;
  flex-direction: column;
  margin: 33px 0px 0px 0px;
}

.ndp_input_row {
  display: flex;
  flex-direction: row;
}

.ndp_input_sec_row {
  display: flex;
  margin: 33px 0px 0px 0px;
}

.ndp_input_lumb_row {
  display: flex;
  margin: 0px 0px 18px 0px;
}

.ndp_cost_item_view {
  display: flex;
  width: 881px;
  flex-direction: column;
}

.ndp_seperatorStyle {
  margin: 24px 0px;
  border: 1px solid #E0E0E0;
}

.ndp_start_year_row {
  display: flex;
  flex-direction: column;
  width: 429px;
  margin-right: 24px;
}

.ndp_start_text {
  font-family: 'RubikRegular';
  font-weight: 400;
  font-size: 16px;
  color: #0B2653;
}

.ndp_slider_content {
  display: flex;
  width: 100%;
  margin: 50px 0px 24px 0px;
}
.ndp_sliderStyle{
  display: flex;
  width: 100%;
  flex-direction: row;
}
.ndp_bottomView {
  display: flex;
  width:100%;
  height: 50px;
  background-color: white;
  position: absolute;
  bottom: 0;
  align-items: center;
  justify-content: end;
}

.ndp_buttonView {
  display: flex;
  padding: 10px;
}

.ndp_material_button {
  display: flex;
  width: 252px;
  margin-left: 24px;
}

.ndp_save {
  display: flex;
  width: 252px;
  margin-left: 24px;
}

.ndp_toggle_container {
  display: flex;
  margin-top: 31px;
  gap: 24px;
}

.ndp_toggle_view {
  display: flex;
}

.ndp_toggle_input {
  display: flex;
  margin-right: 24px;
  font-family: 'RubikRegular';
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  color: #0B2653;
  gap: 15px;
}

.ndp_tier_text {
  font-family: 'RubikRegular';
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  color: #0B2653;
}

@media screen and (min-width: 100%) {
  .ndp_container {
    width: 100% 
  }
}