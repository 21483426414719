.rm_container {
  display: flex;
  padding: 20px;
  align-items: center;
  justify-content: center;
}

.rm_content {
  display: flex;
  align-self: center;
  flex-direction: column;
}

.rm_headerView {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.rm_headingStyle {
  color: #013F60;
  font-family: RubikRegular;
  font-size: 24px;
  font-weight: 400;
}

.rm_tableView {
  display: flex;
  margin: 24px 0;
  width: 100%;
  height: 100;
}

.ag-theme-alpine {
  --ag-header-height: 30px;
  --ag-foreground-color: #0B2653;
  --ag-header-foreground-color: #0B2653;
  --ag-header-background-color: black;
  --ag-font-size: 12px;
  --ag-font-family: 'RubikRegular';
  --ag-border-radius: 5px;
  --ag-border-width: 0;
}

.ag-theme-alpine .ag-header {
  font-family: 'RubikRegular';
  font-weight: 600;
  background: #DEEDF5;
}

.ag-theme-alpine .ag-header-cell {
  color: #013F60;
  font-family: RubikRegular;
  font-size: 14px;
  font-weight: 400;
}