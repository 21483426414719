.gp_container {
  display: flex;
  width: 197px;
  height: 300px;
  position: relative;
}

.gp_contentView {
  position: absolute;
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
}

.gp_baseView {
  display: flex;
  width: 100%;
  height: 100%;
}

.gp_iconStyle {
  display: flex;
  margin-top: 50px;
  width: 40px;
  height: 40px;
}

.gp_textView {
  display: flex;
  margin-top: 10px;
  width: 157px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 70px;
}

.gp_title {
  display: flex;
  font-family: "RubikRegular";
  font-weight: 700;
  font-size: 14px;
  color: white;
  text-align: center;
  padding: 2px;
}

.gp_info {
  display: flex;
  margin-top: 8px;
  font-family: "RubikRegular";
  font-weight: 400;
  font-size: 10px;
  width: 126px;
  color: whitesmoke;
  text-align: center;
}

.gp_priority_number {
  display: flex;
  margin-top: 65px;
  font-family: "RubikRegular";
  font-weight: 400;
  font-size: 28px;
  text-align: center;
  color: white;
}

.gp_buttonView {
  display: flex;
  margin-top: 24px;
  width: 144px;
  height: 40px;
}
