.ba_container {
  display: flex;
  width: 100%;
  height: 100%;
}

.ba_content {
  display: flex;
  width: 100%;
  height: 100%;
}

.ba_bannersView {
  display: flex;
  width: 100%; 

}


.ba_bannerContentView {
  display: flex;
  width: 45%;
  flex-direction: column;
  margin: 50px 100px 50px 100px;

}
@media (max-width: 500px) {
  .ba_bannerContentView{
     margin: 50px ;
  }
}

.ba_labelStyle_1 {
  color: #865D06;
  font-family: RubikRegular;
  font-size: 20px;
  font-weight: 400;
}

.ba_labelStyle_2 {
  color: #865D06;
  font-family: RubikMedium;
  font-size: 60px;
  font-weight: 500;
}

.ba_labelStyle_3 {
  color: #84785E;
  font-family: RubikRegular;
  font-size: 18px;
  font-weight: 400;
}
@media (max-width: 1024px) {
  .ba_labelStyle_1 {
    font-size: 18px; 
  }

  .ba_labelStyle_2 {
    font-size: 45px; 
  }

  .ba_labelStyle_3 {
    font-size: 16px; 
  }
}


@media (max-width: 768px) {
  .ba_labelStyle_1 {
    font-size: 16px; 
  }

  .ba_labelStyle_2 {
    font-size: 35px; 
  }

  .ba_labelStyle_3 {
    font-size: 14px; 
  }
}

.ba_startButtonView {
  display: flex;
  margin-top: 40px;
  width: 180px;
}