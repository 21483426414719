.cp_container {
  display: flex;
  overflow: hidden;
  background: #f7f8fa;
  flex-direction: column;
  height: 100vh;
  position: relative;
}

@media screen and (min-width: 100%) {
  .cp_container {
    width: 100%
  }
}

.cp_content_view {
  display: flex;
  width: calc(100% - 48px);
  margin: 20px 24px 24px 24px;
  overflow-y: scroll;
  flex-direction: column;
  padding-bottom: 70px;
}

.cp_view {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.cp_name_view {
  display: flex;
  flex-direction: row;
}

.cusprofileFirstName {
  display: flex;
  margin-right: 30px;
}

.cp_secondname_view {
  display: flex;
}

.cp_dob_gender {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.cp_dob_section {
  width: 429px;
  margin-right: 30px;
}

.cp_retInput {
  display: flex;
  width: 429px;
}

.cp_PANView {
  display: flex;
  flex-direction: column;
  margin-top: 12px;
}

.cp_gender_view {
  display: flex;
  flex-direction: row;
}

.cp_verifiedPAN {
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding-left: 285px;

}

.cp_verificationPAN {
  display: flex;
  width: 95%;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: flex-end;
  height: 10px;
  margin-top: 5px;
}

.cp_verifiedPanLabel {
  font-family: 'RubikRegular';
  font-size: 12px;
  color: #0B2653;
}

.cp_verifyPanLabel {
  text-decoration: underline;
  font-family: 'RubikRegular';
  font-size: 12px;
  color: #0B2653;
  cursor: pointer;
}

.cp_verifyPAN {
  display: flex;
  margin-left: 20px;
  width: 15px;
}

.cp_verifyPANView {
  display: flex;
  flex-direction: column;
  margin-top: 2px;
  margin-left: 5px;
  align-items: flex-end;
  justify-content: flex-end;
}

.cp_toggle_gender_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

}

.cp_genderLabel {
  display: flex;
  font-family: 'RubikRegular';
  font-size: 16px;
  color: #0B2653;
  margin-right: 18px;
}


.cp_maritalstatusView {
  display: flex;
  align-items: center;
  margin-left: 80px;
}

.cp_maritalLabel {
  display: flex;
  font-family: 'RubikRegular';
  font-size: 16px;
  color: #0B2653;
  margin-right: 18px;
}

.cp_toggle_maritalcontainer {
  display: flex;
}

.cp_resident_view {
  display: flex;
  flex-direction: row;
}

.cp_residentstatusView {
  display: flex;
  align-items: center;
  margin-left: 5px;
}

.cp_toggle_input {
  display: flex;
  margin-right: 24px;
  font-family: 'RubikRegular';
  font-size: 14px;
  text-align: center;
  color: #0B2653;
  gap: 15px;
}

.cp_iconStyle {
  display: flex;
  width: 70px;
  height: 70px;
}

.cp_marital_text {
  font-family: 'RubikRegular';
  font-size: 14px;
  text-align: center;
  color: #0B2653;
}

.cp_gender {
  display: flex;
  flex-direction: row;
  gap: 24px;
}

.cp_expenseType {
  display: flex;
  flex-direction: row;
  margin-right: 272px;
}

.cp_gender_toggles {
  display: flex;
  margin-right: 30px;
}

.cp_marital {
  display: flex;
  flex-direction: row;
  gap: 20px;

}


.cp_spouseLabel {
  display: flex;
  font-family: 'RubikRegular';
  font-size: 16px;
  color: #0B2653;
  padding-bottom: 10px;

}

.cp_contactLabel {
  display: flex;
  font-family: 'RubikMedium';
  font-size: 16px;
  color: #0B2653;
  padding-bottom: 10px;

}

.cp_spouseFirstName {
  display: flex;
  margin-right: 30px;
}

.cp_emailId {
  display: flex;
  margin-right: 40px;
}

.cp_spouse_view {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.cp_contact_view {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 30px;

}

.cp_spouseVerifyPAN {
  display: flex;
  flex-direction: row;
  margin-top: 2px;
  margin-left: 5px;
}


.cp_toggle_gender {
  display: flex;
  flex-direction: row;
  margin-right: 24px;
  font-family: 'RubikRegular';
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  color: #0B2653;
  gap: 15px;
}

.cp_slider_content {
  display: flex;
  width: 100%;
  margin: 40px 0px 24px 0px;
}


.custprofile_year_value {
  display: flex;
  font-family: 'RubikRegular';
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  vertical-align: middle;
  align-items: center;
  justify-content: center;
  color: #0B2653;
  border-radius: 4px;
}

.cp_yearsLabel {
  display: flex;
  position: absolute;
  right: 40px;
  top: 0px;
  font-family: "RubikRegular";
  font-weight: 400;
  font-size: 16px;
  color: #013f60;
  text-align: left;
}

.cp_toggle_selectedgender {
  display: flex;
  flex-direction: row;
  margin-right: 24px;
}

.cp_genderIcon {
  display: flex;
  width: 70px;
  height: 70px;
}

.cp_seperatorStyle {
  margin: 24px 0px;
  border: 1px solid #E0E0E0;
}

.cp_spouseName {
  display: flex;
}

.cp_emailId_phone {
  display: flex;
}

.cp_spousePanNumber {
  display: flex;
  flex-direction: column;
  margin-top: 12px;
}

.cp_address {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-right: 40px;
}

.cp_state {
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-top: 20px;
}

.cp_spousedob {
  display: flex;
  flex-direction: row;
}

.cp_spousePanDobView {
  display: flex;
  flex-direction: column;
}

.cp_yearsView {
  display: flex;
  flex-direction: row;
}

.cp_retirementPlan {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.cp_slider_view {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 0px 24px 0px 0px;
  justify-content: flex-start;
}

.cusprofileSpouseDOB {
  display: flex;
  flex-direction: column;
}

.cp_start_year_row {
  display: flex;
  flex-direction: column;
  width: 429px;
  margin-right: 40px;
}

.cp_start_text {
  font-family: 'RubikRegular';
  font-weight: 400;
  font-size: 16px;
  color: #0B2653;
}

.cp_retirementLabel {
  font-family: 'RubikRegular';
  font-weight: 400;
  font-size: 14px;
  color: #0B2653;
}

.cp_retInputView {
  display: flex;
  width: 429px;
}

.cp_bottom_view {
  bottom: 0;
  display: flex;
  width: 100%;
  background-color: white;
  justify-content: flex-end;
  position: absolute;
}

.cp_button_view {
  display: flex;
  width: 150px;
  height: 48px;
  margin-top: 10px;
  margin-right: 24px;
}

.cp_description_view {
  display: flex;
  flex-direction: row;
}

.cp_input {
  display: flex;
  width: 429px;
  margin-right: 24px;
}

.cp_expense {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.cp_expense_text {
  font-family: 'RubikRegular';
  font-weight: 400;
  font-size: 16px;
  color: #0B2653;
  margin-right: 24px;
}

.cp_residentLabel {
  display: flex;
  font-family: 'RubikRegular';
  font-size: 16px;
  color: #0B2653;
  margin-right: 24px;
}

.cp_retLabel {
  display: flex;
  font-family: 'RubikRegular';
  font-size: 16px;
  color: #0B2653;
}

.cp_successModal_dispaly {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 10px;
}

.cp_successModal {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30%;
  margin-top: 10%;
}

.cp_successModal-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.cp_successModal_heading {
  display: flex;
  font-family: 'RubikRegular';
  color: #0B2653;
  padding-top: 20px;
  font-size: 16px;
  text-align: center;
}

.cp_successModal_close {
  display: flex;
  color: #aaa;
  width: 100%;
  font-size: 25px;
  font-weight: bold;
  font-family: 'RubikRegular';
  align-items: flex-end;
  justify-content: flex-end;
}

.cp_successModal_close:hover,
.cp_successModal_close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.cp_successModal_msg {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cp_successlIcon {
  display: flex;
  width: 150px;
  height: 120px;
}

.cp_ok {
  display: flex;
  width: 50%;
  padding-top: 20px;
  align-self: center;
}

.cp_dob_section {
  width: 429px;
  margin-right: 30px;
  margin-top: 12px;
}

.cp_DateSelectionView {
  display: flex;
  margin-top: 12px;
  flex-direction: row;
  gap: 40px;
}

.cp_offsetInputView {
  display: flex;
  margin-top: 24px;
  width: 429px;
}


.cp_calculate_age {
  text-decoration: underline;
  font-family: 'RubikRegular';
  font-size: 12px;
  color: #0B2653;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 95%;
  height: 10px;
  padding-top: 5px;
  cursor: pointer;
}

.cp_securityNroleView {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 24px;
  margin-top: 1%;
}

.cp_port_view {
  display: flex;
  margin-top: 1%;
  gap: 24px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.cp_port_row {
  display: flex;
  gap:48px
}