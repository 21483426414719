.emi_container {
  display: flex;
  height: 100vh;
  overflow: hidden;
  background: #F7F8FA;
  flex-direction: column;
  position: relative;
}

@media screen and (min-width: 100%) {
  .emi_container {
    width: 100%;
  }
}

.emi_content_view {
  display: flex;
  width: calc(100% - 48px);
  margin: 0px 24px 24px 24px;
  overflow-x: hidden;
  overflow-y: scroll;
  flex-direction: column;
  padding-bottom: 50px;
}

.emi_description_view {
  display: flex;
  flex-direction: row;
  margin: 33px 24px 0px 0px;
}

.emi_input {
  display: flex;
  width: 429px;
  margin-right: 24px;
}

.emi_expense {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.emi_expense_text {
  font-family: 'RubikRegular';
  font-weight: 400;
  font-size: 16px;
  color: #0B2653;
  margin-right: 24px;
}

.emi_toggle {
  display: flex;
  width: 109px;
}

.emi_seperatorStyle {
  margin: 24px 0px;
  border: 1px solid #E0E0E0;
}

.emi_slider_view {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 0px 24px 0px 0px;
  justify-content: flex-start;

}

.emi_start_year_row {
  display: flex;
  flex-direction: column;
  width: 429px;
  margin-right: 24px;
}

.emi_start_text {
  font-family: 'RubikRegular';
  font-weight: 400;
  font-size: 16px;
  color: #0B2653;
}

.emi_slider_content {
  display: flex;
  width: 100%;
  margin: 50px 0px 24px 0px;
}

.emi_premium_view {
  width: 429px;
  display: flex;
}

.emi_bottomView {
  display: flex;
  width: 100%;
  height: 60px;
  padding-right: 24px;
  background-color: white;
  position: absolute;
  bottom: 0;
  align-items: center;
  justify-content: flex-end;
}

.emi_buttonView {
  height: 38px;
  display: flex;
  align-items: center;
}

.emi_link_text {
  font-family: 'RubikRegular';
  font-weight: 500;
  font-size: 16px;
  text-decoration-line: underline;
  color: #003049;
  width: 129px;
  display: flex;
  vertical-align: middle;
}

.emi_save {
  display: flex;
  width: 252px;
  margin-left: 24px;
}