.dav_container {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 2%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: #FFF1BE;
  animation: blink_animation 1s infinite;
  border-radius: 5px;
}

@keyframes blink_animation {
  0% {
    opacity: 1;
  }

  /* Start with fully visible */
  50% {
    opacity: 0.7;
  }

  /* Fade out at 50% of the animation */
  100% {
    opacity: 1;
  }

  /* Fully visible again at the end */
}

.dav_textStyle {
  display: flex;
  font-family: RubikRegular;
  font-size: 14px;
  color: #0B2653;
}

.dav_buttonTextStyle {
  display: flex;
  font-family: RubikRegular;
  font-size: 14px;
  color: #0173b0;
  text-decoration: underline;
}