.rpe_container {
  display: flex;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background: #f7f8fa;
  flex-direction: column;
}

.rpe_content {
  display: flex;
  width: calc(100%-48px);
  margin: 0px 24px 24px 24px;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
}

.rpe_profileView {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.rpe_start_icon {
  display: flex;
  flex-direction: row;
  width: 350px;
  padding-right: 40px;
  position: absolute;
}

.rpe_end_icon {
  display: flex;
  width: 300px;
  padding-top: 30px;
}

.rpe_labelStyle {
  margin: 24px;
  font-family: "RubikMedium";
  font-size: 24px;
  color: #0b2653;
}

.rpe_info_view {
  width: 700px;
  height: 50px;
  left: 205px;
  top: 440px;
  font-family: "RubikRegular";
  font-weight: 700;
  font-size: 16px;
  text-align: center;
  color: #51668a;
  margin-top: 32px;
}

.rpe_normaltext {
  width: 700px;
  height: 50px;
  left: 205px;
  top: 440px;
  font-family: "RubikRegular";
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #51668a;
  padding-left: 5px;
}

.rpe_buttonsView {
  display: flex;
  flex-direction: row;
  margin-top: 24px;
  align-items: center;
  justify-content: center;
}

.rpe_buttonView {
  width: 252px;
  height: 48px;
  margin-right: 24px;
}

.rpe_modalContainer {
  display: flex;
  margin-top: 35%;
  margin-left: 35%;
  width: 70%;
  height: 50%;
  border-radius: 18px;
  align-self: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.rpe_modalContent {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.rpe_modalLabelStyle {
  font-family: "RubikMedium";
  font-weight: 500;
  font-size: 24px;
  color: #0b2653;
  display: flex;
  text-align: center;
  flex-direction: row;
}

.rpe_modalButtonView {
  margin-top: 24px;
  display: flex;
  width: 120px;
  height: 48px;
}
