.hdetail_container {
    display: flex;
    height: 100vh;
    overflow: hidden;
    background: #F7F8FA;
    flex-direction: column;
    position: relative;
  }
  
  .hdetail_content_view {
    display: flex;
    width: calc(100% - 48px);
    margin: 0px 24px 24px 24px;
    overflow-y: scroll;
    flex-direction: column;
    margin-bottom: 50px;
  }
  
  .hdetail_input {
    display: flex;
    width: 429px;
    margin-right: 24px;
  }
  
  .hdetail_description_view {
    display: flex;
    margin: 24px 0px;
  }
  
  .hdetail_seperatorStyle {
    margin: 24px 0px;
    border: 1px solid #E0E0E0;
  }
  
  .hdetail_start_year_row {
    display: flex;
    flex-direction: column;
    width: 429px;
    margin-right: 24px;
  }
  
  .hdetail_start_text {
    font-family: 'RubikRegular';
    font-weight: 400;
    font-size: 16px;
    color: #0B2653;
  }
  
  .hdetail_slider_content {
    display: flex;
    width: 100%;
    margin: 50px 0px 24px 0px;
  }
  
  .hdetail_bottomView {
    display: flex;
    width: 100%;
    height: 50px;
    background-color: white;
    position: absolute;
    bottom: 0;
    align-items: center;
    justify-content: end;
  }
  
  .hdetail_buttonView {
    display: flex;
    padding: 10px;
  }
  
  .hdetail_save {
    display: flex;
    width: 252px;
    margin-left: 24px;
  }

  @media screen and (min-width: 100%) {
    .hdetail_container {
      width: 100% 
    }
  }