.bs_container {
    display: flex;
    height: 100vh;
    overflow: hidden;
    flex-direction: column;
    position: relative;
}

@media screen and (min-width: 100%) {
    .bs_container {
        width: 100%;
    }
}

.bs_contentContainer {
    display: flex;
    width: 100%;
    height: calc(100vh - 90px);
    flex-direction: row;
    background-color: #e5e5e5;
    overflow-y: scroll;
}

.bs_formView {
    display: flex;
    width: 50%;
    height: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
@media (max-width: 768px) {
  .bs_formView { 
    
   width: 100%;
  }
}

.bs_formContentView {
    display: flex;
    width: 80%;
    height: 80%;
    align-self: center;
    background-color: white;
    justify-content: space-between;
}
@media (max-width: 768px) {
  .bs_formContentView {
       border-radius: 8px;
  }
}

.bs_adviserView {
    display: flex;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 12px;
    align-items: center;
    flex-direction: column;
}

.bs_adviserLabelStyle {
    font-family: 'RubikRegular';
    font-size: 14px;
    font-weight: 400;
    color: #51668A;
}

.bs_adviserImagesView {
    display: flex;
    margin-top: 10px;
    width: 290px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.bs_itemsView {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.bs_itemTextStyle {
    margin-top: 8px;
    font-family: 'RubikRegular';
    font-size: 12px;
    color: #51668A;
}

.bs_carouselView {
    display: flex;
    width: 50%;
    height: 100%;
}
@media (max-width: 768px) {
  .bs_carouselView {
    display: none; 
  }
}

.bs_bottomView {
    display: flex;
    width: 100%;
    height: 40px;
    position: absolute;
    bottom: 0;
    box-shadow: 1px 1px 4px 1px rgba(6, 73, 109, 0.1);
}