@font-face {
    font-family: 'RubikRegular';
    src: local('RubikRegular'),
        url('./Rubik-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'RubikLight';
    src: local('RubikLight'),
        url('./Rubik-Light.ttf') format('truetype');
}

@font-face {
    font-family: 'RubikMedium';
    src: local('RubikMedium'),
        url('./Rubik-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'RubikBold';
    src: local('RubikBold'),
        url('./Rubik-Bold.ttf') format('truetype');
}