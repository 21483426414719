.em_container {
  display: flex;
  width: 60vw;
  height: 98vh;
  margin: 1vh 20vw;
  padding: 1%;
  overflow: hidden;
}

.em_content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.em_formContent {
  display: flex;
  width: 100% !important;
  align-self: center;
  flex-direction: column;
}

.em_headerView {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.em_headingStyle {
  color: #013F60;
  font-family: RubikRegular;
  font-size: 24px;
  font-weight: 400;
}

.em_input {
  display: flex;
  width: 50%;
}

.em_inputsContentView {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  gap: 24px;
}

.em_labelStyle {
  display: flex;
  color: #474747;
  font-family: RubikRegular;
  font-size: 16px;
  font-weight: 400;
}

.em_textAreaView {
  display: flex;
  width: 99%;
  flex-direction: column;
}

.em_textLabelStyle {
  display: flex;
  margin: 10px 0;
  color: #013F60;
  font-family: RubikRegular;
  font-size: 16px;
  font-weight: 500;
  text-align: left;
}

.em_textAreaStyle {
  display: flex;
  width: 100%;
  height: 120px;
  color: #0b2653;
  font-family: RubikRegular;
  font-size: 14px;
  font-weight: 400;
  border: 1px solid rgba(81, 102, 138, 0.37);
  border-radius: 8px;
}

.em_textAreaStyle:focus {
  border: 1px solid rgba(81, 102, 138, 0.37) !important;
  outline: none !important;

}

.em_buttonView {
  display: flex;
  width: 30%;
  margin-top: 24px;
  align-self: center;
  align-items: center;
  justify-content: center;
}

.em_rowStyle {
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
}

.em_subLabelStyle {
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  padding-left: 5px;
  color: #013F60;
  font-family: RubikRegular;
  font-size: 14px;
  font-weight: 400;
}

.em_responseContentView {
  display: flex;
  width: 100% !important;
  align-self: center;
  flex-direction: column;
}

.em_responseView {
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin: 30px;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.em_successStyle {
  display: flex;
  width: 200px;
  height: 150px;
}

.em_responseStyle {
  margin-top: 24px;
  display: flex;
  width: 100%;
  color: #013F60;
  font-family: RubikRegular;
  font-size: 20px;
  font-weight: 400;
  text-align: center;
}