.npsdb_container {
  display: flex;
  height: 100vh;
  overflow: hidden;
  background: #f7f8fa;
  flex-direction: column;
  position:relative;
}

.npsdb_content_view {
  display: flex;
  width: calc(100% - 80px);
  margin: 0px 24px 0px 24px;
  overflow-y: scroll;
  flex-direction: column;
  margin-bottom: 50px;
}

.npsdb_card_view {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 24px;
}

.npsdb_expect_value_view {
  display: flex;
  background: #FFDC62;
  border: 1px solid #DCAC00;
  border-radius: 4px;
  height: 56px;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
}

.npsdb_value_text {
  display: flex;
  font-family: 'RubikRegular';
  font-weight: 500;
  font-size: 20px;
  text-align: center;
  color: #0B2653;
  margin: 16px 0px;
}

.npsdb_bottomView {
  display: flex;
  width:100%;
  height: 50px;
  background-color: white;
  position: absolute;
  bottom: 0;
  align-items: center;
  justify-content: end;
}

.npsdb_buttonView {
  display: flex;
  padding:10px;
}

.npsdb_addmore_button {
  display: flex;
  width: 252px;
  margin-left: 24px;
}

.npsdb_save {
  display: flex;
  width: 252px;
  margin-left: 24px;
}

@media screen and (min-width: 100%) {
  .npsdb_container {
    width: 100% 
  }
}