.wed_container {
  display: flex;
  height: 100vh;
  overflow: hidden;
  background: #F7F8FA;
  flex-direction: column;
  position: relative;
}

@media screen and (min-width: 100%) {
  .wed_container {
    width: 100%;
  }
}

.wed_content_view {
  display: flex;
  width: calc(100% - 48px);
  margin: 0px 24px 24px 24px;
  overflow-y: scroll;
  flex-direction: column;
  padding-bottom: 50px;
}

.wed_description_view {
  display: flex;
  flex-direction: column;
  margin: 33px 0px 0px 0px;
}

.wed_input {
  display: flex;
  width: 429px;
  margin-right: 24px;
}

.wed_input_row {
  display: flex;
  flex-direction: row;
}

.wed_input_sec_row {
  display: flex;
  margin: 33px 0px 0px 0px;
}

.wed_seperatorStyle {
  margin: 24px 0px;
  border: 1px solid #E0E0E0;
}

.wed_slider_view {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-start;
}

.wed_start_year_row {
  display: flex;
  flex-direction: column;
  width: 429px;
  margin-right: 24px;
}

.wed_start_text {
  font-family: 'RubikRegular';
  font-weight: 400;
  font-size: 16px;
  color: #0B2653;
}

.wed_slider_content {
  display: flex;
  width: 100%;
  margin: 50px 0px 24px 0px;
}

.wed_cost_item_view {
  display: flex;
  margin: 24px 0px;
  width: 881px;
}

.wed_bottomView {
  display: flex;
  width: 100%;
  height: 60px;
  padding-right: 24px;
  background-color: white;
  position: absolute;
  bottom: 0;
  align-items: center;
  justify-content: flex-end;
}

.wed_buttonView {
  height: 38px;
  display: flex;
  align-items: center;
}

.wed_link_text {
  font-family: 'RubikRegular';
  font-weight: 500;
  font-size: 16px;
  text-decoration-line: underline;
  color: #003049;
  width: 129px;
  display: flex;
  vertical-align: middle;
}

.wed_expense_button {
  display: flex;
  width: 252px;
  margin-left: 24px;
}

.wed_save {
  display: flex;
  width: 252px;
  margin-left: 24px;
}