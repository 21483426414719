.cem_modal_view {
  border-radius: 18px;
  width: 60%;
  height: 100%;
}

.cem_modal_content {
  display: flex;
  gap: 10px;
  flex-direction: column;
}

.cem_titleView {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.cem_titleStyle {
  font-family: "RubikRegular";
  font-size: 16px;
  color: #013f60;
}

.cem_iconStyle {
  display: flex;
  width: 20px;
  height: 20px;
  color: #013f60;
}

.cem_contentView {
  gap: 10px;
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.cem_inputsView {
  display: flex;
  gap: 10px;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.cem_ageNgrowthView {
  display: flex;
  margin-top: 24px;
  margin-left: -45px;
  align-self: center;
  flex-direction: row;
  gap: 25px;
}

.cem_ageView {
  display: flex;
  width: 30%;
  align-self: flex-start;
  flex-direction: column;
}

.cem_label_text {
  display: flex;
  font-size: 10px;
  margin-top: 5px;
  color: #013f60
}

.cem_input {
  display: flex;
}

.cem_extraTextStyle {
  display: flex;
  margin-left: 5%;
  font-family: "RubikRegular";
  font-size: 16px;
  color: #013f60;
}

.cem_buttonView {
  display: flex;
  margin-top: 24px;
  width: 100px;
  align-self: center;
  align-items: center;
  justify-content: center;
}