.cpy_container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 84px;
  justify-content: space-between;
  align-items: center;
  background: rgba(239, 183, 65, 0.2);
  border-radius: 4px;
}

.cpy_left_view {
  display: flex;
  margin-left: 24px;
  width: 30%;
  align-items: center;
}

.cpy_info_icon {
  display: flex;
  width: 24px;
  height: 24px;
}

.cpy_labelStyle {
  margin-left: 12px;
  font-family: "RubikRegular";
  font-weight: 400;
  font-size: 16px;
  text-transform: capitalize;
  color: #013f60;
  flex-wrap: wrap;
}

.cpy_right_view {
  display: flex;
  margin-right: 24px;
  width: 70%;
  flex-direction: row;
  align-items: center;
}

.cpy_left_arrow {
  display: flex;
  width: 30px;
  height: 30px;
  color:#0b2653;
  background: #ffffff;
  box-shadow: 0px 2px 12px rgba(1, 63, 96, 0.1);
  border-radius: 50%;
  cursor: pointer;
}

.cpy_contentView {
  width: calc(100% - 60px);
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  margin-left: 16px;
  margin-right: 16px;
  scroll-behavior: smooth;
}

.cpy_array_view {
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
  min-width: 50px;
  flex-direction: row;
  align-items: center;
}

.cpy_value {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cpy_value_content1 {
  font-family: "RubikRegular";
  font-weight: 400;
  font-size: 14px;
  color: #51668a;
}

.cpy_value_content2 {
  font-family: "RubikRegular";
  font-weight: 400;
  font-size: 16px;
  text-align: right;
  color: #0b2653;
}

.cpy_separatorView {
  box-sizing: border-box;
  width: 44px;
  height: 0px;
  background: rgba(239, 183, 65, 0.15);
  border: 1px solid #efb741;
  transform: matrix(0, 1, 1, 0, 0, 0);
}

.cpy_right_arrow {
  display: flex;
  width: 30px;
  height: 30px;
  background: #ffffff;
  color:#0b2653;
  box-shadow: 0px 2px 12px rgba(1, 63, 96, 0.1);
  border-radius: 50%;
  cursor: pointer;
}

.cpy_disable_arrow {
  display: flex;
  width: 30px;
  height: 30px;
  color:#0b2653;
  background: #fcf5f5;
  box-shadow: 0px 2px 12px rgba(1, 63, 96, 0.1);
  border-radius: 50%;
  cursor: none;
  opacity: 0.5;
}
