.odv_container {
  display: flex;
  width: 80vw;
  height: 93vh;
  margin: 1vh 10vw;
  padding: 1%;
  overflow: hidden;
}

.odv_content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.odv_titleView {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 20px;
  justify-content: space-between;
  align-items: center;
}

.odv_titleStyle {
  color: #013F60;
  font-family: RubikRegular;
  font-size: 24px;
  font-weight: 400;
}

.odv_tableView {
  display: flex;
  margin: 20px 0;
  width: 100%;
}

.odv_buttonsView {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  gap: 24px;
  margin: 24px 0;
}

.odv_buttonView {
  display: flex;
  width: 20%;
}