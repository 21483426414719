.collapseable-container {
    top: 70px;
    right:0;
    z-index: 999;
    display: flex;
    position: absolute;
    flex-direction: row;
    transition: right ease 0.2s;
  }
  
  .open {
    right: 10;
  }
  
  .open .collapseable-view {
    display: none;
  }
  
  .openclose-icon-view {
    display: flex;
    margin-top: 10px;
    height: fit-content;
    box-sizing: border-box;
    justify-content: center;
    padding: 5px 2px 5px 5px;
    border-radius: 18px 0px 0px 18px;
  }
  
  .collapseable-view {
    display: flex;
  }
  
  .collapseable-content {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    border-radius: 10px;
    box-sizing: border-box;
    justify-content: center;
    background-color: #FFFFFF;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.12);
  }
  
  .collapseable-text {
    width: 60%;
    font-size: 14px;
    color: black;
    font-weight: bold;
    text-align: right;
  }
  
  .collapseable-icon {
    width: 20%;
    font-size: 35px;
    color: #6779b3;
    margin-left: 10px;
  }