.rs-modal-content {
  padding: 32px;
}

.rm_modal_view {
  border-radius: 18px;
  width: auto;
  height: 100%;
  margin-top: 40vh;
  margin-left: 10vw;
  overflow-y: scroll;
}

.rm_title_view {
  display: grid;
  grid-template-columns: 11fr 1fr;
  margin-bottom: 24px;
}

.rm_title_style {
  display: grid;
  justify-self: center;
  text-align: center;
  font-family: "RubikRegular";
  font-size: 20px;
  color: #0b2653;
}

.rm_cancel_icon {
  display: grid;
  justify-self: end;
  margin-block-end: auto;
  margin-top: -5px;
}

.rm_info_view {
  font-size: 1rem;
  color: #0b2653;
  text-align: center;
  margin-bottom: 32px;
  font-family: "RubikRegular";
}

.rm_buttonView {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.rm_no_button {
  display: flex;
  min-width: 162px;
  max-width: auto;
}

.rm_yes_button {
  display: flex;
  min-width: 162px;
  max-width: auto;
}

.rm_single_button {
  display: flex;
  margin-top: 32px;
  width: 240px;
}
