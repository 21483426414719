.cam_container {
  display: flex;
  height: 100vh;
  overflow: hidden;
  background: #f7f8fa;
  flex-direction: column;
  position: relative;
}

@media screen and (min-width: 100%) {
  .cam_container {
    width: 100%;
  }
}

.cam_content {
  display: flex;
  width: calc(100% - 48px);
  height: 100%;
  margin: 24px;
  overflow: hidden;
  flex-direction: column;
  padding-bottom: 30px;
}

.cam_titleView {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 10px 24px 0 24px;
}

.cam_button_view {
  display: flex;
  width: 252px;
  height: 48px;
  margin-top: 10px;
  margin-right: 24px;
}

.cam_buttonPosition {
  bottom: 0;
  display: flex;
  width: 50%;
  background-color: white
}

.cam_searchView {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-right: 20px;
  flex: 0.34;
  align-items: center;
}

.ag-theme-alpine {
  --ag-header-height: 30px;
  --ag-foreground-color: #0B2653;
  --ag-header-foreground-color: #0B2653;
  --ag-header-background-color: black;
  --ag-font-size: 12px;
  --ag-font-family: 'RubikRegular';
  --ag-border-radius: 5px;
}

.ag-theme-alpine .ag-header {
  font-family: 'RubikRegular';
  font-weight: 600;
  background: linear-gradient(100deg, #FFCA7A -48.61%, #FFE8AE 100%);
}

.ag-theme-alpine .ag-header-group-cell {
  font-size: 12px;
  color: #0B2653;
  font-family: 'RubikRegular';
}

.ag-theme-alpine .ag-header-cell {
  font-size: 12px;
  font-family: 'RubikRegular';
}

.cam_addIcon {
  align-items: center;
  height: 20px;
  width: 20px;
}

.cam_addIconView {
  align-items: center;
  display: flex;
  flex: 0.01;
  justify-content: center;
  padding-right: 2px;
  font-size: 14px;
  font-family: 'RubikRegular';
  color: #1675e0;
  text-decoration: underline;
}

.cam_searchView {
  display: flex;
  justify-content: flex-end;
  flex: 0.27;
  align-items: center;
}

.cam_lableView {
  display: flex;
  flex: 0.67;
  width: 60%;
  align-items: center;
  font-family: 'RubikMedium';
  font-weight: 500;
  font-size: 24px;
  color: #0B2653;
}


.cam_searchView_input {
  dispaly: flex;
  box-sizing: border-box;
  border-radius: 8px;
}


.cam_lableView {
  display: flex;
  flex: 0.7;
  width: 60%;
  align-items: center;
  font-family: 'RubikMedium';
  font-weight: 500;
  font-size: 24px;
  color: #0B2653;
}

.cam_editNdeleteView {
  display: flex;
  flex-direction: row;
  gap: 30px;
  height: 100%;
  align-items: center;
  justify-content: space-evenly;
}

.cam_actionIconStyle {
  display: flex;
  width: 24px;
  height: 24px;
}

.cam_bottomView {
  display: flex;
  position: absolute;
  bottom: 0;
  padding-right: 24px;
  width: 100%;
  height: 50px;
  background-color: white;
  align-items: center;
  justify-content: flex-end;
  box-shadow: 1px 1px 4px 1px rgba(6, 73, 109, 0.1);
  gap: 24px;
}

.cam_buttonView {
  display: flex;
  width: 252px;
  align-items: center;
}