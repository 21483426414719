.com_container {
  display: flex;
  padding: 20px;
  align-items: center;
  justify-content: center;
}

.com_content {
  display: flex;

  height: auto;
  align-self: center;
  flex-direction: column;
  overflow-y: auto;
}
.com_closeIconStyle{
  cursor: pointer;
}
.com_checkboxContainer{
  display: flex;
  width: 95%;
  gap: 2rem;
}
.com_checkboxItem{
  display: flex;
  gap: 0.5rem;
}
.wp_footer_sub{
  color: #FFF;
  font-family: RubikRegular;
  font-size: 14px;
  font-weight: 400;
}
.com_formContent {
  display: flex;
  width: 95% !important;
  align-self: center;
  flex-direction: column;
}
.highlight_number{
   font-weight: bold; 
  color: #013F60;;   
  background-color: rgba(255, 228, 102, 0.5); 
  padding: 2px 4px;  
  border-radius: 4px; 
}

.com_headerView {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.com_headingStyle {
  color: #013F60;
  font-family: RubikRegular;
  font-size: 24px;
  font-weight: 400;
}

.com_rowView {
  display: flex;
  width: 95%;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;
}

.com_input {
  display: flex;
  width: 35%;
}

.com_inputsContentView {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  gap: 24px;
  align-items: center;
}

.com_labelStyle {
  display: flex;
  color: #474747;
  font-family: RubikRegular;
  font-size: 16px;
  font-weight: 400;
}

.com_textAreaView {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.com_textLabelStyle {
  display: flex;
  margin: 10px 0;
  color: #013F60;
  font-family: RubikRegular;
  font-size: 16px;
  font-weight: 500;
  text-align: left;
}

.com_textAreaStyle {
  display: flex;
  height: 120px;
  color: #0b2653;
  font-family: RubikRegular;
  font-size: 14px;
  font-weight: 400;
  border: 1px solid rgba(81, 102, 138, 0.37);
  border-radius: 8px;
  padding: 15px;
}

.com_textAreaStyle:focus {
  border: 1px solid rgba(81, 102, 138, 0.37) !important;
  outline: none !important;

}

.com_subLabelStyle {
  display: flex;
  width: 95%;
  align-items: flex-start;
  justify-content: flex-start;
  padding-left: 5px;
  color: #013F60;
  font-family: RubikRegular;
  font-size: 14px;
  font-weight: 400;
}

.com_responseContentView {
  display: flex;
  width: 95% !important;
  align-self: center;
  flex-direction: column;
}

.com_responseView {
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin: 30px;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
@media (max-width: 768px) {
  .com_responseView {
  margin: 0;
 
}
}

.com_successStyle {
  display: flex;
  width: 200px;
  height: 150px;
}

.com_responseStyle {
  margin-top: 24px;
  width: 95%;
  color: #013F60;
  font-family: RubikRegular;
  font-size: 20px;
  font-weight: 400;
  text-align: center;
}
@media (max-width: 768px) {
.com_responseStyle {
  width: 100%;
  font-size: 18px;
 
}
}

.com_buttonView {
  display: flex;
  width: 30%;
  margin-top: 24px;
  align-self: center;
  align-items: center;
  justify-content: center;
}
.com-inputs-content-view {
  width: 100%;
  padding: 20px;
  
  border-radius: 8px;
  
  box-sizing: border-box;
 
}

.com-row-view {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 20px;
}

.com-row-view > div {
  flex: 1 1 calc(33% - 20px);
  min-width: 200px;
}

.com-checkbox-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  margin-bottom: 5px;
}

.com-checkbox-item {
  display: flex;
  align-items: center;
  gap: 8px;
}

.com-sub-label-style {
  font-size: 14px;
  color: #333;
  text-align: center;
  display: block;
  margin-top: 20px;
}

@media (max-width: 768px) {
  .com-inputs-content-view {
    padding: 0%;
    margin: 0%;
  }

  .com-checkbox-container {
    justify-content: flex-start;
  }

  .com-sub-label-style {
    font-size: 12px;
  }
}
