.t_container {
  display: flex;
  position: absolute;
  bottom: 80px;
  border-radius: 4px;
  box-shadow: 0px 1px 4px rgba(1, 63, 96, 0.2);
}

.t_messageStyle {
  display: flex;
  width: 100%;
}
