.pd_container{
  display: flex;
  height: 100vh;
  overflow: hidden;
  background: #F7F8FA;
  flex-direction: column;
  position:relative;
  
}

.pd_content_view {
  display: flex;
  width: calc(100% - 48px);
  margin: 0px 24px 24px 24px;
  overflow-y: scroll;
  flex-direction: column;
  margin-bottom: 50px;
}

.pd_card_view {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 24px;
}

.pd_expect_value_view {
  display: flex;
  background: #FFDC62;
  border: 1px solid #DCAC00;
  border-radius: 4px;
  height: 56px;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
}

.pd_value_text {
  display: flex;
  font-family: 'RubikRegular';
  font-weight: 500;
  font-size: 20px;
  text-align: center;
  color: #0B2653;
  margin: 16px 0px;
}

.pd_modal_view {
  display: flex;
  background: #FFFFFF;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.12);
  border-radius: 18px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 516px;
  height: 207px;
  position: absolute;
  margin-top: 150px;
  margin-left: 100px;
  overflow-y: scroll;
}

.pd_bottomView {
  display: flex;
  width:100%;
  height: 50px;
  background-color: white;
  position: absolute;
  bottom: 0;
  align-items: center;
  justify-content: end;

}

.pd_buttonView {
  display: flex;
  padding :10px;
  gap:20px;

}

.pd_addmore_button {
  display: flex;
  width: 252px;
}

.pd_save {
  display: flex;
  width: 252px;
}

@media screen and (min-width: 100%) {
  .pd_container {
    width: 100%;
  }
}