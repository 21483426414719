.ra_container {
  display: flex;
  width: 100%;
  height: 100%;
  background-color: white;
  box-shadow: 0px 4px 12px rgba(1, 63, 96, 0.1);
  border-radius: 8px;
  flex-direction: column;
}

.ra_tableView {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  background-color: white;
  border-radius: 8px;
  overflow-y: auto;
  border: solid 1px #f7f8fa;
  border-collapse: collapse;
}

.ra_tableContentView {
  display: flex;
  width: 100%;
  height: 75vh;
  overflow: scroll;
  flex-direction: column;
}


.ra_header_row {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  background: linear-gradient(100deg, #FFCA7A -48.61%, #FFE8AE 100%);
  position: sticky;
  top: 0;
  margin-bottom: 2px;
}

.ra_headerStyle {
  display: flex;
  padding: 1vh 0 1vh 3vw;
  width: calc(100% / 4);
  height: 100%;
  align-items: center;
  justify-content: flex-start;
  font-family: 'RubikMedium';
  font-size: 14px;
  color: #0B2653;
  text-align: left;
}

.ra_item_view {
  display: flex;
  flex-direction: row;
  margin-top: 1vh;
  padding: 1vh 2vw;
  align-items: center;
  justify-content: space-between;
}

.ra_item_title {
  display: flex;
  width: 80px;
  font-family: 'RubikMedium';
  font-size: 16px;
  color: #0B2653;
  text-align: left;
  justify-content: flex-start;
  align-items: flex-start;
}

.ra_item_percent_title {
  display: flex;
  width: 100px;
  margin-left: 5%;
  font-family: 'RubikMedium';
  font-size: 16px;
  color: #0B2653;
  text-align: left;
  justify-content: flex-start;
  align-items: flex-start;
}

.ra_separatorStyle {
  display: flex;
  margin-left: 2.5%;
  width: 95%;
  align-self: center;
  height: 1px;
  border: 1px solid #E0E0E0;
}

.ra_data_row {
  display: flex;
  width: 100%;
  align-items: center;
}

.ra_data_view {
  display: flex;
  padding: 1vh 0 1vh 3vw;
  width: calc(100% / 4);
  align-items: flex-start;
  justify-content: flex-start;
  font-family: "RubikRegular";
  font-size: 14px;
  color: #51668A;
  text-align: left;
}

.ra_text_input {
  display: flex;
  width: 40%;
  height: 30px;
  border: 1px solid rgba(81, 102, 138, 0.37);
  border-radius: 5px;
  font-family: 'RubikRegular';
  font-size: 14px;
  color: #51668A;
  padding: 3px 7px;
}

.ra_item_add {
  display: flex;
  width: 100px;
  height: 24px;
  align-items: center;
  justify-content: center;
}

.ra_button_style {
  font-family: 'RubikMedium';
  margin-left: 12px;
  font-size: 14px;
  text-decoration: underline;
  text-decoration-line: underline;
  background: linear-gradient(270deg, #005887 0.01%, #003049 105.19%);
  -webkit-background-clip: text;
  -webkit-background-clip: underline;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.ra_delete {
  cursor: pointer;
  align-self: center;
}

.ra_modalContent {
  display: flex;
  width: 100%;
  flex-direction: row;
  margin: 24px 24px 24px -10px;
  gap: 10px;
  align-items: center;
  justify-content: center;
}

.ra_categorySelect {
  display: flex;
  padding-left: 10px;
  padding-right: 10px;
  width: 270px;
  height: 56px;
  border: solid 1px gainsboro;
  border-radius: 8px;
  background-color: transparent;
  font-family: 'RubikRegular';
  font-size: 14px;
  color: #0b2653;
}

.ra_categorySelect:active {
  border: solid 1px gainsboro;
}

.ra_categorySelect:focus-within :focus :focus-visible {
  border: solid 1px gainsboro;
}