.rf_container {
    display: flex;
    width: 100%;
    height: 100%;
    background-color: white;
    flex-direction: column;
}

.rf_contentContainer {
    width: 80%;
    display: flex;
    align-self: center;
    align-items: center;
    flex-direction: column;
}

.rf_titleView {
    display: flex;
    margin-top: 32px;
    align-items: center;
    justify-content: center;
}

.rf_titleStyle {
    font-family: 'RubikMedium';
    font-size: 24px;
    text-decoration: underline;
    text-decoration-line: underline;
    background: linear-gradient(270deg, #005887 0.01%, #003049 105.19%);
    -webkit-background-clip: text;
    -webkit-background-clip: underline;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    flex: none;
    order: 1;
    flex-grow: 0;
}

.rf_contentView {
    display: flex;
    width: 100%;
    align-self: center;
    flex-direction: column;
    margin-bottom: 32px;
}

.rf_labelStyle {
    font-family: 'RubikRegular';
    margin-top: 24px;
    font-size: 16px;
    color: #0B2653;
    align-self: center;
}

.rf_inputView {
    display: flex;
    margin: 24px 0 0 24px;
    width: 100%;
    align-self: center;
}

.rf_infoView {
    display: flex;
    margin:5px 0 0 15px;
}

.rf_infoStyle {
    font-family: 'RubikRegular';
    font-size: 12px;
    color: #51668A;
}

.rf_buttonView {
    display: flex;
    width: 200px;
    height: 48px;
    margin-top: 30px;
    align-self: center;
    align-items: center;
    justify-content: center;
}

.rf_verifyLabelStyle {
    font-family: 'RubikRegular';
    margin-top: 24px;
    font-size: 14px;
    color: #51668A;
    align-self: center;
}

.rf_emailIdStyle {
    font-family: 'RubikMedium';
    margin-top: 12px;
    font-size: 16px;
    color: #0B2653;
    align-self: center;
}

.rf_resendLinkView {
    display: flex;
    margin-top: 100px;
    align-self: center;
    align-items: center;
    flex-direction: row;
}

.rf_resendLabelStyle {
    font-family: 'RubikRegular';
    font-size: 16px;
    color: #576268;
}

.rf_resendStyle {
    font-family: 'RubikMedium';
    margin-left: 5px;
    font-size: 16px;
    background: linear-gradient(270deg, #003049 0.01%, #005887 105.19%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-decoration-line: underline;
    text-decoration: underline;
}

.rf_pinView {
    display: flex;
    margin-top: 20px;
    width: 396px;
    flex-direction: column;
    align-self: center;
}

.rf_pinLabelStyle {
    margin-bottom: 12px;
    width: 247px;
    height: 19px;
    font-size: 16px;
    color: #0B2653;
    font-family: RubikRegular;
}