.mrs_container {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
}

.mrs_slider {
  width: 100%;
  height: 8px;
  border-radius: 5px;
  position: relative;
  background: #efb741;
  margin-top: 15px; /*slider background-color*/
}

.mrs_slider .mrs_progress {
  position: absolute;
  left: 0%;
  right: 0%;
  height: 8px;
  border-radius: 5px;
  background: #efb741;
}

.mrs_range_input {
  position: relative;
}

.mrs_range_input input {
  position: absolute;
  top: -8px;
  height: 8px;
  width: 100%;
  background: none;
  pointer-events: none;
  -webkit-appearance: none;
}

input[type="range"]::-webkit-slider-thumb {
  height: 30px;
  width: 12px;
  border-radius: 0;
  pointer-events: auto;
  -webkit-appearance: none;
  background: white;
  border: solid 3px #efb741;
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.25);
}

input[type="range"]::-moz-slider-thumb {
  height: 17px;
  width: 17px;
  border-radius: 50%;
  border: none;
  pointer-events: auto;
  -moz-appearance: none;
  background: white;
  background: #efb741;
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.25);
}

.mrs_valuesView {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: red;
}

.mrs_valuesStyle {
  position: absolute;
  left: 0;
  font-size: 12px;
  font-family: "Rubik-Regular";
  color: #0b2653;
}

.mrs_labelsView {
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: red;
}

.mrs_labelStyle {
  position: absolute;
  left: 0;
  font-size: 12px;
  font-family: "Rubik-Regular";
  color: #0b2653;
  text-align: center;
}
