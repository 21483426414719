.insm_container {
  display: flex;
  padding: 20px;
  align-items: center;
  justify-content: center;
}

.insm_content {
  display: flex;
 
  height: auto;
  align-self: center;
  flex-direction: column;
}
.insm_closeIconStyle{
  cursor: pointer;
}
.insm_headerView {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.insm_headingStyle {
  display: flex;
  margin-right: 50px;
  color: #013F60;
  font-family: RubikRegular;
  font-size: 24px;
  font-weight: 400;
}

@media (max-width: 1024px) {
  .insm_headingStyle {
    font-size: 20px; 
    margin-right: 30px; 
  }
}


@media (max-width: 768px) {
  .insm_headingStyle {
    font-size: 18px;
    margin-right: 20px;
  }
}


@media (max-width: 480px) {
  .insm_headingStyle {
    font-size: 16px; 
    margin-right: 15px;
    flex-direction: column; 
    align-items: center;
    text-align: center;
  }
}

.insm_inputsContentView {
  padding: 15px 40px;
 
  border-radius: 8px;
  
  max-width: 800px; 
  margin: 0 auto; 
}

.insm_inputsRowView {
  display: flex;
  flex-wrap: wrap; 
  justify-content: space-between;
  margin-bottom: 15px; 
}

.insm_inputsRowView > div {
  flex: 1;
  min-width: 300px;
  margin-right: 15px; 
}

.insm_inputsRowView > div:last-child {
  margin-right: 0;
}

@media screen and (max-width: 820px) {
  
  .insm_inputsRowView {
    flex-direction: column; 
  }

  .insm_inputsRowView > div {
    margin-right: 0; 
    margin-bottom: 25px; 
  }

  .insm_inputsRowView > div:last-child {
    margin-bottom: 0; 
  }
}

@media screen and (max-width: 480px) {
  
  .insm_inputsContentView {
    padding: 15px; 
  }

  .insm_inputsRowView > div {
    min-width: 100%;
    margin-bottom: 35px; 
  }
}
.insm_labelStyle {
  display: flex;
  margin: 10px 0;
  color: #013F60;
  font-family: RubikRegular;
  font-size: 16px;
  font-weight: 500;
  text-align: left;
}
@media screen and (max-width: 480px) {
.insm_labelStyle {
font-size: 14px;

}
}

.insm_sliderOuterView {
  display: flex;
  width: 45%;
  flex-direction: column;
}

.insm_sliderView {
  display: flex;
  width: 100%;
  margin: 10px 0 0 -24px;
}

.insm_buttonView {
  display: flex;
  width: 30%;
  margin-top: 24px;
  align-self: center;
  align-items: center;
  justify-content: center;
}

.insm_resultContent {
  display: flex;
  flex-direction: column;
  width: 80%;
  height: 50px;
  align-items: center;
  justify-content: center;
  align-self: center;
  background-color: rgba(239, 183, 65, 0.2);
  margin-top: 24px;
  padding: 30px;
}

.insm_resultStyle {
  color: #013F60;
  font-family: RubikRegular;
  font-size: 22px;
  font-weight: 400;
  text-align: center;
}
@media screen and (max-width: 820px) {
.insm_resultStyle {
 
  font-size: 20px;
  
}

}

.insm_scoreContent {
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin: 30px;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.insm_input {
  display: flex;
  width: 45%;
}