#olt_container {
  display: flex;
  height: 100vh;
  overflow: hidden;
  background: #f7f8fa;
  flex-direction: column;
  position: relative;
}

@media screen and (min-width: 100%) {
  #olt_container {
    width: 100%;
  }
}

#olt_inner_container {
  display: flex;
  flex-direction: column;
  height: calc(100%-100px);
  margin: 0 24px;
  width: calc(100% - 48px);
  padding-bottom: 24px;
  overflow-y: auto;
}

#olt_top_container {
  display: flex;
  margin-bottom: 24px;
}

#olt_top_toggle_field {
  display: flex;
  gap: 30px;
  align-items: center;
}

#olt_top_toggle_label {
  color: #0B2653;
  font-size: 16px;
  font-family: RubikRegular;
}

.olt_toggle {
  display: flex;
  border-radius: 100px;
  width: 109px;
}

.olt_start_year_row {
  display: flex;
  flex-direction: column;
  width: 429px;
  margin-right: 24px;
}

.olt_start_text {
  font-family: 'RubikRegular';
  font-size: 16px;
  color: #0B2653;
}

.olt_slider_content {
  display: flex;
  width: 100%;
  margin: 50px 0px 24px 0px;
}


#olt_middle_content {
  display: flex;
  margin-top: 24px;
  margin-bottom: 24px;
}

#olt_bottom_container {
  gap: 30px;
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  margin-bottom: 50px;
}

#olt_bottom_top {
  display: flex;
}

.olt_bot_bot_input {
  width: 50%;
}

#olt_bottom_view {
  bottom: 0;
  display: flex;
  position: absolute;
  height: fit-content;
  align-items: center;
  width: 100%;
  background-color: white;
  justify-content: flex-end;
  padding-bottom: 10px;
}

#olt_button_view {
  width: 252px;
  height: 48px;
  padding: 10px;
}

.olt_input {
  display: flex;
  width: 429px;
  margin-right: 24px;
}

.olt_checkboxOuterView {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.olt_checkboxView {
  margin-right: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.olt_checkboxStyle {
  width: 18px;
  height: 18px;
}

.olt_checkboxLabelStyle {
  margin-left: 5px;
  font-family: 'RubikRegular';
  font-weight: 400;
  font-size: 12px;
  color: #0B2653;
}