.slider {
    position: relative;
    width: 100%;
    height: 50px;
    margin-left: 24px;
}

#slider {
    -webkit-appearance: none;
    width: 100%;
    height: 10px;
    background: #E0E0E0;
    outline: none;
    opacity: 0.7;
    margin-top: 20px;
    -webkit-transition: .2s;
    transition: opacity .2s;
    border-radius: 10px;
    z-index: 1;
}

#slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
    background-color: white;
    border: 4px solid#EFB741;
    box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.25);
    border-radius: 50%;
    cursor: pointer;
    position: relative;
    z-index: 1;
}

.show-value {
    position: absolute;
    display: flex;
    transform: translate(-48%, -220%);
    width: 86px;
    height: 24px;
    background: #EFB741;
    border-radius: 4px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.show-value:after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-width: 7px;
    border-style: solid;
    border-color: #EFB741 transparent transparent transparent;
    top: 24px;
}

.show-age-value {
    position: absolute;
    display: flex;
    transform: translate(-48%, -170%);
    width: 86px;
    height: 40px;
    background: #EFB741;
    border-radius: 4px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.show-age-value:after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-width: 7px;
    border-style: solid;
    border-color: white transparent transparent transparent;
    top: 40px;
}

.current-value {
    display: flex;
    width: 100%;
    height: 50%;
    font-family: 'RubikRegular';
    font-weight: 400;
    font-size: 14px;
    text-align: center;
    vertical-align: middle;
    align-items: center;
    justify-content: center;
    color: #0B2653;
    border-radius: 4px;
}

.current-age {
    display: flex;
    width: 100%;
    height: 50%;
    font-family: 'RubikRegular';
    font-weight: 400;
    font-size: 14px;
    text-align: center;
    vertical-align: middle;
    justify-content: center;
    color: #0B2653;
    background-color: white;
    border-radius: 4px;
}

.range {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.mark {
    display: flex;
    transform: translateY(10%);
    width: 30px;
    align-items: center;
    flex-direction: column;
}

.line-style {
    width: 8px;
    height: 0px;
    opacity: 0.37;
    border: 1px solid #626262;
    transform: rotate(90deg);
    z-index: 0;
}

.mark-label {
    margin-top: 8px;
    font-family: 'RubikRegular';
    font-weight: 400;
    font-size: 14px;
    color: #51668A;
}