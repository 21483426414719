.lf_container {
    display: flex;
    width: 100%;
    height: 100%;
    background-color: white;
    flex-direction: column;
}

.lf_content {
    width: 80%;
    display: flex;
    align-self: center;
    align-items: center;
    flex-direction: column;
    gap:2rem;
}

.lf_titleView {
    display: flex;
    margin: 32px;
    align-items: center;
    justify-content: center;
}

.lf_titleStyle {
    font-family: 'RubikMedium';
    font-size: 24px;
    text-decoration: underline;
    text-decoration-line: underline;
    background: linear-gradient(270deg, #005887 0.01%, #003049 105.19%);
    -webkit-background-clip: text;
    -webkit-background-clip: underline;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    flex: none;
    order: 1;
    flex-grow: 0;
}

.lf_inputView {
    display: flex;
    
    width: 100%;
    align-self: center;
}

.lf_otpView {
    display: flex;
    
    width: 100%;
    align-self: center;
    flex-direction: column;
}

.lf_otpLabelStyle {
    font-family: 'RubikRegular';
    font-size: 16px;
    margin-bottom: 16px;
    color: #51668A;
}

.lf_buttonView {
    display: flex;
    width: 200px;
    height: 48px;
    margin-top: 10px;
    align-self: center;
    align-items: center;
    justify-content: center;
}

.lf_linkView {
    display: flex;
    margin: 24px 0px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.lf_linkLabelStyle {
    font-family: 'RubikRegular';
    font-size: 18px;
    color: #51668A;
}

.lf_linkStyle {
    font-family: 'RubikMedium';
    margin-left: 12px;
    font-size: 18px;
    letter-spacing: 0.01em;
    text-decoration: underline;
    text-decoration-line: underline;
    background: linear-gradient(270deg, #005887 0.01%, #003049 105.19%);
    -webkit-background-clip: text;
    -webkit-background-clip: underline;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    flex: none;
    order: 1;
    flex-grow: 0;
}

.lf_forgotpinView {
    display: flex;
    width: 100%;
    margin-top: 18px;
    padding-right: 10px;
    justify-content: end;
    align-items: flex-end;
}

.lf_forgotStyle {
    font-family: 'RubikMedium';
    margin-left: 12px;
    font-size: 14px;
    text-decoration: underline;
    text-decoration-line: underline;
    background: linear-gradient(270deg, #005887 0.01%, #003049 105.19%);
    -webkit-background-clip: text;
    -webkit-background-clip: underline;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    flex: none;
    order: 1;
    flex-grow: 0;
}