.fdp_container {
  display: flex;
  height: 100vh;
  overflow: hidden;
  background: #f7f8fa;
  flex-direction: column;
  position: relative;
}

@media screen and (min-width: 100%) {
  .fdp_container {
    width: 100%;
  }
}

.fdp_content {
  display: flex;
  width: calc(100% - 48px);
  height: 100%;
  overflow-y: auto;
  margin: 0 24px;
  flex-direction: column;
}

.fdp_networthView {
  display: grid;
  width: 100%;
  height: 170px;
  grid-template-columns: 30% 40% 30%;
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(1, 63, 96, 0.1);
  border-radius: 8px;
  padding: 0 24px;
}

.fdp_myNetworthView {
  display: flex;
  width: 100%;
  margin-top: 7px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
}

.fdp_titleStyle {
  font-family: "RubikRegular";
  font-size: 20px;
  text-align: left;
  color: #51668a;
}

.fdp_amountStyle {
  font-family: "RubikMedium";
  font-size: 32px;
  color: #0b2653;
}

.fdp_dateStyle {
  font-family: "RubikRegular";
  font-size: 15px;
  color: #51668a;
}

.fdp_chartOuterView {
  display: flex;
  width: 100%;
  align-items: center;
}

.fdp_chartView {
  display: flex;
  height: 90%;
  align-items: center;
}

.fdp_breakupView {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-evenly;
}

.fdp_breakupStyle {
  font-family: "RubikMedium";
  font-size: 20px;
  color: #013f60;
}

.fdp_valuesView {
  display: grid;
  grid-template-columns: 10% 50% 40%;
  align-items: center;
}

.fdp_percentageStyle {
  display: flex;
  width: 41px;
  height: 26px;
  background: linear-gradient(270deg, #003049 0.01%, #005887 105.19%);
  border-radius: 8px;
  font-family: "RubikMedium";
  font-size: 12px;
  color: #ffffff;
  text-align: center;
  vertical-align: middle;
  align-items: center;
  justify-content: center;
}

.fdp_labelStyle {
  display: flex;
  margin-left: 20px;
  font-family: "RubikRegular";
  font-size: 16px;
  color: #0b2653;
}

.fdp_valueStyle {
  font-family: "RubikRegular";
  font-size: 16px;
  color: #51668a;
}

.fdp_tabSelectionView {
  display: flex;
  margin-top: 10px;
  width: 100%;
}

.fdp_tabContentView {
  display: flex;
  width: 100%;
  margin-top: 24px;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 60px;
}

.fdp_colView {
  display: flex;
  width: 49%;
  flex-direction: column;
}

.fdp_scheme_card {
  display: flex;
  width: 100%;
  cursor: pointer;
  border-radius: 8px;
  height: fit-content;
  background-color: #ffffff;
  box-shadow: 0px 4px 12px rgba(1, 63, 96, 0.1);
  flex-direction: column;
  margin-bottom: 24px;
}

.fdp_drpdwn_icon {
  display: flex;
  width: 32px;
  transition: 0.5s;
}

.fdp_drpdwn_icon_rotate {
  display: flex;
  width: 32px;
  transition: 0.5s;
  transform: rotate(180deg);
}

.fdp_dd_cnt_titleNdesc {
  display: flex;
  margin-left: 12px;
  flex-direction: column;
}

.fdp_dd_title_style {
  font-family: "RubikMedium";
  font-weight: 500;
  font-size: 18px;
  color: #0b2653;
}

.fdp_dd_desc_style {
  font-family: "RubikRegular";
  font-weight: 400;
  font-size: 16px;
  color: #51668a;
}

.fdp_descNValueView {
  margin-top: 8px;
  display: flex;
  flex-direction: row;
}

.fdp_dd_value_style {
  margin-left: 5px;
  font-family: "RubikRegular";
  font-weight: 400;
  font-size: 16px;
  color: #0b2653;
}

.fdp_add_scheme {
  display: flex;
  font-family: "RubikRegular";
  font-weight: 400;
  font-size: 16px;
  color: #0b2653;
  flex-direction: row;
  flex-wrap: nowrap;
  text-align: right;
  width: 10%;
  height: 100%;
}

.fdp_extraLabelStyle {
  font-size: 12px;
  margin-right: 3%;
}

.fdp_svngs_sch_title {
  font-family: "RubikRegular";
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.01em;
  color: #0b2653;
}

.fdp_drpdwn_cnt_title {
  display: flex;
  width: 100%;
  padding: 24px 24px 18px;
  align-items: center;
  justify-content: flex-start;
}

.fdp_addable_card {
  width: 100%;
  display: flex;
  padding: 5% 4%;
  align-items: center;
  justify-content: space-between;
}

.fdp_scheme_value {
  display: flex;
  background: #fff8e1;
  width: calc(100% - 48px);
  padding: 24px;
  align-items: center;
}

.fdp_add_style {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 90%;
  height: 100%;
}

.fdp_scheme_outer {
  display: flex;
  width: calc(100% - 48px);
  padding: 24px;
  align-items: center;
}

.fdp_scheme_inner {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.fdp_separatorStyle {
  display: flex;
  width: calc(100%-48px);
  border: 1px solid #e0e0e0;
  margin: 0 24px;
}

.fdp_bottomView {
  display: flex;
  width: 100%;
  height: 55px;
  background-color: white;
  position: absolute;
  bottom: 0;
  padding-top: 10px;
  padding-right: 24px;
  align-items: center;
  justify-content: end;
  box-shadow: 1px 1px 4px 1px rgba(6, 73, 109, 0.1);
}

.fdp_buttonView {
  display: flex;
  position: absolute;
  flex-direction: row;
}

.fdp_buttonView {
  display: flex;
  width: 252px;
  height: 48px;
}

.fdp_expandable_content {
  display: flex;
  width: 100%;
  flex-direction: column;
}

/* 
.fdp_expandable_content {
  height: 0;
  animation: grow 1s ease-in forwards;
}

@keyframes grow {
  from {
    height: 0;
  }
  to {
    height: auto;
  }
}
 */