.cf_container {
  display: flex;
  width: 100%;
  height: 100%;
  background-color: white;
  box-shadow: 0px 4px 12px rgba(1, 63, 96, 0.1);
  border-radius: 8px;
  padding: 24px;
  overflow: hidden;
  flex-direction: column;
}

.cf_titleView {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.cf_itemsView {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.cf_titleStyle {
  font-family: "RubikRegular";
  font-weight: 400;
  font-size: 16px;
  color: #0b2653;
}

.cf_iconStyle {
  display: flex;
  margin-left: 8px;
  width: 18px;
  height: 18px;
  color: #003049;
}

.cf_chartView {
  display: flex;
  height: 300px;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: -24px;
}

.cf_chartViewModal {
  display: flex;
  height: 500px;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: -24px;
}