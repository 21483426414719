.adp_container {
  display: flex;
  height: 100%;
  overflow: hidden;
  background: #f7f8fa;
  flex-direction: column;
  position: relative;
}

@media screen and (min-width: 100%) {
  .adp_container {
    width: 100%;
  }
}

.adp_inner_container {
  overflow: auto;
  display: flex;
  align-items: center;
  margin: 0 24px;
  flex-direction: column;
  width: calc(100% - 48px);
}

.adp_main_content {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  flex-direction: row;
}

.adp_balance_corpus {
  display: flex;
  margin-top: 16px;
  width: 100%;
  font-family: RubikRegular;
  font-size: 16px;
  gap: 20px;
}

.adp_alert_View {
  justify-content: space-between;
  display: flex;
  width: 49%;
  border-radius: 8px;
  align-items: center;
}

.adp_risk_profile {
  padding: 10px 15px;
  justify-content: space-between;
  display: flex;
  width: 46%;
  color: #27ae60;
  background-color: rgba(109, 212, 83, 0.2);
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(1, 63, 96, 0.1);
  align-items: center;
}

.adp_risk_label {
  display: flex;
  margin-right: 30px;
}

.adp_cal_eff_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.adp_cal_title {
  font-family: 'RubikMedium';
  font-weight: 500;
  font-size: 18px;
  color: #0B2653;
  padding-bottom: 10px;
}

.adp_chart_contant {
  display: flex;
  gap: 20px;
  width: 100%;
  flex-direction: column;
}

.adp_cal_eff_content {
  padding: 10px 15px;
  justify-content: space-between;
  display: flex;
  width: 46%;
  color: #27ae60;
  background-color: rgba(109, 212, 83, 0.2);
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(1, 63, 96, 0.1);
}

.adp_cal_eff_secure {
  display: flex;
  font-size: 16px;
  font-weight: 500;
}

.adp_cal_eff_growth {
  display: flex;
  margin-right: 30px;
  font-size: 16px;
  font-weight: 500;
}

.adp_chart_bucket {
  display: flex;
  gap: 20px;
}

.adp_chart_view {
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
}

.adp_bottom_view {
  position: absolute;
  bottom: 0;
  padding-right: 24px;
  display: flex;
  height: 60px;
  align-items: center;
  width: 100%;
  background-color: white;
  justify-content: flex-end;
  box-shadow: 1px 1px 4px 1px rgba(6, 73, 109, 0.1);
  gap: 20px;
}

.adp_button_view {
  margin-top: 10px;
  width: 252px;
  height: 48px;
  display: flex;
}

.adp_donutchart {
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.adp_donutchart_container {
  display: flex;
  padding: 24px;
  width: 50%;
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(1, 63, 96, 0.1);
  border-radius: 8px;
  flex-direction: column;
}

.adp_slider_container {
  width: 50%;
  display: flex;
  padding: 24px;
  border-radius: 8px;
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(1, 63, 96, 0.1);
  flex-direction: column;
  padding-bottom: 50px;
}

.adp_assetTitleStyle {
  display: flex;
  width: 100%;
  font-family: "RubikRegular";
  font-size: 16px;
  color: #0b2653;
  align-items: flex-start;
}

.adp_sliderView {
  display: flex;
  width: 95%;
  align-items: flex-start;
  margin-top: 50px;
}

.adp_retInput {
  display: flex;
  width: 100%
}