.pp_servicesView {
    padding: 2rem 6rem !important;
    background-color: #f9f9f9;
    border-radius: 8px;
}

@media (max-width: 768px) {  
    .pp_servicesView {
      padding: 4rem 2rem !important; 
    }
}

.pp_serviceTitleStyle {
    color: #013F60;
    font-family: RubikRegular;
    font-size: 56px;
    font-weight: 500;
    text-align: center;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}
@media (max-width: 968px) {
    .pp_serviceTitleStyle {
        font-size: 45px;
    }
}

@media (max-width: 468px) {
    .pp_serviceTitleStyle {
        font-size: 35px;
    }
}

.pp_privacyPolicyContent{
    font-family: RubikRegular;
    font-size: 16px;
    margin-top: 30px;
}

.pp_privacyPolicySubtitle{
    font-family: RubikRegular;
    font-size: 20px;
    color: #013F60;
    margin: 10px 0;
}

.pp_privacyPolicySubSubtitle{
    font-family: RubikRegular;
    font-size: 18px;
    color: #013F60;
}

.pp_privacyPolicyList{
    gap: 10px;
    margin-left: 20px;
    line-height: 1.8;
    font-family: RubikRegular;
    font-size: 16px;
}

.pp_footerView {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    background: #002234;
    padding: 12px 0;
}

.pp_footerTextStyle {
    color: #FFF;
    text-align: center;
    font-family: RubikRegular;
    font-size: 13px;
    font-weight: 400;
}