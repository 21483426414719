#blt_container {
  display: flex;
  height: 100vh;
  overflow: hidden;
  background: #f7f8fa;
  flex-direction: column;
  position: relative;
}

@media screen and (min-width: 100%) {
  #blt_container {
    width: 100%;
  }
}

#blt_inner_container {
  display: flex;
  flex-direction: column;
  height: calc(100%-100px);
  margin: 0 24px;
  width: calc(100% - 48px);
  padding-bottom: 24px;
  overflow-y: auto;
}

#blt_top_container {
  display: flex;
  margin-bottom: 24px;
}

#blt_top_toggle_field {
  display: flex;
  gap: 30px;
  align-items: center;
}

#blt_top_toggle_label {
  color: #0B2653;
  font-size: 16px;
  font-family: RubikRegular;
}

.blt_toggle {
  display: flex;
  box-shadow: inset 0px 3px 20px rgba(0, 0, 0, 0.15);
  border-radius: 100px;
}

.blt_start_year_row {
  display: flex;
  flex-direction: column;
  width: 429px;
  margin-right: 24px;
}

.blt_start_text {
  font-family: 'RubikRegular';
  font-size: 16px;
  color: #0B2653;
}

.blt_slider_content {
  display: flex;
  width: 100%;
  margin: 50px 0px 24px 0px;
}


#blt_middle_content {
  display: flex;
  margin-top: 24px;
  margin-bottom: 24px;
}

#blt_bottom_container {
  gap: 30px;
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  margin-bottom: 50px;
}

#blt_bottom_top {
  display: flex;
}

#blt_bottom_view {
  bottom: 0;
  display: flex;
  position: absolute;
  height: fit-content;
  align-items: center;
  width: 100%;
  background-color: white;
  justify-content: flex-end;
}

#blt_button_view {
  display: flex;
  width: 252px;
  padding:10px;
}

.blt_input {
  display: flex;
  width: 429px;
  margin-right: 24px;
}