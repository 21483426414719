.pdetail_container {
  display: flex;
  height: 100vh;
  overflow: hidden;
  background: #F7F8FA;
  flex-direction: column;
  position: relative;
}

.pdetail_content_view {
  display: flex;
  width: calc(100% - 48px);
  margin: 0px 24px 24px 24px;
  overflow-y: scroll;
  flex-direction: column;
  margin-bottom: 50px;
}

.pdetail_input {
  display: flex;
  width: 429px;
  margin-right: 24px;
}

.pdetail_description_view {
  display: flex;
  margin: 24px 0px;
}

.pdetail_seperatorStyle {
  margin: 24px 0px;
  border: 1px solid #E0E0E0;
}

.pdetail_start_year_row {
  display: flex;
  flex-direction: column;
  width: 429px;
  margin-right: 24px;
}

.pdetail_start_text {
  font-family: 'RubikRegular';
  font-weight: 400;
  font-size: 16px;
  color: #0B2653;
}

.pdetail_slider_content {
  display: flex;
  width: 100%;
  margin: 50px 0px 24px 0px;
}

.pdetail_bottomView {
  display: flex;
  width: 100%;
  height: 50px;
  background-color: white;
  position: absolute;
  bottom: 0;
  align-items: center;
  justify-content: end;
}

.pdetail_buttonView {
  display: flex;
  padding:10px;
}

.pdetail_save {
  display: flex;
  width: 252px;
  margin-left: 24px;
}

@media screen and (min-width: 100%) {
  .pdetail_container {
    width: 100% 
  }
}