#ins_container {
  display: flex;
  height: 100vh;
  overflow: hidden;
  background: #f7f8fa;
  flex-direction: column;
  position: relative;
}

@media screen and (min-width: 100%) {
  #ins_container {
    width: 100%;
  }
}

#ins_inner_container {
  display: flex;
  flex-direction: column;
  gap: 5%;
  height: 60%;
  margin: 0 24px 24px;
  width: calc(100% - 48px);
}

#ins_top_container {
  display: flex;
}

.ins_start_year_row {
  display: flex;
  flex-direction: column;
  width: 429px;
  margin-right: 24px;
}

.ins_start_text {
  font-family: 'RubikRegular';
  font-size: 16px;
  color: #0B2653;
}

.ins_slider_content {
  display: flex;
  width: 100%;
  margin: 50px 0px 24px 0px;
}


#ins_middle_content {
  display: flex;
}

#ins_bottom_container {
  gap: 30px;
  display: flex;
  flex-direction: column;
}

#ins_bottom_top {
  display: flex;
}

#ins_bottom_view {
  bottom: 0;
  display: flex;
  position: absolute;
  height: fit-content;
  align-items: center;
  width: 100%;
  background-color: white;
  justify-content: flex-end;
  padding-bottom: 10px;
}

#ins_button_view {
  width: 252px;
  height: 48px;
  padding: 10px;
}

.ins_input {
  display: flex;
  width: 429px;
  margin-right: 24px;
}