.rps_slider {
  position: relative;
  width: 100%;
  height: 50px;
  margin-left: 24px;
}

#rps_slider {
  -webkit-appearance: none;
  width: 100%;
  height: 10px;
  background: #E0E0E0;
  outline: none;
  opacity: 0.7;
  margin-top: 20px;
  -webkit-transition: .2s;
  transition: opacity .2s;
  border-radius: 10px;
  z-index: 1;
}

#rps_slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  background-color: white;
  border: 4px solid#EFB741;
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  z-index: 1;
}

.rps_current-value {
  display: flex;
  width: 100%;
  height: 50%;
  font-family: 'RubikRegular';
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  vertical-align: middle;
  align-items: center;
  justify-content: center;
  color: #0B2653;
  border-radius: 4px;
}

.rps_range {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.rps_mark {
  display: flex;
  transform: translateY(10%);
  width: 30px;
  align-items: center;
  flex-direction: column;
}

.rps_line-style {
  width: 8px;
  height: 0px;
  opacity: 0.37;
  border: 1px solid #626262;
  transform: rotate(90deg);
  z-index: 0;
}

.rps_mark-label {
  margin-top: 8px;
  font-family: 'RubikRegular';
  font-weight: 400;
  font-size: 14px;
  color: #51668A;
}