.tsv_container {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.tsv_tabSelectionView {
  display: flex;
  width: 100%;
  height: 46px;
  border-bottom: solid 2px rgba(87, 98, 104, 0.1);
  flex-direction: row;
}

.tsv_tabSelectionView ::-webkit-scrollbar {
  display: none;
}

.tsv_tabStyle {
  display: flex;
  width: 222px;
  height: 46px;
  align-items: center;
  justify-content: center;
  border-bottom: solid 4px transparent;
}

.tsv_itemStyle {
  font-family: "RubikRegular";
  font-size: 16px;
  text-align: center;
  color: #51668a;
}

.fdb_selectedTabStyle {
  display: flex;
  width: 222px;
  height: 46px;
  align-items: center;
  justify-content: center;
  border-bottom: solid 4px #013f60;
}

.tsv_selectedItemStyle {
  font-family: "RubikRegular";
  font-size: 16px;
  text-align: center;
  color: #013f60;
}

.tsv_contentView {
  display: flex;
  width: 100%;
  flex-direction: row;
}

.tsv_tabContentView {
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}