.tv_titleView {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.tv_titleStyle {
  font-family: 'RubikMedium';
  font-weight: 500;
  font-size: 18px;
  color: #0B2653;
  padding-bottom: 5px;
}

.tv_infoStyle {
  margin: 5px 0px;
  font-family: 'RubikRegular';
  font-weight: 400;
  font-size: 14px;
  color: #51668A;
  display: flex;
  width: 950px;
}