.mf_container {
  display: flex;
  height: 100vh;
  overflow: hidden;
  background: #f7f8fa;
  flex-direction: column;
  position: relative;
}

.mf_contentView {
  display: flex;
  width: calc(100% - 48px);
  margin: 0px 24px 0 24px;
  overflow-y: scroll;
  flex-direction: column;
  padding-bottom: 24px;
}

.mf_assetAllocationView {
  display: flex;
  margin-top: 24px;
  width: 100%;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

.mf_chartOuterView {
  display: flex;
  padding: 24px;
  width: 40%;
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(1, 63, 96, 0.1);
  border-radius: 8px;
  flex-direction: column;
}

.mf_assetTitleStyle {
  display: flex;
  width: 100%;
  font-family: "RubikRegular";
  font-weight: 400;
  font-size: 18px;
  color: #0b2653;
}

.mf_chartView {
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mf_buttonView {
  display: flex;
  width: 252px;
}

.mf_tabSelectionView {
  display: flex;
  margin-top: 40px;
  width: 100%;
}

.mf_detailsView {
  margin-top: 24px;
  display: flex;
  width: 100%;
  flex-direction: column;
}

.mf_detailsContentView {
  display: flex;
  margin-top: 24px;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
}

.mf_detailsCardView {
  display: flex;
  width: 48%;
}

.mf_titleNamtView {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
}

.mf_titleStyle {
  font-family: "RubikMedium";
  font-weight: 500;
  font-size: 24px;
  color: #0b2653;
}

.mf_amtStyle {
  margin-left: 32px;
  font-family: "RubikRegular";
  font-weight: 400;
  font-size: 20px;
  color: #51668a;
}
@media screen and (min-width: 100%) {
  .mf_container {
    width: 100% 
  }
}