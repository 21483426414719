.aad_container {
  display: flex;
  height: 100vh;
  overflow: hidden;
  background: #f7f8fa;
  flex-direction: column;
  position: relative;
}

.aad_content_view {
  display: flex;
  width: calc(100% - 48px);
  margin: 0px 24px 24px 24px;
  overflow-y: scroll;
  flex-direction: column;
  margin-bottom: 50px;
}

.aad_input {
  display: flex;
  width: 429px;
  margin-right: 24px;
}

.aad_description_view {
  display: flex;
  width: 100%;
  height: calc(100% - 40px);
  flex-direction: column;
}

.aad_input_row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap:30px;
}

.aad_sec_row {
  display: flex;
  margin-top: 33px;
  align-items: center;
}

.aad_toggle_view {
  display: flex;
  flex-direction: row;
  gap: 55px;
}

.aad_extraView{
display: flex;
width: 30px;
background-color: #fff;
}

.aad_toggle_text {
  display: flex;
  font-family: "RubikRegular";
  font-weight: 400;
  font-size: 16px;
  width: 300px;
  color: #0b2653;
}

.aad_seperatorStyle {
  margin: 24px 0px;
  border: 1px solid #e0e0e0;
}

.aad_slider_view {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-start;
}

.aad_start_year_row {
  display: flex;
  flex-direction: column;
  width: 429px;
  margin-right: 24px;
}

.aad_start_text {
  font-family: "RubikRegular";
  font-weight: 400;
  font-size: 16px;
  color: #0b2653;
}

.aad_slider_content {
  display: flex;
  width: 100%;
  margin: 50px 0px 24px 0px;
}

.aad_cost_item_view {
  display: flex;
  margin: 24px 0px 0px;
  width: 881px;
}

.aad_retirement_toggle {
  display: flex;
  margin-bottom: 24px;
}

.aad_bottomView {
  display: flex;
  width: 100%;
  height: 50px;
  background-color: white;
  position: absolute;
  bottom: 0;
  align-items: center;
  justify-content: end;
}

.aad_buttonView {
  display: flex;
  padding: 10px;
}

.aad_sale_button {
  display: flex;
  width: 252px;
  margin-left: 24px;
}

.aad_save {
  display: flex;
  width: 252px;
  margin-left: 24px;
}

@media screen and (min-width: 100%) {
  .aad_container {
    width: 100% 
  }
}

