.ss_container {
  display: flex;
  width: 75%;
   height: 98vh;
  
  padding: 1%;
  overflow: hidden;
}
@media (max-width: 850px) {
  .ss_container {
  width: 100%;
  height: 100%;
  }
}

.ss_resultContainer {
  display: flex;
 
  height: 98vh;
  
  padding: 1%;
  overflow: hidden;
  align-self: center;
  align-items: center;
}

.ss_content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 500px;
  overflow: auto;
}

.ss_formContent {
  display: flex;
  width: 100% !important;
  align-self: center;
  flex-direction: column;
}
.ss_closeIconStyle{
  cursor: pointer;
}
.ss_headerView {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.ss_headingStyle {
  display: flex;
  margin-right: 50px;
  color: #013F60;
  font-family: RubikRegular;
  font-size: 24px;
  font-weight: 400;
}
.ss_inputsContentView {
  display: flex;
  flex-direction: column;
  gap: 20px;
  
  box-sizing: border-box;
}


.ss_inputsRowView {
  display: flex;

  gap: 20px;
  width: 100%;
  box-sizing: border-box;
}


.ss_inputView {
  flex: 1 1 45%;
  min-width: 220px;
  box-sizing: border-box;
}


@media (max-width: 850px) {
  .ss_inputsRowView {
    flex-direction: column; 
  }

  .ss_inputView {
    flex: 1 1 100%; 
  }
  
.ss_input {
  display: flex;
  width: 100%!important;
}
}



@media (max-width: 480px) {
  .ss_inputView {
    padding: 0 5px; 
  }
}
.ss_labelStyle {
  display: flex;
  margin: 10px 0;
  color: #013F60;
  font-family: RubikRegular;
  font-size: 16px;
  font-weight: 500;
  text-align: left;
}

.ss_buttonView {
  display: flex;
  width: 20%;
  margin-top: 24px;
  align-self: center;
  align-items: center;
  justify-content: center;
}
@media (max-width: 480px) {
 .ss_buttonView {
      width: fit-content;
  }
}

.ss_resultContent {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.ss_scoreContent {
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin: 30px;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.ss_input {
  display: flex;
  width: 50%;
}

.ss_retakeView {
  display: flex;
  margin: 35px 0 10px 0;
  align-self: center;
}

.ss_retakeStyle {
  text-align: left;
  font-family: RubikRegular;
  font-size: 16px;
  font-weight: 500;
  text-decoration-line: underline;
  color: #003049;
}