.fl_container {
  display: flex;
  height: 100vh;
  overflow: hidden;
  background: #F7F8FA;
  flex-direction: column;
  position: relative;
}

@media screen and (min-width: 100%) {
  .fl_container {
    width: 100%;
  }
}

.fl_contentView {
  display: flex;
  width: calc(100% - 48px);
  margin: 0px 24px 0 24px;
  overflow-y: scroll;
  flex-direction: column;
  padding-bottom: 24px;
}

.fl_title_view {
  display: flex;
  padding: 12px;
  width: 100%;
  background: linear-gradient(121.37deg, rgb(233, 244, 252) 9.99%, rgb(222, 237, 248) 68.94%);
  border-radius: 8px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.fl_title_desc_view {
  display: flex;
  flex-direction: column;
}

.fl_titleStyle {
  font-family: 'RubikMedium';
  font-weight: 500;
  font-size: 24px;
  color: #0B2653;
}

.fl_infoStyle {
  display: flex;
  width: 70%;
  margin-top: 8px;
  font-family: 'RubikRegular';
  font-weight: 400;
  font-size: 16px;
  color: #51668A;
}

.fl_retirement_icon {
  width: 100px;
  height: 100px;
  display: flex;
}

.fl_gp_view {
  display: flex;
  width: 100%;
  margin-top: 24px;
  flex-direction: column;
}

.fl_gp_text {
  font-family: 'RubikMedium';
  font-weight: 500;
  font-size: 20px;
  color: #0B2653;
}

.fl_gp_content {
  width: 100%;
  background: #FFFFFF;
  box-shadow: 0px 2px 4px rgba(46, 79, 91, 0.08);
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  gap: 5px;
  margin-top: 24px;
  padding: 12px 24px;
}

.fl_sepearatorView {
  display: flex;
  width: 1px;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.12);
}

.fl_titleView {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.fl_labelText {
  display: flex;
  text-align: right;
  font-family: RubikRegular;
  font-size: 14px;
  font-weight: 500;
  text-decoration-line: underline;
  background: linear-gradient(270deg, #003049 0.01%, #005887 105.19%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  flex-wrap: nowrap;
}

.fl_linkView {
  display: flex;
  flex-direction: row;
  gap: 24px;
}

.fl_alertView {
  display: flex;
  width: 80%;
  height: 100%;
  align-self: center;
}