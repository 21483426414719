.resd_container {
  display: flex;
  height: 100vh;
  overflow: hidden;
  background: #f7f8fa;
  flex-direction: column;
  position: relative;
}

.resd_content_view {
  display: flex;
  width: calc(100% - 48px);
  margin: 0px 24px 24px 24px;
  overflow-x: hidden;
  overflow-y: scroll;
  flex-direction: column;
  margin-bottom: 50px;
}

.resd_input {
  display: flex;
  width: 429px;
  margin-right: 24px;
}

.resd_description_view {
  display: flex;
  width: 100%;
  height: calc(100% - 40px);
  flex-direction: column;
  gap: 30px;
}

.resd_input_row {
  display: flex;
  flex-direction: row;
  /* flex-wrap: wrap; */
  align-items: flex-start;
  justify-content: flex-start;
}

.resd_sec_row {
  display: flex;
  align-items: center;
}

.resd_third_row {
  display: flex;
  align-items: center;
}

.resd_toggle_view {
  display: flex;
  flex-direction: row;
  gap: 55px;
  width:453px; 
}

.resd_toggle_text {
  display: flex;
  font-family: 'RubikRegular';
  font-weight: 400;
  font-size: 16px;
  width: 300px;
  color: #0b2653;
}

.resd_seperatorStyle {
  margin: 24px 0px;
  border: 1px solid #e0e0e0;
}

.resd_slider_view {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-start;
}

.resd_start_year_row {
  display: flex;
  flex-direction: column;
  width: 429px;
  margin-right: 24px;
}

.resd_start_text {
  font-family: "RubikRegular";
  font-weight: 400;
  font-size: 16px;
  color: #0b2653;
}

.resd_slider_content {
  display: flex;
  width: 100%;
  margin: 50px 0px 24px 0px;
}

.resd_cost_item_view {
  display: flex;
  margin: 24px 0px 0px;
  width: 881px;
}

.resd_retirement_toggle {
  display: flex;
  margin-bottom: 24px;
}

.resd_bottomView {
  display: flex;
  height: 50px;
  width: 100%;
  background-color: white;
  position: absolute;
  bottom: 0;
  align-items: center;
  justify-content: end;
}

.resd_buttonView {
  display: flex;
  padding: 10px;
}

.resd_sale_button {
  display: flex;
  width: 252px;
  margin-left: 24px;
}

.resd_save {
  display: flex;
  width: 252px;
  margin-left: 24px;
}

@media screen and (min-width: 100%) {
  .resd_container {
    width: 100%
  }
}