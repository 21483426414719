.m_container {
    display: flex;
    width: 256px;
    height: 100vh;
    overflow-y: hidden;
    background-color: #001725;
    flex-direction: column;
    align-items: flex-start;
}

.m_headerView {
    padding-top: 10px;
    padding-left: 24px;
    display: flex;
    width: 100%;
    height: 52px;
    align-self: flex-start;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
}

.m_logoStyle {
    display: flex;
    width: 32px;
    height: 42px;
}

.m_titleStyle {
    margin-left: 13px;
    font-family: RubikMedium;
    font-size: 13px;
    color: white;
}

.m_menuListView {
    display: flex;
    width: 100%;
    /* height: calc(100vh - 52px); */
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.m_menuItemView {
    display: flex;
    position: relative;
    width: 100%;
    padding-left: 24px;
    flex-direction: row;
    align-items: center;
    margin-top: 25px;
}

.m_menuIconStyle {
    display: flex;
    width: 24px;
    height: 24px;
}

.m_expandIconStyle {
    display: flex;
    width: 24px;
    height: 24px;
    position: absolute;
    right: 12px;
}

.m_menuStyle {
    width: 80%;
    margin-left: 12px;
    font-family: RubikRegular;
    font-weight: 400;
    font-size: 14px;
    color: #E0E0E0;
}

.m_subMenuItemView {
    display: flex;
    margin-left: 68px;
    margin-top: 15px;
    flex-direction: row;
    align-items: center;
}

.m_subMenuStyle {
    font-family: RubikLight;
    font-weight: 300;
    font-size: 14px;
    color: #E0E0E0;
}