.rs-table {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: white;
}

.rs-table-column-group-header-content {
    height: 30px;
    width: 400px;
    text-align: center;
    color: #000000;
    background: linear-gradient(100deg, #FFCA7A -48.61%, #FFE8AE 100%);
    font-family: "RubikRegular";
    font-weight: 700;
    font-size: 14px;
}

.rs-table-cell-header .rs-table-cell-content {
    text-align: center;
    color: #000000;
    background: linear-gradient(100deg, #FFCA7A -48.61%, #FFE8AE 100%);
    font-family: "RubikRegular";
    font-weight: 500;
    font-size: 14px;
    border-top: 1px solid white;
}

.rs-table-cell-content {
    width: 200px;
    height: 30px;
    text-align: center;
    color: #000000;
    background-color: #FFFFFF;
}