.f_container {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    background-color: white;
}

.f_labelStyle {
    display: flex;
    font-family: 'RubikRegular';
    font-size: 14px;
    color: #51668A;
    text-align: center;
    vertical-align: middle;
}