.c_container {
    display: flex;
    width: 100%;
    height: 100% !important;
}

.c_carouselView {
    display: flex;
    width: 100%;
    height: 100% !important;
    background-color: transparent !important;
}

.c_contentView {
    display: flex;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, #FFF5DC 0%, #FFF5DC 111.86%);
    position: relative;
    align-items: center;
    justify-content: center;
}

.c_labelView {
    display: flex;
    width: 100%;
    position: absolute;
    bottom: 30px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.c_labelStyle {
    font-family: 'RubikMedium';
    font-size: 32px;
    color: #0B2653;
    text-align: center;
    vertical-align: middle;
}

.c_subLabelStyle {
    font-family: 'RubikRegular';
    font-size: 16px;
    color: #0B2653;
    text-align: center;
    vertical-align: middle;
}

.c_itemImgStyle {
    display: flex;
    width: 80%;
    height: 80%;
    align-self: center;
}