.elo_container {
  display: flex;
  height: 100vh;
  overflow: hidden;
  background: #F7F8FA;
  flex-direction: column;
  position:relative;
}

.elo_content_view {
  display: flex;
  width: calc(100% - 48px);
  height: calc(100% - 60px);
  margin: 0px 24px 24px 24px;
  overflow-y: hidden;
  flex-direction: column;
  margin-bottom: 50px;
}

.elo_input {
  display: flex;
  width: 429px;
  margin-right: 24px;
}

.elo_expenselist {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}

.elo_title_row {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 850px;
  justify-content: space-between;
}

.elo_title_column {
  display: flex;
  flex-direction: column;
}

.elo_title {
  font-family: 'RubikRegular';
  font-weight: 600;
  font-size: 14px;
  color: #0B2653;
  margin-top: 16px;
  margin-bottom: 24px;
  display: flex;
}

.elo_input_fields {
  display: flex;
  flex-direction: column;
  color: #0B2653;
  opacity: 0.6;
  gap: 24px;
  margin-bottom: 10px;
}

.elo_bottomView {
  display: flex;
  width: 100%;
  height: 50px;
  background-color: white;
  position: absolute;
  bottom: 0;
  align-items: center;
  justify-content: end;
}

.elo_buttonView {
  display: flex;
  padding: 10px;
}

.elo_save {
  display: flex;
  width: 252px;
}

@media screen and (min-width: 100%) {
  .elo_container {
    width: 100% 
  }
}

.elo_toggle_view {
  display: flex;
  flex-direction: row;
  gap: 55px;
}

.elo_toggle_text {
  display: flex;
  font-family: "RubikRegular";
  font-weight: 400;
  font-size: 16px;
  width: 300px;
  color: #0b2653;
}

.elo_input_row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
}

.elo_fir_row{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.elo_sec_row {
  display: flex;
  margin: 33px 0px 66px;
  align-items: center;
}

.elo_seperatorStyle {
  margin: 0px 0px 24px;
  border: 1px solid #e0e0e0;
}

.elo_retirement_toggle {
  display: flex;
  margin-bottom: 24px;
}