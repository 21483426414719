.rd_viewStyle {
    position: relative;
    margin-left: 20px;
    margin-top: 10px;
    align-self: center;
    align-items: center;
    justify-content: center;
  }
  
  .rd_diallerViewStyle {
    align-self: center;
    align-items: center;
    justify-content: center;
  }
  
  .rd_valueViewStyle {
    position: absolute;
    top: 25px;
    left: 15px;
    align-self: center;
    align-items: center;
    justify-content: center;
  }
  