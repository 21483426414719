.hce_container {
  display: flex;
  height: 100vh;
  overflow: hidden;
  background: #F7F8FA;
  flex-direction: column;
  position: relative;
}

@media screen and (min-width: 100%) {
  .hce_container {
    width: 100%;
  }
}

.hce_content_view {
  display: flex;
  width: calc(100% - 48px);
  margin: 0px 24px 24px 24px;
  overflow-x: hidden;
  overflow-y: scroll;
  flex-direction: column;
  margin-bottom: 50px;
}

.hce_description_view {
  margin: 33px 24px 0px 0px;
  display: flex;
  flex-direction: row;
}

.hce_expense {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.hce_expense_text {
  font-family: 'RubikRegular';
  font-weight: 400;
  font-size: 16px;
  color: #0B2653;
  margin-right: 24px;
}

.hce_toggle {
  display: flex;
  width: 150px;
}

.hce_seperatorStyle {
  margin: 24px 0px;
  border: 1px solid #E0E0E0;
}

.hce_input {
  display: flex;
  width: 429px;
  margin-right: 24px;
}

.hce_slider_view {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 0px 24px 0px 0px;
  justify-content: flex-start;
}

.hce_start_year_row {
  display: flex;
  flex-direction: column;
  width: 429px;
  margin-right: 24px;
}

.hce_start_text {
  font-family: 'RubikRegular';
  font-weight: 400;
  font-size: 16px;
  color: #0B2653;
}

.hce_slider_content {
  display: flex;
  width: 100%;
  margin: 50px 0px 24px 0px;
}

.hce_bottomView {
  display: flex;
  width: 100%;
  height: 60px;
  padding-right: 24px;
  background-color: white;
  position: absolute;
  bottom: 0;
  align-items: center;
  justify-content: flex-end;
}

.hce_buttonView {
  display: flex;
  margin-top: 10px;
  align-items: center;
}

.hce_save {
  display: flex;
  width: 252px;
  height: 48px;
}