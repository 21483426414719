.gd_viewStyle {
  position: relative;
  margin-left: 20px;
  margin-top: 10px;
  align-self: center;
  align-items: center;
  justify-content: center;
}

.gd_diallerViewStyle {
  align-self: center;
  align-items: center;
  justify-content: center;
}

.gd_valueViewStyle {
  position: absolute;
  top: -10px;
  left:-20px;
  align-self: center;
  align-items: center;
  justify-content: center;

}
