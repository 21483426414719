.te_container {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  overflow-x: auto;
  gap: 24px;
}

.te_content {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  overflow-x: scroll !important;
}


.te_itemDisplayView {
  display: flex;
  margin-right: 24px;
  width: 500px !important;
  height: 100% !important;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  border-radius: 4px;
  background: #FFF;
  border: solid 0.1px rgba(1, 63, 96, 0.11);
  box-shadow: 0px 10px 10px 0px rgba(1, 63, 96, 0.11);
}

.te_feedbackView {
  display: flex;
  flex-direction: column;
}

.te_quoteStyle {
  display: flex;
  width: 40px;
  height: 40px;
}

.te_itemStyle {
  display: flex;
  margin: 20px 0;
  width: 100%;
  color: #474747;
  font-family: RubikRegular;
  font-size: 14px;
  font-weight: 400;
  flex-wrap: wrap;
  text-align: justify;
}

.te_nameView {
  display: flex;
  flex-direction: column;
}

.te_avatarStyle {
  display: flex;
  margin: 20px 0 10px 0;
  width: 48px;
  height: 48px;
  border-radius: 24px;
  flex-shrink: 0;
  background-color: #EDF6FF;
  align-items: center;
  justify-content: center;
  color: #000;
  font-family: RubikMedium;
  font-size: 18px;
  font-weight: 500;
}

.te_nameStyle {
  color: #013F60;
  font-family: RubikMedium;
  font-size: 20px;
  font-weight: 500;
}

.te_companyStyle {
  color: #013F60;
  font-family: RubikRegular;
  font-size: 16px;
  font-weight: 400;
}