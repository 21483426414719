.sm_main_div {
  margin: 15px;
  display: flex;
  overflow-y: scroll;
  flex-direction: column;
  height: calc(100vh - 110px);
}

.sm_header {
  color: #0b2653;
  font-size: 16px;
  font-family: RubikMedium;
}

.sm_item_container {
  gap: 10px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.sm_itemView{
  margin-top: 14px;
}