.arp_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
  background: #f7f8fa;
}

@media screen and (min-width: 100%) {
  .arp_container {
    width: 100%
  }
}

.arp_content_view {
  display: flex;
  width: calc(100% - 48px);
  margin: 20px 24px 24px 24px;
  overflow-y: scroll;
  flex-direction: column;
}

.arp_picture_riskprofile {
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
}

.arp_risk_label {
  font-family: "RubikMedium";
  font-size: 24px;
  color: #0b2653;
  align-items: center;
  justify-content: center;

}

.arp_riskprofile_content {
  display: flex;
  width: 50%;
  flex-direction: column;
  padding-right: 20px;
}

.arp_policy_picture {
  display: flex;
  flex-direction: row;
  width: 50%;
  height: 100%;
}

.arp_pictureStyle {
  display: flex;
  width: 100%;
  height: 100%;
}

.arp_slider {
  display: flex;
  gap: 20px;
  height: 100%;
}

.arp_slider_ability {
  width: 50%;
  display: flex;
  padding: 24px;
  border-radius: 8px;
  background: #ffffff;
  /* background:#02faea; */
  box-shadow: 0px 4px 12px rgba(1, 63, 96, 0.1);
  flex-direction: column;
  margin-bottom: 70px;
  height: 100%;
}

.arp_abilityTitleStyle {
  display: flex;
  width: 100%;
  font-family: "RubikMedium";
  font-size: 18px;
  color: #0b2653;
  align-items: flex-start;
}

.arp_sliderView {
  display: flex;
  width: 95%;
  align-items: flex-start;
  margin-top: 30px;
}

.arp_end_icon {
  display: flex;
  width: 180px;
  height: 180px;
}

.arp_risk_profile {
  justify-content: space-around;
  display: flex;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(1, 63, 96, 0.1);
  align-items: center;
  background: linear-gradient(121.37deg, rgb(233, 244, 252) 9.99%, rgb(222, 237, 248) 68.94%);
}

.arp_slider_willing {
  width: 50%;
  display: flex;
  padding: 24px;
  border-radius: 8px;
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(1, 63, 96, 0.1);
  flex-direction: column;
  margin-bottom: 70px;
  height: 100%;
}

.arp_willingTitleStyle {
  display: flex;
  width: 100%;
  font-family: "RubikMedium";
  font-size: 18px;
  color: #0b2653;
  align-items: flex-start;
}

.arp_willingSliderView {
  display: flex;
  width: 95%;
  align-items: flex-start;
  margin-top: 30px;
}

.arp_balance_corpus {

  display: flex;
  margin-top: 16px;
  font-family: RubikRegular;
  font-size: 16px;
  padding-right: 20px;
}

.arp_policy_picture {
  display: flex;
  flex-direction: row;
  width: 50%;
}

.arp_genderIcon {
  display: flex;
  width: 100px;
  height: 100px;
}

.mark-label-portions {
  margin-top: 14px;
  font-family: 'RubikRegular';
  font-weight: 400;
  font-size: 14px;
  color: #51668A;
}

.arp_gauge_chart {
  background: #FFFFFF;
  box-shadow: 0px 4px 12px rgba(1, 63, 96, 0.1);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.arp_bottomView {
  display: flex;
  width:100%;
  height: 55px;
  background-color: white;
  position: absolute;
  bottom: 0;
  padding-top: 10px;
  padding-right: 35px;
  align-items: center;
  justify-content: end;
  box-shadow: 1px 1px 4px 1px rgba(6, 73, 109, 0.1);
}

.arp_buttonView {
  display: flex;
  position: absolute;
  flex-direction: row;
  width: 252px;
  height: 48px;
}