.rsm_container {
  display: flex;
  width: 40vw;
  margin: 20vh 40vw;
  padding: 1%;
  overflow: hidden;
  align-self: center;
}

.rsm_content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.rsm_headerView {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.rsm_titleStyle {
  color: #013F60;
  font-family: RubikRegular;
  font-size: 24px;
  font-weight: 400;
}

.rsm_inputsView {
  margin: 10% 5% 5% 5%;
}

.rsm_buttonView {
  display: flex;
  margin-top: 5%;
  width: 50%;
  align-self: center;
}