.App {
  text-align: center;
}


.app-containerStyle {
  display: flex;
  width: 100vw;
  height: 100vh;
  margin: auto;
  overflow: auto;
  position: relative;
  flex-direction: row;
  border: 0 solid gainsboro;
  background-color: white;
}

.app-contentStyle {
  display: flex;
  width: calc(100% - 256px);
  height: 100%;
  flex-direction: column;
}

.app-contentStyle_2 {
  display: flex;
  width: calc(100% - 68px);
  height: 100%;
  flex-direction: column;
}

.app-contetStyle_3 {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


/** change Scrollbar styles  **/
::-webkit-scrollbar {
  display: flex;
  border-radius: 4px;
  background-color: #FFFFFF;
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgb(179, 179, 179);
}

@media screen and (min-width: 1600px) {
  .app-containerStyle {
    max-width: 1600px;
    border-left-width: 1px;
    border-right-width: 1px;
  }
}

@media screen and (max-width: 1024px) {
  .app-containerStyle{
    max-width: 1024px;
    border-left-width: 1px;
    border-right-width: 1px;
  }
}