.rr_container {
  display: flex;
  width: 75%;
  padding: 1%;
  overflow: hidden;
}
@media (max-width: 768px) {
 .rr_container {
  display: flex;
  width: 95%;
 }
}

.rr_resultContainer {
  display: flex;

  height: 98vh;

  padding: 1%;
  overflow: hidden;
  align-self: center;
  align-items: center;
}

.rr_content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: scroll;
}

.rr_formContent {
  display: flex;
  width: 100% !important;
  align-self: center;
  flex-direction: column;
}


.rr_headerView {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.rr_headingStyle {
  display: flex;
  margin-right: 50px;
  color: #013F60;
  font-family: RubikRegular;
  font-size: 24px;
  font-weight: 400;
  align-items: center; 
}


@media (max-width: 1024px) {
  .rr_headingStyle {
    font-size: 22px; 
    margin-right: 30px; 
  }
}


@media (max-width: 768px) {
  .rr_headingStyle {
    font-size: 20px; 
    margin-right: 20px;
    font-weight: 500;
  }
}


@media (max-width: 480px) {
  .rr_headingStyle {
    font-size: 18px; 
    margin-right: 15px; 
  }
}



.rr_inputsContentView {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
}

.rr_rowStyle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 20px;
}

.rr_colStyle {
  flex: 1;
  min-width: 200px; 
}

.rr_labelStyle {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #013F60;
}

.rr_inputsRowView {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
}


@media (max-width: 1024px) {
  .rr_inputsContentView {
    padding: 15px;
    gap: 15px; 
  }

  .rr_rowStyle {
    flex-direction: column;
    gap: 30px;
  }

  .rr_colStyle {
    min-width: 100%; 

  }

  .rr_inputsRowView {
    flex-direction: column;
    gap: 15px;
  }

  .rr_labelStyle {
    font-size: 17px; 
  }
}


@media (max-width: 768px) {
  .rr_inputsContentView {
    padding: 10px;
    gap: 10px;
  }

  .rr_rowStyle {
    flex-direction: column; 
    gap: 25px;
  }

  .rr_colStyle {
    min-width: 100%; 
  }

  .rr_inputsRowView {
    flex-direction: column;
    gap: 10px;
  }

  .rr_labelStyle {
    font-size: 16px;
  }
}


@media (max-width: 480px) {
  .rr_inputsContentView {
    padding: 5px;
    gap: 5px; 
  }

  .rr_rowStyle {
    flex-direction: column;
    gap: 20px;
  }

  .rr_colStyle {
    min-width: 100%;
  }

  .rr_inputsRowView {
    flex-direction: column;
    gap: 8px; 
  }

  .rr_labelStyle {
    font-size: 14px;
    text-align: center; 
    margin-bottom: 8px; 
  }
}


@media (max-width: 360px) {
  .rr_inputsContentView {
    padding: 0px;
    gap: 0px;
  }

  .rr_rowStyle {
    gap: 15px; 
  }

  .rr_colStyle {
    padding: 5px; 
  }

  .rr_labelStyle {
    font-size: 12px; 
  }

  .rr_inputsRowView {
    gap: 5px; 
  }
}



.rr_labelStyle {
  display: flex;
  margin: 10px 0;
  color: #013F60;
  font-family: RubikRegular;
  font-size: 16px;
  font-weight: 500;
  text-align: left;
}

.rr_sliderOuterView {
  display: flex;
  width: 90%;
  flex-direction: column;
  margin-top: 24px;
}

.rr_sliderView {
  display: flex;
  width: 100%;
  margin: 24px 0 24px 0px;
}
.rr_closeIconStyle{
  cursor: pointer;
}
.rr_buttonView {
  display: flex;
  width: 20%;
 
  align-self: center;
  align-items: center;
  justify-content: center;
}
@media (max-width: 768px) {
  .rr_buttonView {
    width: fit-content;
  }
}

.rr_resultContent {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.rr_corpusContent {
  margin: 0 20px 10px 20px;
  display: flex;
  width: 80%;
  flex-direction: column;
}

.rr_labelNsliderView {
  margin: 5px 0;
  display: flex;
  width: 100%;
  flex-direction: column;
}

.rr_subLabelStyle {
  font-family: RubikRegular;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  color: #51668a;
}

.rr_amtSliderView {
  display: flex;
  width: 100%;
  height: 5px;
  margin: 10px 0;
  border-radius: 5px;
  flex-direction: row;
  align-items: center;
}

.rr_amtSliderStyle {
  display: flex;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  background-color: #EFB741;
}

.rr_amtTextStyle {
  margin-left: 5px;
  font-family: RubikRegular;
  font-size: 12px;
  font-weight: 500;
  text-align: left;
  color: #576268;
}

.rr_scoreContent {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.rr_input {
  display: flex;
  width: 100%;
}

.rr_retakeView {
  display: flex;
  margin: 35px 0 10px 0;
  align-self: center;
}

.rr_retakeStyle {
  text-align: left;
  font-family: RubikRegular;
  font-size: 16px;
  font-weight: 500;
  text-decoration-line: underline;
  color: #003049;
}