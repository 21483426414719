.pad_container {
    display: flex;
    height: 100vh;
    overflow: hidden;
    background: #f7f8fa;
    flex-direction: column;
    position:relative
  }
  
  .pad_content_view {
    display: flex;
    width: calc(100% - 48px);
    margin: 0px 24px 24px 24px;
    overflow-y: scroll;
    flex-direction: column;
    margin-bottom: 50px;
  }
  
  .pad_input {
    display: flex;
    width: 429px;
    margin-right: 24px;
  }
  
  .pad_description_view {
    display: flex;
    width: 100%;
    height: calc(100% - 40px);
    flex-direction: column;
  }
  
  .pad_input_row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .pad_sec_row {
    display: flex;
    margin-top: 33px;
    align-items: center;
  }
  
  .pad_toggle_view {
    display: flex;
    flex-direction: row;
    gap: 55px;
  }
  
  .pad_toggle_text {
    display: flex;
    font-family: "RubikRegular";
    font-weight: 400;
    font-size: 16px;
    width: 300px;
    color: #0b2653;
  }
  
  .pad_seperatorStyle {
    margin: 24px 0px;
    border: 1px solid #e0e0e0;
  }
  
  .pad_slider_view {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-start;
  }
  
  .pad_start_year_row {
    display: flex;
    flex-direction: column;
    width: 429px;
    margin-right: 24px;
  }
  
  .pad_start_text {
    font-family: "RubikRegular";
    font-weight: 400;
    font-size: 16px;
    color: #0b2653;
  }
  
  .pad_slider_content {
    display: flex;
    width: 100%;
    margin: 50px 0px 24px 0px;
  }
  
  .pad_cost_item_view {
    display: flex;
    margin: 24px 0px 0px;
    width: 881px;
  }
  
  .pad_retirement_toggle {
    display: flex;
    margin-bottom: 24px;
  }
  
  .pad_bottomView {
    display: flex;
    width: 100%;
    height: 50px;
    background-color: white;
    position: absolute;
    bottom: 0;
    align-items: center;
    justify-content: end;
  }
  
  .pad_buttonView {
    display: flex;
    padding: 10px;
  }
  
  .pad_sale_button {
    display: flex;
    width: 252px;
    margin-left: 24px;
  }
  
  .pad_save {
    display: flex;
    width: 252px;
    margin-left: 24px;
  }
  
  @media screen and (min-width: 100%) {
    .pad_container {
      width: 100% 
    }
  }
  