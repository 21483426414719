.hh_container {
  display: flex;
  margin-top: 15%;
  width: 669px;
  height: 400px;
  border-radius: 18px;
  align-self: center;
  flex-direction: column;
}

.hh_content {
  display: flex;
  width: '100%';
  height: '100%';
  flex-direction: column;
}

.hh_title_view {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.hh_title_style {
  font-family: 'RubikRegular';
  font-weight: 500;
  font-size: 24px;
  color: #0B2653;
  display: flex;
  justify-content: center;
  flex-direction: row;
}

.hh_cancel_icon {
  display: flex;
  position: absolute;
  right: 24px;
}

.hh_itemsView {
  margin-top: 24px;
  display: flex;
  width: 100%;
  padding: 0 20px;
  flex-direction: row;
  align-items: center;
  align-self: center;
  justify-content: space-between;
}

.hh_itemStyle {
  width: 180px;
  height: 210px;
  background: #FFFFFF;
  box-shadow: 1px 1px 4px 1px rgba(6, 73, 109, 0.1);
  border-radius: 8px;
  align-items: center;
  display: flex;
  flex-direction: column;
  border:0px solid #005887;
}

.hh_itemIconStyle {
  display: flex;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: #FFFDE9;
  margin-top: 34px;
}

.hh_itemTextStyle {
  margin-top: 14px;
  display: flex;
  font-family: 'RubikRegular';
  font-weight: 400;
  font-size: 16px;
  color: #013F60;
  flex-wrap: wrap;
  align-items: center;
  text-align: center;
  vertical-align: middle;
}

.hh_nextButton_view {
  display: flex;
  width: 206px;
  height: 48px;
  margin-top: 32px;
  align-self: center;
  align-items: center;
  justify-content: center;
}
