.gwt_container {
  display: flex;
  height: 100vh;
  overflow: hidden;
  background: #F7F8FA;
  flex-direction: column;
  position: relative;
}

.gwt_content_view::-webkit-scrollbar {
  display: none;
}

@media screen and (min-width: 100%) {
  .gwt_container {
    width: 100%;
  }
}

.gwt_content_view {
  display: flex;
  width: calc(100% - 48px);
  margin: 0px 24px 24px 24px;
  overflow-y: scroll;
  flex-direction: column;
  margin-bottom: 50px;
}

.gwt_input {
  display: flex;
  width: 429px;
  margin-right: 24px;
  margin-top: 24px
}

.gwt_bottomView {
  display: flex;
  width: 100%;
  height: 60px;
  padding-right: 24px;
  background-color: white;
  position: absolute;
  bottom: 0;
  align-items: center;
  justify-content: flex-end;
}

.gwt_buttonView {
  display: flex;
  width: 252px;
  height: 38px;
}

.gwt_save {
  display: flex;
  width: 252px;
  margin-left: 24px;
}