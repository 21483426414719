.re_container {
  display: flex;
  height: 100vh;
  overflow: hidden;
  background: #F7F8FA;
  flex-direction: column;
  position: relative;
}

@media screen and (min-width: 100%) {
  .re_container {
    width: 100%;
  }
}

.re_content_view {
  display: flex;
  width: calc(100% - 48px);
  height: calc(100% - 60px);
  margin: 0px 24px 24px 24px;
  overflow-y: hidden;
  flex-direction: column;
}

.re_input {
  display: flex;
  width: 429px;
  margin-right: 24px;
}

.re_content {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: calc(100% - 150px);
  background: #FFFFFF;
  box-shadow: 0px 4px 12px rgba(1, 63, 96, 0.1);
  border-radius: 8px;
  margin-top: 24px;
}

.re_menu_view {
  display: flex;
  flex-direction: column;
  width: 338px;
  border-top-left-radius: 8px;
  border-right: 1px solid #E0E0E0;
  position: relative;
}

.re_sub_menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #E0E0E0;
  font-family: 'RubikRegular';
  font-weight: 400;
  cursor: pointer;
  height: 80px;
  padding-left: 28px;
}

.re_selected_submenu {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #E0E0E0;
  background-color: #EFB741;
  font-family: 'RubikRegular';
  font-weight: 500;
  cursor: pointer;
  padding-left: 28px;
  height: 80px;
}

.re_menu_items {
  width: 246px;
  height: 48px;
  font-family: 'RubikRegular';
  font-weight: 500;
  font-size: 16px;
  color: #0B2653;
  align-items: center;
  display: flex;
}

.re_arrow {
  padding-right: 33px;
  color: white;
}

.re_or_style {
  display: flex;
  position: absolute;
  align-self: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 12px;
  border-radius: 50%;
  top: 60px;
  background: #FFFFFF;
  color: #0B2653;
  font-family: 'RubikRegular';
  font-weight: 400;
  font-size: 14px;
  border: 1px solid #E0E0E0;
  width: 40px;
  height: 40px;
}

.re_expenselist {
  display: flex;
  flex-direction: column;
  margin-left: 24px;
  height: 100%;
}

.re_title {
  width: 300px;
  height: 22px;
  font-family: 'RubikRegular';
  font-weight: 600;
  font-size: 16px;
  color: #0B2653;
  margin: 24px 0px;
}

.re_text {
  font-family: 'RubikRegular';
  font-weight: 400;
  font-size: 16px;
  color: #51668A;
  margin: 24px 0px;
}

.re_title_row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.re_titleView {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding-left: 55px;
}

.re_title {
  width: 300px;
  height: 22px;
  font-family: 'RubikRegular';
  font-weight: 600;
  font-size: 16px;
  color: #0B2653;
  margin: 24px 55px 24px 0px;
}

.re_total {
  display: flex;
  font-family: 'RubikRegular';
  font-weight: 400;
  color: #51668A;
  font-size: 16px;
}

.re_highlighted-text {
  display: flex;
  color: #51668A;
  font-family: 'RubikRegular';
  font-weight: 600;
  font-size: 16px;
}

.re_input_fields {
  display: flex;
  flex-direction: column;
  color: #0B2653;
  opacity: 0.6;
  overflow: auto;
  gap: 24px;
  margin-bottom: 24px;
  padding-top: 2px;
}

.re_mis_text {
  width: 176px;
  height: 16px;
  font-family: 'RubikRegular';
  font-weight: 400;
  font-size: 12px;
  color: #51668A;
}

.re_dummy_text {
  display: flex;
  margin-top: 30px;
  font-family: 'RubikRegular';
  font-weight: 400;
  font-size: 16px;
  color: #51668A;
}

.re_bottomView {
  display: flex;
  width: 100%;
  height: 60px;
  padding-right: 24px;
  background-color: white;
  position: absolute;
  bottom: 0;
  align-items: center;
  justify-content: flex-end;
}

.re_buttonView {
  display: flex;
  margin-top: 12px;
  width: 252px;
  height: 48px;
}