.rph_container {
  display: flex;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background: #f7f8fa;
  flex-direction: column;
}

.rph_content {
  display: flex;
  width: calc(100%-48px);
  margin: 0px 24px 24px 24px;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
}

.rph_profileView {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.rph_start_icon {
  display: flex;
  flex-direction: row;
}

.rph_info_view {
  width: 700px;
  height: 66px;
  left: 205px;
  top: 440px;
  font-family: "RubikRegular";
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #51668a;
}

.rph_buttonView {
  display: flex;
  width: 250px;
  height: 38px;
  margin-top: 24px;
}
