.fdc_container {
  display: flex;
  width: 100%;
  padding: 24px;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(1, 63, 96, 0.1);
  border-radius: 8px;
}

.fdc_contentHeader {
  display: flex;
  width: 100%;
  position: relative;
}

.fdc_logoStyle {
  display: flex;
  width: 40px;
  height: 40px;
  border-radius: 4px;
}

.fdc_titleNamtView {
  margin: 0 44px 0 12px;
  display: flex;
  flex-direction: column;
}

.fdc_titleStyle {
  display: flex;
  font-family: "RubikMedium";
  font-weight: 500;
  font-size: 16px;
  color: #0b2653;
  flex-wrap: wrap;
}

.fdc_amtStyle {
  display: flex;
  margin-top: 12px;
  font-family: "RubikMedium";
  font-size: 14px;
  color: #0b2653;
}

.fdc_expand_icon {
  position: absolute;
  right: 0;
  display: flex;
  width: 32px;
  transition: 0.5s;
}

.fdc_expand_icon_rorate {
  position: absolute;
  right: 0;
  display: flex;
  width: 32px;
  transition: 0.5s;
  transform: rotate(180deg);
}

.fdc_detailsView {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.fdc_separatorStyle {
  display: flex;
  width: 100%;
  margin: 24px 0;
  border: 1px solid #e0e0e0;
}

.fdc_detailsRowView {
  display: grid;
  grid-template-columns: 33% 33% 33%;
}

.fdc_labelNvalueView {
  display: flex;
  flex-direction: column;
}

.fdc_labelStyle {
  display: flex;
  font-family: "RubikMedium";
  font-size: 16px;
  color: #0b2653;
}

.fdc_valueStyle {
  margin-top: 8px;
  font-family: "RubikRegular";
  font-size: 14px;
  color: #51668a;
}

.fdc_ratingUrlStyle {
  display: flex;
  width: 64px;
  height: 17px;
}

.fdc_ratingStyle {
  margin-top: 9px;
  background: linear-gradient(270deg, #005887 0.01%, #003049 105.19%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  gap: 2px;
}
