.ts-viewStyle {
    display: flex;
    flex-direction: column;
    gap: 20px;
    overflow-y:auto;
  }
  
  .ts-rowStyle {
    padding: 0;
    display: flex;
    align-self: flex-start;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .ts-colStyle {
    padding: 0;
    display: flex;
    cursor: pointer;
    align-self: flex-start;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
  }