.st_container {
  display: flex;
  width: calc(100vw - 256px);
  height: 100vh;
  overflow: hidden;
  background: #F7F8FA;
  flex-direction: column;
}

.st_content_view {
  display: flex;
  width: calc(100% - 48px);
  margin: 0px 24px 24px 24px;
  overflow-y: scroll;
  flex-direction: column;
  margin-bottom: 50px;
}

.st_profile_view {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.st_icon {
  display: flex;
  margin-bottom: 16px;
}

.st_info_view {
  font-family: 'RubikRegular';
  font-weight: 400;
  font-size: 16px;
  color: #51668A;
  width: 467px;
  height: 44px;
  text-align: center;
}

.st_save {
  display: flex;
  width: 252px;
  margin-left: 24px;
}