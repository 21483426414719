.addrow_modal {


    border-radius: 18px;
    width: 100%;
    height: 100%;
    overflow-y: scroll;

}


.addrow_modal-content {
    display: flex;
    gap: 10px;
    flex-direction: column;
    padding: 20px;
    width: 100%;

}

.addrow_close {
    display: flex;
    color: #aaa;
    font-size: 25px;
    font-weight: bold;
    font-family: 'RubikRegular';
}

.addrow_close:hover,
.addrow_close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

.addrow_heading {
    display: flex;
    font-family: 'RubikRegular';
    color: #0B2653;
    padding-top: 8px;
    font-size: 16px;
    font-weight: bold;
    padding-left: 4px;
}

.addrow_title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.addrow_inputView {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 80%;
    align-self: center;
}

.addrow_buttonView {
    display: flex;
    margin-top: 24px;
    width: 100px;
    align-self: center;
    align-items: center;
    justify-content: center;
}