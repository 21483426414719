.cvd_container {
  width: 100%;
  height: 100%;
  position:relative;

}

.cvd_tabSelectionView {
  width: 100%;
}

.cvd_downloadView {
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  padding: 10px 20px;
  border-radius: 5px;
  color: white;
  background: linear-gradient(270deg, #0e4969 0.01%, #0173b0 105.19%);
}
