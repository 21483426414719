.ant-switch {
  background: linear-gradient(270deg, #003049 0.01%, #005887 105.19%);
}

[data-app-id="my-app-id"] .ant-switch-handle::before {
  background: #EFB741;
}

.ant-switch-checked .ant-switch-inner {
  font-size: 14px;
  font-weight: 600;
  font-family: "RubikRegular";
  background: linear-gradient(270deg, #0E4969 0.01%, #0173B0 105.19%);
  box-shadow: inset 0px 3px 20px rgba(0, 0, 0, 0.15);
}

.ant-switch-checked .ant-switch-inner-checked {
  font-family: 'RubikRegular';
  font-weight: 600;
  font-size: 14px;
}

.ant-switch-checked .ant-switch-inner-unchecked {
  font-size: 14px;
  font-weight: 600;
  font-family: 'RubikRegular';
}

.ant-switch-checked .ant-switch-inner {
  background: linear-gradient(270deg, #0E4969 0.01%, #0173B0 105.19%);
  box-shadow: inset 0px 3px 20px rgba(0, 0, 0, 0.15);
}

.ant-switch-inner {
  background: linear-gradient(270deg, #0E4969 0.01%, #0173B0 105.19%);
  box-shadow: inset 0px 3px 20px rgba(0, 0, 0, 0.15);
}

.ant-switch-inner-unchecked {
  font-family: 'RubikRegular';
  font-weight: 600;
  font-size: 14px;
}
