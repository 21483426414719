.gl_container {
  display: flex;
  height: 100vh;
  overflow: hidden;
  background: #F7F8FA;
  flex-direction: column;
}

@media screen and (min-width: 100%) {
  .gl_container {
    width: 100%;
  }
}

.gl_content_view {
  display: flex;
  width: calc(100% - 48px);
  margin: 0px 24px 0 24px;
  overflow-y: scroll;
  flex-direction: column;
  padding-bottom: 24px;
}

.gl_separatorStyle {
  display: flex;
  margin-top: 22px;
  width: 972px;
  height: 2px;
  background: #576268;
  opacity: 0.1;
}

.gl_titleView {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.gl_titleStyle {
  font-family: 'RubikMedium';
  font-weight: 500;
  font-size: 24px;
  color: #0B2653;
}

.gl_infoStyle {
  font-family: 'RubikRegular';
  font-weight: 400;
  font-size: 16px;
  color: #51668A;
  margin-bottom: 24px;
}

.gl_informationView {
  display: flex;
  width: 100%;
  height: calc(100% - 40px);
}

.gl_cardsView {
  display: flex;
  flex-direction: row;
}

.gl_cards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 23px;
}

.gl_hh_expense_icon {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 5px 1px;
}