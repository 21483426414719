.li_container {
    display: flex;
    flex-direction: row;
    width: 800px;
    height: 24px;
}

.li_bar {
    height: 100%;
    position: relative;
}

.li_equity {
    background-color: #EFB741;
}

.li_debt {
    background-color: #F9CF81;
}

.li_commodities {
    background-color: #1B4563;
}

.li_value_equity {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: 500;
    font-size: 12px;
    color: #013F60;
    font-family: "RubikRegular";
    text-align: center;
}

.li_value_debt {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: 500;
    font-size: 12px;
    color: #013F60;
    font-family: "RubikRegular";
    text-align: center;
}

.li_value_commadities {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: 500;
    font-size: 12px;
    color: #FFFFFF;
    font-family: "RubikRegular";
    text-align: center;
}