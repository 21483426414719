.rp_container {
  display: flex;
  height: 100%;
  overflow: hidden;
  background: #f7f8fa;
  flex-direction: column;
  position: relative;
}

@media screen and (min-width: 100%) {
  .rp_container {
    width: 100%;
  }
}

.rp_content {
  display: flex;
  width: calc(100% - 48px);
  height: calc(100% - 120px);
  flex-direction: column;
  align-self: center;
}

.rp_riskProfileView {
  display: flex;
  width: 100%;
  padding-bottom: 24px;
  overflow-y: scroll;
  flex-direction: column;
}

.rp_titleView {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
}

.rp_titleStyle {
  font-family: "RubikMedium";
  font-weight: 500;
  font-size: 24px;
  color: #0b2653;
}

.rp_circularStyle {
  display: flex;
  width: 50px;
  height: 50px;
  margin-left: 24px;
}

.rp_scoreStyle {
  margin-top: 5px;
  margin-left: 12px;
  font-family: "RubikRegular";
  font-weight: 400;
  font-size: 16px;
  vertical-align: middle;
  color: #51668a;
}

.rp_sliderView {
  display: flex;
  width: 100%;
  margin-top: 12px;
}

.rp_separatorStyle {
  display: flex;
  width: 100%;
  height: 2px;
  background: #576268;
  opacity: 0.1;
}

.rp_questionsNoptionsView {
  margin-top: 24px;
  display: flex;
  width: 100%;
}

.rp_questionsView {
  display: flex;
  width: 50%;
  flex-direction: column;
}

.rp_qLabelStyle {
  font-family: "RubikMedium";
  font-weight: 500;
  font-size: 16px;
  color: #0b2653;
}

.rp_quesStyle {
  margin-top: 24px;
  font-family: "RubikRegular";
  font-weight: 400;
  font-size: 18px;
  color: #0b2653;
}

.rp_verticalSeparator {
  margin: 0 24px;
  width: 0px;
  min-height: 300px;
  opacity: 0.3;
  border: 1px solid #000000;
}

.rp_optionsView {
  display: flex;
  width: 50%;
  height: 100%;
  overflow-y: auto;
  flex-direction: column;
}

.rp_oLabelStyle {
  margin-bottom: 24px;
  font-family: "RubikMedium";
  font-weight: 500;
  font-size: 16px;
  color: #0b2653;
}

.rp_itemView {
  display: flex;
  width: 429px;
  min-height: 48px;
  background: #ffffff;
  box-shadow: 1px 1px 4px 1px rgba(6, 73, 109, 0.1);
  border-radius: 8px;
  align-items: center;
  justify-content: flex-start;
}

.rp_choicesView {
  display: flex;
  height: 90%;
  overflow-y: auto;
}

.rp_itemStyle {
  display: flex;
  height: 100%;
  margin-left: 33px;
  font-family: "RubikRegular";
  font-weight: 400;
  font-size: 16px;
  color: #0b2653;
}

.rp_selectedItemView {
  display: flex;
  width: 429px;
  min-height: 48px;
  background: #efb741;
  box-shadow: 1px 1px 4px 1px rgba(6, 73, 109, 0.1);
  border-radius: 8px;
  align-items: center;
  justify-content: flex-start;
}

.rp_selectedItemStyle {
  display: flex;
  height: 100%;
  margin-left: 33px;
  font-family: "RubikMedium";
  font-weight: 500;
  font-size: 16px;
  vertical-align: middle;
  color: #0b2653;
}

.rp_bottomView {
  display: flex;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 50px;
  padding-right: 24px;
  background-color: white;
  align-items: center;
  justify-content: flex-end;
}

.rp_buttonView {
  display: flex;
  margin-top: 12px;
  width: 252px;
  height: 48px;
}

.rp_modal_dispaly {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 10px;
}

.rp_modal {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30%;
  margin-top: 10%;
}

.rp_modal-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.rp_modal_heading {
  display: flex;
  font-family: 'RubikRegular';
  color: #0B2653;
  padding-top: 20px;
  font-size: 16px;
  text-align: center;
}

.rp_modal_subLabel {
  display: flex;
  font-family: 'RubikRegular';
  color: #1e1e1f;
  padding-top: 20px;
  font-size: 14px;
  text-align: center;
}

.rp_modal_close {
  display: flex;
  color: #aaa;
  font-size: 25px;
  font-weight: bold;
  font-family: 'RubikRegular';
  align-items: flex-end;
  justify-content: flex-end;
}

.rp_modal_close:hover,
.rp_modal_close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.rp_modal_msg {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cp_successlIcon {
  display: flex;
  width: 150px;
  height: 120px;
}

.cp_ok {
  display: flex;
  width: 30%;
  padding-top: 20px;
}