.resh_container {
  display: flex;
  height: 100vh;
  overflow: hidden;
  background: #f7f8fa;
  flex-direction: column;
  position: relative;
}

.resh_content_view {
  display: flex;
  width: calc(100% - 48px);
  margin: 0px 24px 24px 24px;
  overflow-y: scroll;
  flex-direction: column;
  margin-bottom: 50px;
}

.resh_profile_view {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.resh_icon {
  display: flex;
  margin-bottom: 16px;
}

.resh_info_view {
  font-family: "RubikRegular";
  font-weight: 400;
  font-size: 16px;
  color: #51668a;
  width: 579px;
  height: 44px;
  text-align: center;
}

.resh_add_property {
  display: flex;
  width: 252px;
  margin-left: 24px;
  margin-top: 20px;
  height: 48px;
}

@media screen and (min-width: 100%) {
  .resh_container {
    width: 100% 
  }
}
