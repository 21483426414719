.sl_container{
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 10px;
  justify-content: space-between;
  overflow: hidden;
}

.sl_content {
  margin-top: 10px;
  width: calc(100% - 80px);
  display: flex;
  flex-direction: row;
  overflow: auto;
}

.left_arrow {
  display: flex;
  width: 30px;
  height: 30px;
  flex-shrink: 0;
  background: #FFFFFF;
  box-shadow: 0px 2px 12px rgba(1, 63, 96, 0.1);
  border-radius: 50%;
}

.sl_array_view {
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
  margin-left: 40px;
  min-width: 50px;
  flex-direction: column;
  align-items: center;
}

.sl_valueView {
  display: flex;
}

.sl_value {
  display: flex;
  gap: 20px;
  color: rgba(77,166,255, 0.37);
}

.sl_highlightValue {
  color: green;
  font-weight: bold;
  font-size: large;
}

.sl_selectedValue {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #013F60;
  font-family: 'RubikRegular';
}

.sl_indicator_style {
  display: flex;
  margin-top: 17px;
  width: 100%;
  height: 2px;
  background: #013F60;
}

.sl_emptyStyle {
  display: flex;
  margin-top: 17px;
  width: 100%;
  height: 2px;
  background: transparent;
}

.right_arrow {
  display: flex;
  width: 30px;
  height: 30px;
  flex-shrink: 0;
  background: #FFFFFF;
  box-shadow: 0px 2px 12px rgba(1, 63, 96, 0.1);
  border-radius: 50%;
}