.va_container {
  display: flex;
  height: 100vh;
  overflow: hidden;
  background: #F7F8FA;
  flex-direction: column;
  position: relative;
}

@media screen and (min-width: 100%) {
  .va_container {
    width: 100%;
  }
}

.va_content_view {
  display: flex;
  width: calc(100% - 48px);
  margin: 0px 24px 24px 24px;
  overflow-x: hidden;
  overflow-y: scroll;
  flex-direction: column;
  padding-bottom: 50px;
}

.va_description_view {
  margin: 33px 24px 0px 0px;
  display: flex;
  flex-direction: row;
}

.va_input {
  display: flex;
  width: 429px;
  margin-right: 24px;
}

.va_expense {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.va_expense_text {
  font-family: 'RubikRegular';
  font-weight: 400;
  font-size: 16px;
  color: #0B2653;
  margin-right: 24px;
}

.va_toggle {
  display: flex;
  width: 180px;
}

.va_seperatorStyle {
  margin: 24px 0px;
  border: 1px solid #E0E0E0;
}

.va_slider_view {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 0px 24px 0px 0px;
  justify-content: flex-start;
}

.va_start_year_row {
  display: flex;
  flex-direction: column;
  width: 429px;
  margin-right: 24px;
}

.va_start_text {
  font-family: 'RubikRegular';
  font-weight: 400;
  font-size: 16px;
  color: #0B2653;
}

.va_slider_content {
  display: flex;
  width: 100%;
  margin: 50px 0px 24px 0px;
}

.va_cost_age_slider {
  display: flex;
  margin: 24px 24px 24px 0px;
  width: 881px;
}

.va_money_inflation {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  box-sizing: border-box;
  margin: 0px 24px 0px 0px;
}

.va_frequency_row {
  display: flex;
  flex-direction: row;
}

.va_expense_frequency_text {
  font-family: 'RubikRegular';
  font-weight: 400;
  font-size: 16px;
  color: #0B2653;
  margin-right: 24px;
}

.va_cost_inflation_input {
  display: flex;
  flex-direction: row;
  margin: 27px 0px 0px 0px;
}

.va_bottomView {
  display: flex;
  width: 100%;
  height: 60px;
  padding-right: 24px;
  background-color: white;
  position: absolute;
  bottom: 0;
  align-items: center;
  justify-content: flex-end;
}

.va_buttonView {
  height: 38px;
  display: flex;
  align-items: center;
}

.va_link_text {
  font-family: 'RubikRegular';
  font-weight: 500;
  font-size: 16px;
  text-decoration-line: underline;
  color: #003049;
  width: 129px;
  display: flex;
  vertical-align: middle;
}

.va_expense_button {
  display: flex;
  width: 252px;
  margin-left: 24px;
}

.va_save {
  display: flex;
  width: 252px;
  margin-left: 24px;
}