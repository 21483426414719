#cdc_wrapper {
  display: grid;
  height: fit-content;
  align-items: center;
  border-radius: 12px;
  background: #ffffff;
  padding: 25px 0 25px 20px;
  margin: 24px 0px 0px 0px;
  width: calc(100% / 2 - 12px);
  justify-content: space-between;
  grid-template-columns: 12fr 1fr;
  box-shadow: 0px 4px 12px rgb(1 63 96 / 10%);
}

@media only screen and (max-width: 768px) {
  #cdc_wrapper {
    width: calc(100%);
  }
}

#cdc_content_view {
  width: 100%;
  gap: 10px;
  display: flex;
  flex-direction: column;
}

#cdc_details {
  font-family: RubikRegular;
  font-size: 16px;
  color: #0b2653
}

#cdc_extra_info {
  margin-left: -5px;
  padding: 5px;
  width: fit-content;
  font-family: "RubikRegular";
  font-size: 16px;
  text-transform: capitalize;
  color: #51668a;
  background: #c7e9f1;
}


.cdc_title {
  font-family: "RubikRegular";
  font-size: 18px;
  color: #0b2653;

}

.cdc_more {
  cursor: pointer;
  padding: 2px;
  display: flex;
  align-self: flex-start;
}

.cdc_current_value {
  display: flex;
  font-family: "RubikRegular";
  font-weight: 400;
  font-size: 16px;
  color: #51668a;
  margin-bottom: 12px;
}

.cdc_extra_content {
  font-family: "RubikRegular";
  font-weight: 400;
  font-size: 16px;
  text-transform: capitalize;
  color: #51668a;
  background: #c7e9f1;
  margin-right: 24px;
  padding: 0px 4px;
}

.cdc_edit_view {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 1px 1px 4px 1px rgba(6, 73, 109, 0.1);
  border-radius: 8px;
  gap: 40px;
  padding: 10px;
  position: absolute;
}

.cdc_edit_row {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-self: flex-start;
  margin: 10px 5px;
  cursor: pointer;
}

.cdc_edit_text {
  display: flex;
  font-family: "RubikRegular";
  font-weight: 400;
  font-size: 14px;
  color: #51668a;
  cursor: pointer;
}
