.bsd_container {
  display: flex;
  height: 100vh;
  overflow: hidden;
  background: #f7f8fa;
  flex-direction: column;
  position:relative;
}

.bsd_content_view {
  display: flex;
  width: calc(100% - 48px);
  margin: 0px 24px 24px 24px;
  overflow-y: scroll;
  flex-direction: column;
  margin-bottom: 50px;
}

.bsd_input {
  display: flex;
  width: 429px;
  margin-right: 24px;
}

.bsd_description_view {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 40px);
}

.bsd_input_row {
  display: flex;
  flex-direction: row;
  gap:30px;
  align-items: flex-start;
  justify-content: flex-start;
}

.bsd_sec_row {
  display: flex;
  margin-top: 33px;
  align-items: center;
}

.bsd_bottomView {
  display: flex;
  width:100%;
  height: 50px;
  background-color: white;
  position: absolute;
  bottom: 0;
  align-items: center;
  justify-content: end;
}

.bsd_buttonView {
  display: flex;
  padding:10px;
}

.bsd_save {
  display: flex;
  width: 252px;
  margin-left: 24px;
}

@media screen and (min-width: 100%) {
  .bsd_container {
    width: 100%;
    background-color: aqua;
  }

}