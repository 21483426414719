.cipy_container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 50px;
  align-items: center;
  background: rgba(239, 183, 65, 0.2);
  border-radius: 4px;
}

.cipy_left_view {
  display: flex;
  margin-left: 24px;
  width: 50%;
  align-items: center;
}

.cipy_info_icon {
  display: flex;
  width: 24px;
  height: 24px;
}

.cipy_labelStyle {
  margin-left: 12px;
  font-family: 'RubikRegular';
  font-weight: 400;
  font-size: 16px;
  text-transform: capitalize;
  color: #013F60;
  flex-wrap: wrap;
}

.cipy_value {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 26px;
}

.cipy_value_content {
  font-family: 'RubikRegular';
  font-weight: 600;
  font-size: 16px;
  text-align: right;
  color: #0B2653;
}

.cipy_edit_icon {
  display: flex;
  width: 20px;
  height: 20px;
}

.cipy_separatorView {
  box-sizing: border-box;
  width: 44px;
  height: 0px;
  background: rgba(239, 183, 65, 0.15);
  border: 1px solid #EFB741;
  transform: matrix(0, 1, 1, 0, 0, 0);
}

.cipy_right_arrow {
  display: flex;
  width: 30px;
  height: 30px;
  background: #FFFFFF;
  box-shadow: 0px 2px 12px rgba(1, 63, 96, 0.1);
  border-radius: 50%;
  cursor: pointer;
}
