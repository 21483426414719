.cg_container {
  display: flex;
  height: 100vh;
  overflow: hidden;
  background: #F7F8FA;
  flex-direction: column;
  position: relative;
}

@media screen and (min-width: 100%) {
  .rp_container {
    width: 100%;
  }
}

.cg_content {
  display: flex;
  width: calc(100%-48px);
  margin: 0px 24px 24px 24px;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
}

.cg_linktext {
  display: flex;
  color: #0B2653;
  font-weight: 600;
  font-size: 16px;
  font-family: 'RubikRegular';
}

.cg_content_view {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.cg_content_row {
  display: flex;
  width: 100%;
  flex-direction: row;
  font-family: 'RubikRegular';
  font-size: 16px;
  align-items: center;
}

.cg_categorised_info {
  font-family: 'RubikMedium';
  font-size: 18px;
  color: #0B2653;
  text-align: center;
  /* background: rgba(255, 220, 98, 0.5);
  border: 1px solid #DCAC00;
  border-radius: 4px;
  padding: 2px */
}

.cg_info_icon {
  display: flex;
  margin-left: 16px;
  width: 18px;
  height: 18px;
}

.cg_delete {
  display: flex;
  width: 40px;
  height: 40px;
}

.cg_info_view {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 1px 1px 4px 1px rgba(6, 73, 109, 0.1);
  border-radius: 8px;
  gap: 40px;
  padding: 10px;
  position: absolute;
}

.cg_info_data {
  margin-left: 14px;
  font-family: 'RubikRegular';
  font-size: 12px;
  color: #51668A;
}

.cg_uncategorizedListView {
  margin: 12px 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 20px;
  overflow-x: auto;
  scroll-behavior: smooth;
  padding-bottom: 14px;
  border-bottom: 1px solid rgba(81, 102, 138, 0.3);
}

.cg_draggable_view {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* border-top: 1px solid rgba(81, 102, 138, 0.3); */
  /* margin-top: 14px; */
  padding-bottom: 24px;
}

.cg_needs {
  display: flex;
  width: calc(100% / 3.2);
  flex-direction: column;
  margin-top: 5px;
}

.cg_cat_title {
  font-family: 'RubikRegular';
  font-weight: 400;
  font-size: 16px;
  color: #51668A;
}

.cg_amount {
  margin-top: 8px;
  font-family: 'RubikMedium';
  font-weight: 500;
  font-size: 20px;
  color: #0B2653;
  margin-bottom: 24px;
}

.cg_listView {
  display: flex;
  width: 90%;
  min-height: 150px;
  flex-direction: column;
  align-self: center;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 4px;
  margin-top: 5px;
  padding: 24px 0;
  gap: 20px;
  box-shadow: 1px 1px 4px 1px rgba(6, 73, 109, 0.1);
}

.cg_dropbox_view {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-self: center;
}

.cg_plus_icon {
  width: 28px;
  height: 28px;
  display: flex;
}

.cg_drop_text {
  font-family: 'RubikRegular';
  font-weight: 400;
  font-size: 12px;
  color: #51668A;
}

.cg_separatorView {
  display: flex;
  border: 1px solid rgba(81, 102, 138, 0.3);
}

.cg_priority_content {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.cg_priority_title {
  left: 66.44%;
  right: 28.39%;
  top: 0%;
  bottom: 61.22%;
  font-family: 'RubikRegular';
  font-weight: 400;
  font-size: 16px;
  color: #0B2653;
}

.priority_view {
  display: flex;
  flex-direction: row;
  gap: 2px;
}

.cg_indicator_level {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-family: 'RubikRegular';
  font-weight: 400;
  font-size: 12px;
  color: #51668A;
}

.cg_bottomView {
  display: flex;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60px;
  padding-right: 24px;
  background-color: white;
  align-items: center;
  justify-content: flex-end;
  box-shadow: 1px 1px 4px 1px rgba(6, 73, 109, 0.1);
}

.cg_buttonsView {
  display: flex;
  position: absolute;
  right: 24px;
  margin-top: 10px;
  flex-direction: row;
}

.cg_buttonView {
  margin-left: 24px;
  display: flex;
  width: 252px;
  height: 48px;
}