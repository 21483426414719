.fddp_container {
  display: flex;
  height: 100vh;
  overflow: hidden;
  background: #f7f8fa;
  flex-direction: column;
  position: relative;
}

.fddp_content_view {
  display: flex;
  width: calc(100% - 48px);
  margin: 0px 24px 24px 24px;
  overflow-y: scroll;
  flex-direction: column;
  margin-bottom: 50px;
}

.fddp_input {
  display: flex;
  width: 429px;
  margin-right: 24px;
}

.fddp_description_view {
  display: flex;
  width: 100%;
  height: calc(100% - 40px);
}

.fddp_input_row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
}

.fddp_sec_row {
  display: flex;
  margin: 33px 0px;
  align-items: center;
}

.fddp_toggle_view {
  display: flex;
  flex-direction: row;
  gap: 55px;
}

.fddp_toggle_text {
  display: flex;
  font-family: "RubikRegular";
  font-weight: 400;
  font-size: 16px;
  max-width: 300px;
  word-wrap: break-word;
  color: #0b2653;
}

.fddp_seperatorStyle {
  margin: 24px 0px;
  border: 1px solid #e0e0e0;
}

.fddp_slider_view {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.fddp_start_year_row {
  display: flex;
  flex-direction: column;
  width: 429px;
}

.fddp_start_text {
  font-family: "RubikRegular";
  font-weight: 400;
  font-size: 16px;
  color: #0b2653;
}

.fddp_slider_content {
  display: flex;
  width: 100%;
  margin: 50px 0px 24px 0px;
}

.fddp_cost_item_view {
  display: flex;
  margin: 24px 0px;
  width: 881px;
}

.fddp_retirement_toggle {
  display: flex;
  margin-bottom: 24px;
  gap: 50px;
}

.fddp_bottomView {
  display: flex;
  width:100%;
  height: 50px;
  background-color: white;
  position: absolute;
  bottom: 0;
  align-items: center;
  justify-content: end;
}

.fddp_buttonView {
  display: flex;
  padding:10px;
}

.fddp_sale_button {
  display: flex;
  width: 252px;
  margin-left: 24px;
}

.fddp_save {
  display: flex;
  width: 252px;
  margin-left: 24px;
}

@media screen and (min-width: 100%) {
  .fddp_container {
    width: 100% 
  }
}
