.aaa_container {
  display: flex;
  height: 100vh;
  overflow: hidden;
  background: #f7f8fa;
  flex-direction: column;
  position: relative;
}

@media screen and (min-width: 100%) {
  .aaa_container {
    width: 100%;
  }
}

.aaa_content_view {
  display: flex;
  width: calc(100% - 48px);
  margin: 0px 24px 0 24px;
  overflow: hidden;
  flex-direction: column;
  padding-bottom: 90px;
}

.aaa_bottomView {
  display: flex;
  position: absolute;
  bottom: 0;
  padding-right: 24px;
  width: 100%;
  height: 50px;
  background-color: white;
  align-items: center;
  justify-content: flex-end;
  box-shadow: 1px 1px 4px 1px rgba(6, 73, 109, 0.1);
  gap: 24px;
}

.aaa_bigButtonView{
  display: flex;
  width: 300px;
  align-items: center;
}

.aaa_buttonView {
  display: flex;
  width: 252px;
  align-items: center;
}

.aaa_calculation_button {
  display: flex;
  width: 252px;
  margin-left: 24px;
}

.aaa_submit {
  display: flex;
  width: 252px;
  margin-left: 24px;
}

.aa_extraView {
  display: flex;
  height: 24px;
}

.aaa_titleView {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
}

.aaa_titleStyle {
  display: flex;
  font-family: 'RubikMedium';
  font-size: 16px;
  color: #0b2653;
}

.aaa-dialog-class {
  display: flex;
  padding-left: 150px;
  padding-top: 200px;
  align-items: center;
}

.aaa_modal_view {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.aaa_table_view {
  display: flex;
  align-self: center;
  flex-direction: column;
  width: 200px;
}

.aaa_label {
  display: flex;
  font-size: 16px;
  font-family: "RubikRegular";
  color:#0b2653
}

.aaa_value {
  margin-top: 5px;
  display: flex;
  font-size: 20px;
  font-family: "RubikMedium";
  color:#0b2653
}

.aaa_content_style {
  display: flex;
  flex-direction: row;
}