.sem_container {
  display: flex;
  width: 70%;
  
  padding: 1%;
  overflow: hidden;
}
 @media (max-width: 768px) { 
  .sem_container {
     width: 90%;
  }
 }

.sem_content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.sem_formContent {
  display: flex;
  width: 100% !important;
  align-self: center;
  flex-direction: column;
}
.highlight_number{
   font-weight: bold; 
  color: #013F60;;   
  background-color: rgba(255, 228, 102, 0.5); 
  padding: 2px 4px;  
  border-radius: 4px; 
}
.sem_headerView {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

}
 @media (max-width: 768px) { 
  .sem_headerView {
      margin-bottom: 20px;
  }
 }

.sem_headingStyle {
  color: #013F60;
  font-family: RubikRegular;
  font-size: 24px;
  font-weight: 400;
}

.sem_input {
  display: flex;
  width: 50%;
}

.sem_inputsContentView {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  gap: 24px;
}

.sem_labelStyle {
  display: flex;
  color: #013F60;
  font-family: RubikRegular;
  font-size: 16px;
  font-weight: 400;
  margin: 10px 0;
}

.sem_textAreaView {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.sem_textLabelStyle {
  display: flex;
  margin: 10px 0;
  color: #013F60;
  font-family: RubikRegular;
  font-size: 16px;
  font-weight: 500;
  text-align: left;
}

.sem_textAreaStyle {
  display: flex;
  
  height: 100px;
  color: #0b2653;
  font-family: RubikRegular;
  font-size: 14px;
  font-weight: 400;
  border: 1px solid rgba(81, 102, 138, 0.37);
  border-radius: 8px;
  padding:10px;
}

.sem_textAreaStyle:focus {
  border: 1px solid rgba(81, 102, 138, 0.37) !important;
  outline: none !important;

}

.sem_subLabelStyle {
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  padding-left: 5px;
  color: #013F60;
  font-family: RubikRegular;
  font-size: 14px;
  font-weight: 400;
}

.sem_buttonView {
  display: flex;
  width: 30%;
  margin-top: 24px;
  align-self: center;
  align-items: center;
  justify-content: center;
}

.sem_responseContentView {
  display: flex;
  width: 100% !important;
  align-self: center;
  flex-direction: column;
}

.sem_responseView {
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin: 30px;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.sem_successStyle {
  display: flex;
  width: 200px;
  height: 150px;
}

.sem_responseStyle {
  margin-top: 24px;
  display: flex;
  width: 100%;
  color: #013F60;
  font-family: RubikRegular;
  font-size: 20px;
  font-weight: 400;
  text-align: center;
  display: flex;
  justify-content: center;
}