.sb_container {
  display: flex;
  width: 100%;
  height: 70px;
  flex-direction: row;
  gap: 5px;
  align-items: center;
}

.sb_titleStyle {
  display: flex;
  width: 10%;
  height: 100%;
  font-family: 'RubikMedium';
  font-size: 15px;
  color: #0B2653;
  padding: 0 5px;
  align-items: flex-start;
}

.sb_subLabelsView {
  display: flex;
  width: 5%;
  height: 100%;
  flex-direction: column;
}

.sb_labelNValuesView {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
}

.sb_subLabelStyle {
  display: flex;
  width: 100%;
  height: 100%;
  font-family: 'RubikMedium';
  font-size: 12px;
  color: #0B2653;
  align-items: start;
}

.sb_targetNactualView {
  display: flex;
  width: 60%;
  height: 100%;
  flex-direction: column;
  gap: 5px;
}

.sb_valuesView {
  display: flex;
  width: 100%;
  height: 20px;
  flex-direction: row;
}

.split-bar {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
}

.sb_labelStyle {
  font-family: 'RubikRegular';
  font-size: 12px;
  color: #0B2653;
  padding: 0 5px;
}

.bar-segment {
  width: 20px;
}

.db_labelDisplayView {
  display: flex;
  width: 100%;
  margin-top: 10px;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
}

.db_labelNtextView{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.db_labelViewStyle {
  display: flex;
  width: 10px;
  height: 10px;
  margin-right: 5px;
}

.db_labelTextStyle {
  font-family: 'RubikRegular';
  font-size: 10px;
  color: #51668A;
  padding: 0 5px;
}

.db_chartOuterView {
  display: flex;
  width: 25%;
  height: 100%;
  justify-content: space-evenly;
}