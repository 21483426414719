.con_container {
  display: flex;
  height: 100vh;
  overflow: hidden;
  background: #f7f8fa;
  flex-direction: column;
  position: relative;
}

@media screen and (min-width: 100%) {
  .con_container {
    width: 100%
  }
}

.con_content {
  display: flex;
  width: calc(100% - 48px);
  margin: 20px 24px 24px 24px;
  overflow-y: scroll;
  flex-direction: column;
}

@media screen and (max-width: 100%) {
  .con_content {
    width: 100%;
    background-color: green
  }
}

.con_retInput {
  display: flex;
  width: 429px;
  margin-right: 24px;
}

.con_view {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.con_name_view {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  margin-bottom: 50px;
  width: 100%;
}

.con_item_Name {
  display: flex;
  flex-direction: row;
  min-width: 230px;
  flex-basis: calc(50% - 80px);
}

.con_align {
  display: flex;
  flex-direction: row;
}

.con_bottomView {
  display: flex;
  height: 50px;
  background-color: white;
  position: absolute;
  bottom: 0;
  padding-right: 24px;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

.con_buttonView {
  display: flex;
  padding: 10px 40px;
  align-items: flex-end;
  justify-content: flex-end;
}

.con_preview_button {
  display: flex;
  width: 252px;
  margin-left: 24px;
}

.con_submit {
  display: flex;
  width: 252px;
  margin-left: 24px;
}


.con-dialog-class {
  display: flex;
}

.con_modal_view {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 80vh;
  overflow: hidden;
}

.con_modal_title_view {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 24px;
}

.con_modal_title_style {
  justify-self: center;
  text-align: center;
  font-family: "RubikRegular";
  font-size: 20px;
  color: #0b2653;
}

.con_table_view {
  display: flex;
  align-self: center;
  flex-direction: column;
  width: 100%;
  height: 90%;
  overflow-y: auto;
}

.con_label {
  display: flex;
  width: 100%;
  font-size: 16px;
  font-family: "RubikRegular";
  padding: 3px 10px;
}

.con_value {
  display: flex;
  width: 50%;
  font-size: 16px;
  font-family: "RubikRegular";
  padding-left: 20px;
}

.con_dbData {
  display: flex;
  flex-direction: row;
  color: gray;
}

.con_updatedData {
  display: flex;
  flex-direction: row;
  color: #0173b0
}