.clp_container {
  display: flex;
  height: 100%;
  overflow: hidden;
  background: #f7f8fa;
  flex-direction: column;
  position: relative;
}

.clp_content {
  display: flex;
  width: calc(100% - 48px);
  margin: 0px 24px 0 24px;
  overflow-x: hidden;
  overflow-y: scroll;
  flex-direction: column;
  padding-bottom: 50px;
}

.clp_detailsView {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
}

.clp_amtDisplayView {
  display: flex;
  width: 38%;
  height: 52px;
  padding: 0 24px;
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(1, 63, 96, 0.1);
  border-radius: 8px;
  align-items: center;
  justify-content: space-between;
}

.clp_amountLabelStyle {
  font-family: "RubikRegular";
  font-weight: 400;
  font-size: 16px;
  color: #0b2653;
}

.clp_amtNeditView {
  display: flex;
  flex-direction: row;
}

.clp_amountStyle {
  font-family: "RubikMedium";
  font-weight: 500;
  font-size: 16px;
  color: #51668a;
}

.clp_editIconStyle {
  display: flex;
  width: 20px;
  height: 20px;
  margin-left: 16px;
}

.clp_topButtonsView {
  display: flex;
  width: 100%;
  margin: 12px 0 12px 0;
  align-items: center;
  justify-content: center;
}

.clp_chartOuterView {
  display: flex;
  background-color: white;
  box-shadow: 0px 4px 12px rgba(1, 63, 96, 0.1);
  border-radius: 8px;
  padding: 24px;
  overflow: hidden;
  flex-direction: column;
}


.clp_chartView {
  display: flex;
  width: 48%;
  height: 356px;
  background: #FFFFFF;
  box-shadow: 0px 4px 12px rgba(1, 63, 96, 0.1);
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  padding: 34px;
  padding-bottom: 24px;
}

.clp_financialView {
  display: flex;
  width: 100%;
  margin: 0 24px 24px 0;
  flex-direction: column;
}


.clp_disclaimerView {
  display: flex;
  width: 100%;
  margin: 24px 0 24px 0;
  flex-direction: column;
  gap: 12px;
}

.clp_effectiveView {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  gap: 48px;
}

.clp_effectRateStyle{
  display: flex;
  font-family: "RubikMedium";
  font-size: 16px;
  color: #005887;
}

.clp_negativeStyle{
  display: flex;
  font-family: "RubikMedium";
  font-size: 16px;
  color: #005887;
}

.clp_disclaimerStyle {
  display: flex;
  font-family: "RubikRegular";
  font-weight: 400;
  font-size: 14px;
  color: #51668A;
}

.clp_goalView {
  display: flex;
  width: 100%;
}

.clp_bottomView {
  display: flex;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60px;
  padding-top: 10px;
  padding-right: 24px;
  background-color: white;
  align-items: center;
  justify-content: flex-end;
  box-shadow: 1px 1px 4px 1px rgba(6, 73, 109, 0.1);
}

.clp_linkView {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.clp_linkStyle {
  display: flex;
  font-family: "RubikMedium";
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  text-decoration-line: underline;
  color: #005887;
  flex-wrap: nowrap;
}

.clp_buttonView {
  margin-left: 12px;
  display: flex;
  width: 252px;
  height: 48px;
}

.view_modal {
  display: flex;
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding-left: 1%;
  padding-top: 1%;
}

.view_modal-content {
  display: flex;
  flex-direction: column;
  width: 100%;
}


.view_modal_close {
  display: flex;
  color: #aaa;
  font-size: 25px;
  font-weight: bold;
  font-family: 'RubikRegular';
}

.view_modal_close:hover,
.view_modal_close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.view_modal_heading {
  display: flex;
  font-family: 'RubikRegular';
  color: #0B2653;
  padding-top: 4px;
  font-size: 16px;
  font-weight: bold;
  padding-left: 4px;
  justify-content: center;
  align-items: center;
}

.view_modal_title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.view_successModal_bottomView {
  display: flex;
  width: 30%;
  margin-top: 10%;
  align-self: center;
}

.view_successModal_dispaly {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 40px;
}

.view_successModal {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  width: 40%;
  height: 150px;
  margin-top: 20vh;
}

.view_successModal-content {
  display: flex;
  gap: 10px;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.view_successModal_heading {
  display: flex;
  font-family: 'RubikRegular';
  color: #0B2653;
  padding-top: 20px;
  font-size: 16px;
  font-weight: bold;
  padding-left: 4px;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.view_successModal_close {
  display: flex;
  color: #aaa;
  font-size: 25px;
  font-weight: bold;
  font-family: 'RubikRegular';
  align-items: flex-end;
  justify-content: flex-end;
}

.view_successModal_close:hover,
.view_successModal_close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.view_successModal_msg {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.view_successlIcon {
  width: 92px;
  height: 50px;
  display: flex;

}