.s_container {
    display: flex;
    width: 100%;
    height: 100%;
    background-color: white;
    flex-direction: column;
}

.s_titleView {
    display: flex;
    width: 80%;
    align-self: center;
    align-items: center;
    flex-direction: column;
}

.s_titleStyle {
    margin-top: 32px;
    font-family: 'RubikMedium';
    font-size: 24px;
    text-decoration: underline;
    text-decoration-line: underline;
    background: linear-gradient(270deg, #005887 0.01%, #003049 105.19%);
    -webkit-background-clip: text;
    -webkit-background-clip: underline;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    flex: none;
    order: 1;
    flex-grow: 0;
}

.s_contentContainerView {
    display: flex;
    width: 80%;
    align-self: center;
    align-items: center;
}

.s_contentView {
    display: flex;
    width: 100%;
    align-self: center;
    flex-direction: column;
}

.s_inputView {
    display: flex;
    margin: 24px 0 0 24px;
    width: 100%;
    align-self: center;
}


.s_infoView {
    display: flex;
    margin: 2px 0 0 10px;

}

.s_codeStyle {
    display: flex;
    width: 35px;
    font-family: 'RubikRegular';
    font-size: 16px;
    color: #0B2653;
    border-right: 1px solid #51668A;
}

.s_infoStyle {
    font-family: 'RubikRegular';
    font-size: 12px;
    color: #51668A;
}

.s_buttonView {
    display: flex;
    width: 200px;
    height: 48px;
    margin-top: 20px;
    align-self: center;
    align-items: center;
    justify-content: center;
}

.s_linkView {
    display: flex;
    margin: 12px 0px 12px 0;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.s_linkLabelStyle {
    font-family: 'RubikRegular';
    font-size: 18px;
    color: #51668A;
}

.s_linkStyle {
    font-family: 'RubikMedium';
    margin-left: 12px;
    font-size: 18px;
    letter-spacing: 0.01em;
    text-decoration: underline;
    text-decoration-line: underline;
    background: linear-gradient(270deg, #005887 0.01%, #003049 105.19%);
    -webkit-background-clip: text;
    -webkit-background-clip: underline;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    flex: none;
    order: 1;
    flex-grow: 0;
}

.s_labelView {
    display: flex;
    width: 80%;
    align-self: center;
    align-items: center;
    justify-content: center;
}

.s_labelStyle {
    font-family: 'RubikRegular';
    font-size: 14px;
    font-weight: 400;
    color: #51668A;
    text-align: center;
    vertical-align: middle;
}

.s_phoneNoView {
    display: flex;
    margin-top: 12px;
    align-self: center;
    align-items: center;
    flex-direction: row;
}

.s_phoneLinkStyle {
    font-family: 'RubikRegular';
    font-size: 14px;
    color: #51668A;
}

.s_phoneNoStyle {
    font-family: 'RubikRegular';
    margin-left: 8px;
    font-size: 16px;
    color: #0B2653;
}

.s_editStyle {
    display: flex;
    margin-left: 11px;
    width: 11px;
    height: 11px;
}

.s_otpView {
    display: flex;
    margin-top: 17px;
    width: 70%;
    align-self: center;
    align-items: center;
}

.s_resendLinkView {
    display: flex;
    margin-top: 100px;
    align-self: left;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

.s_resendLabelStyle {
    font-family: 'RubikRegular';
    font-size: 16px;
    color: #576268;
}

.s_resendStyle {
    font-family: 'RubikMedium';
    margin-left: 5px;
    font-size: 16px;
    background: linear-gradient(270deg, #003049 0.01%, #005887 105.19%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-decoration-line: underline;
    text-decoration: underline;
}

.s_extraView {
    display: flex;
    height: 20px;
}