.prt_container {
  display: flex;
  height: 100vh;
  overflow: hidden;
  background: #f7f8fa;
  flex-direction: column;
  position: relative;
}

@media screen and (min-width: 100%) {
  .prt_container {
    width: 100%;
  }
}

.prt_content {
  display: flex;
  width: calc(100% - 48px);
  margin: 24px;
  overflow: hidden;
  flex-direction: column;
  padding-bottom: 90px;
}

.prt_titleView {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

.prt_titleStyle {
  display: flex;
  align-items: center;
  font-family: 'RubikMedium';
  font-weight: 500;
  font-size: 24px;
  color: #0B2653;
}

.prt_selectionView {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}

.prt_selectLabelStyle {
  display: flex;
  align-items: center;
  font-family: 'RubikRegular';
  font-weight: 400;
  font-size: 14px;
  color: #0B2653;
}

.prt_categorySelect {
  display: flex;
  padding-left: 10px;
  padding-right: 10px;
  width: 270px;
  height: 40px;
  border: solid 1px gainsboro;
  border-radius: 8px;
  background-color: transparent;
  font-family: 'RubikRegular';
  font-size: 14px;
  color: #0b2653;
}

.prt_categorySelect:active {
  border: solid 1px gainsboro;
}

.prt_categorySelect:focus-within :focus :focus-visible {
  border: solid 1px gainsboro;
}

.prt_bottomView {
  display: flex;
  position: absolute;
  bottom: 0;
  padding-right: 24px;
  width: 100%;
  height: 50px;
  background-color: white;
  align-items: center;
  justify-content: flex-end;
  box-shadow: 1px 1px 4px 1px rgba(6, 73, 109, 0.1);
  gap: 24px;
}

.prt_buttonView {
  display: flex;
  width: 252px;
  align-items: center;
}