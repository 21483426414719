.cm_container {
    flex: 1;
    width: 100%;
    padding: 1%;
    display: flex;
    overflow: auto;
    flex-direction: column;
    background-color: #F7F8FA;
}

.cm_titleView {
    display: flex;
    flex: 0.1;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}

.cm_lableView {
    display: flex;
    align-items: center;
    font-family: 'RubikMedium';
    font-weight: 500;
    font-size: 24px;
    color: #0B2653;
}

.cm_leftContentItems {
    display: flex;
    flex-direction: row;
    gap: 24px;
    align-items: center;
    justify-content: flex-end;
    margin-right: 24px;
}

.cm_searchView {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    margin-right: 20px;
    width: 50%;
    align-items: center;
}

.cusmgmt_button_view {
    display: flex;
    width: 100px;
    flex: 0.03;
    justify-content: center;
    align-items: center;
    padding-right: 3px;
}

.cm_addIconView {
    align-items: center;
    display: flex;
    flex: 0.02;
    justify-content: center;
    padding-right: 4px;
    font-size: 14px;
    font-family: 'RubikRegular';
    color: #1675e0;
    text-decoration: underline;
}

.cm_addIcon {
    align-items: center;
    height: 20px;
    width: 20px;
}

.cm_edit_view {
    display: flex;
    flex-direction: column;
    background: #ffffff;
    box-shadow: 1px 1px 4px 1px rgba(6, 73, 109, 0.1);
    border-radius: 8px;
    gap: 20px;
    position: absolute;
}

.cusmgmt_buttonPosition {
    bottom: 0;
    display: flex;
    width: 50%;
    background-color: white;
}

.cm_tableView {
    display: flex;
    flex: 0.9;
    height: "70%";
    width: "1245px";
    margin-left: "20px";
    background: 'linear-gradient(100deg, #FFCA7A -48.61%, #FFE8AE 100%)';
}

.ag-theme-alpine {
    --ag-header-height: 30px;
    --ag-foreground-color: #0B2653;
    --ag-header-foreground-color: #0B2653;
    --ag-header-background-color: black;
    --ag-font-size: 12px;
    --ag-font-family: 'RubikRegular';
    --ag-border-radius: 5px;
}

.ag-theme-alpine .ag-header {
    font-family: 'RubikRegular';
    font-weight: 600;
    background: linear-gradient(100deg, #FFCA7A -48.61%, #FFE8AE 100%);
}

.ag-theme-alpine .ag-header-group-cell {
    font-size: 12px;
    color: #0B2653;
    font-family: 'RubikRegular';
}

.ag-theme-alpine .ag-header-cell {
    font-size: 12px;
    font-family: 'RubikRegular';
}

.cm_listView {
    width: 100%;
    align-self: center;
}

.cm_titleStyle {
    color: #002d89;
    font-size: 1.5em;
    font-family: "OutfitBold";
}

.cm_bottom_view {
    bottom: 0;
    display: flex;
    width: 50%;
    background-color: white;
    justify-content: flex-end;
    position: absolute;

}

.cm_button_view {
    display: flex;
    width: 252px;
    height: 48px;
    margin-top: 10px;
    margin-right: 24px;
}

.cm_actionTitleView {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.cm_titleStyle {
    font-family: "RubikRegular";
    font-size: 16px;
    color: #013f60;
    display: flex;
    flex-direction: row;
}

.cm_actionHeading {
    font-family: "RubikRegular";
    font-size: 16px;
    color: #013f60;
    flex-direction: row;
    margin-left: 30px;
}

.cm_actionIconStyle {
    display: flex;
    width: 20px;
    height: 20px;
    color: #013f60;
}

.cm_actionClose {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 15px;
}

.cm_actionLink {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;
    flex-direction: column;
}

.addicon {
    height: "10px";
    width: "10px";
}

.cm_unlockLink {
    display: flex;
    gap: 5px;
    align-items: center;
}

.cm_resetpasslink {
    display: flex;
    gap: 5px;
    align-items: center;
}

.cm_statusLink {
    color: #2b85df;
    flex-direction: row;
}

.cm_statusLink:hover {
    text-decoration: underline;
    cursor: pointer;
}

.cm_editIcon:hover {
    cursor: pointer;
}

.cm_statusLabelMan {
    color: red;
    padding-left: 2px;
    font-size: 20px;
}


.ag-header-group-cell-label,
.ag-header-cell-label {
    display: flex;
    justify-content: left;
}