.pdp_container {
  display: flex;
  height: 100vh;
  overflow: hidden;
  background: #F7F8FA;
  flex-direction: column;
  position: relative;
}

.pdp_content_view {
  display: flex;
  width: calc(100% - 48px);
  margin: 0px 24px 24px 24px;
  overflow-y: scroll;
  flex-direction: column;
  margin-bottom: 50px;
}

.pdp_input {
  display: flex;
  width: 429px;
  margin-right: 24px;
}

.pdp_detail_view {
  display: flex;
  flex-direction: column;
}

.pdp_detail_text {
  display: flex;
  font-family: 'RubikRegular';
  font-weight: 500;
  font-size: 18px;
  color: #0B2653;
}

.pdp_inputbox_view {
  display: flex;
  flex-direction: row;
  margin: 33px 24px 33px 0px;
}

.pdp_bottomView {
  display: flex;
  width: 100%;
  height: 50px;
  background-color: white;
  position: absolute;
  bottom: 0;
  align-items: center;
  justify-content: end;
}

.pdp_buttonView {
  display: flex;
  padding: 10px;
}

.pdp_save {
  display: flex;
  width: 252px;
}

@media screen and (min-width: 100%) {
  .pdp_container {
    width: 100%
  }
}

.pdp_seperatorStyle {
  margin: 0px 0px 24px;
  border: 1px solid #e0e0e0;
}

.pdp_retirement_toggle {
  display: flex;
  margin-bottom: 24px;
}

.pdp_toggle_view {
  display: flex;
  flex-direction: row;
  gap: 55px;
}

.pdp_toggle_text {
  display: flex;
  font-family: "RubikRegular";
  font-weight: 400;
  font-size: 16px;
  width: 300px;
  color: #0b2653;
}

.pdp_sliderNLabelView {
  display: flex;
  width: 80%;
  flex-direction: column;
}

.pdp_labelStyle{
  display: flex;
  font-family: "RubikRegular";
  font-weight: 400;
  font-size: 16px;
  width: 300px;
  color: #0b2653;
}

.pdp_sliderView{
  display: flex;
  margin: 35px 0;
}