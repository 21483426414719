.bsh_container {
  display: flex;
  height: 100vh;
  overflow: hidden;
  background: #f7f8fa;
  flex-direction: column;
  position : relative;
}
@media screen and (min-width: 100%) {
  .bsh_container {
    width: 100% 
  }
}
.bsh_content_view {
  display: flex;
  width: calc(100% - 48px);
  margin: 0px 24px 24px 24px;
  overflow-y: scroll;
  flex-direction: column;
  margin-bottom: 50px;
}

.bsh_profile_view {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bsh_icon {
  display: flex;
  margin-bottom: 16px;
}

.bsh_info_view {
  font-family: "RubikRegular";
  font-weight: 400;
  font-size: 16px;
  color: #51668a;
  width: 467px;
  height: 44px;
  text-align: center;
}

.bsh_add_property {
  display: flex;
  width: 252px;
  margin-left: 24px;
  margin-top: 20px;
  height: 48px;
}
