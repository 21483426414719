.circle_container {
    display: inline-block;
    width: 58px;
    height: 58px;
    margin-right: 10px;
    position: relative;
}

.cirle_style {
    display: flex;
    background: #C0C0C0;
    /* opacity: 0.3; */
}

.dot {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 100%;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #0066ff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .inner-dot {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #fff;
  }
  
  .rs-progress-circle-info {
    font-size: 12px !important;
    padding-top: 5px;
  }
  
  