#rhp_container {
  display: flex;
  height: 100vh;
  overflow: hidden;
  background: #f7f8fa;
  flex-direction: column;
  position: relative;
}

@media screen and (min-width: 100%) {
  #rhp_container {
    width: 100%;
  }
}

#rhp_inner_container {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 5%;
  height: 60%;
  margin: 0 24px 24px;
  width: calc(100% - 48px);
}

.rhp_details_text {
  text-align: center;
  font-family: RubikRegular;
  font-size: 18px;
}

#rhp_button_view {
  width: 252px;
  height: 48px;
}

.rhp_image {
  width: 25%;
}