.fi_container {
  display: flex;
  width: 100%;
  flex-direction: column;
  position: relative;
}

#float-label {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 56px;
  position: relative;
}

#float-label input {

  height: 100%;
  outline: 0;
  border: 1px solid rgba(81, 102, 138, 0.37);
  border-radius: 8px;
  background: white;
  font-size: 16px;
  color: #0b2653;
  padding-left: 20px;
  font-family: RubikRegular;
  overflow: hidden;
  display: -webkit-box;
  word-break: break-all;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  align-items: center;
}

#float-label select {
  display: flex;
  justify-content: space-around;
  width: 100%;
  height: 100%;
  outline: 0;
  border: 1px solid rgba(81, 102, 138, 0.37);
  border-radius: 8px;
  background: white;
  font-size: 16px;
  color: #0b2653;
  padding-left: 20px;
  -moz-appearance: none;  
  -webkit-appearance: none; 
  appearance: none;
  padding-right: 10px;
  background-image: url("../../assets/svgs/expand_down.svg");
  background-repeat: no-repeat;
  background-position: 98%;
  background-size: 24px;
  overflow: hidden;
  display: -webkit-box;
  word-break: break-all;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  align-items: center;
}

#float-label input[type="date"] {
  display: flex;
  width: 100%;
  height: 100%;
  outline: 0;
  border: 1px solid rgba(81, 102, 138, 0.37);
  border-radius: 8px;
  font-size: 16px;
  color: #0b2653;
  padding: 0 20px;
}

#float-label input[type="date"][value=""] {
  color: transparent;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: url("../../assets/svgs/ic_date.svg") no-repeat;
  width: 24px;
  height: 24px;
  overflow: hidden;
  cursor: pointer;
  position: absolute;
  right: 7%;
}

#float-label:focus-within input[type="date"] {
  display: flex;
  width: 100%;
  height: 100%;
  outline: 0;
  border: 1px solid rgba(81, 102, 138, 0.37);
  border-radius: 8px;
  font-size: 16px;
  color: #0b2653;
  padding: 0 20px;
}

#float-label [id="title"] {
  font-size: 16px;
  color: rgba(11, 38, 83, 0.37);
  font-weight: 400;
  margin-left: 12px;
  padding-left: 10px;
  padding-right: 10px;
  pointer-events: none;
  position: absolute;
  font-family: RubikRegular;
  transform: translate(0, 18px) scale(1);
  transform-origin: top left;
  transition: all 0.2s ease-out;
  overflow: hidden;
  display: -webkit-box;
  word-break: break-all;
    -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  align-items: center;
}
.required-label {
  color: #666;
}

.required-asterisk {
  color: rgba(252, 95, 95, 0.862);
  margin-left: 2px;
}
#float-label:focus-within [id="title"] {
  position: absolute;
  top: -35px;
  color: #51668a;
  background-color: white;
  transform: translate(0, 30px) scale(0.75);
}

#float-label .Active {
  position: absolute;
  top: -35px;
  color: #51668a;
  background-color: white;
  transform: translate(0, 30px) scale(0.75);
}

#float-label:focus-within input {
  border: 1px solid #51668a;
  overflow: hidden;
  display: -webkit-box;
  word-break: break-all;

  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  align-items: center;
}

#float-label .ActiveInputBox {
  border: 1px solid #51668a;
  overflow: hidden;
  display: -webkit-box;
  word-break: break-all;

  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  align-items: center;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.fi_rupeeSymbol {
  display: flex;
  position: absolute;
  left: 20px;
  top: 20px;
  font-family: "InterRegular";
  font-weight: 400;
  font-size: 16px;
  color: #0b2653;
  text-align: left;
  vertical-align: middle;
}

.fi_percentageSymbol {
  display: flex;
  position: absolute;
  right: 40px;
  top: 20px;
  font-family: "RubikRegular";
  font-weight: 400;
  font-size: 16px;
  color: #013f60;
  text-align: left;
}

.fi_childrenView {
  display: flex;
  position: absolute;
  height: 100%;
  align-items: center;
  right: 8%;
}

.fi_errorMessageView {
  display: flex;
  margin-top: 6px;
}

.fi_errorMessageStyle {
  font-weight: 400;
  font-size: 12px;
  color: #ff0000;
}