.b_container {
  display: flex;
  width: 100%;
  height: 38px;
  cursor: pointer;
  padding: 0 10px;
  border-radius: 30px;
  align-items: center;
  justify-content: center;
  background: linear-gradient(270deg, #0e4969 0.01%, #0173b0 105.19%);
}
@media (max-width: 968px) {
  .b_container {
    width: 95%;
    height: 34px;
    text-wrap: nowrap;
  }
}

.b_empty_button {
  width: 100%;
  height: 38px;
  display: flex;
  cursor: pointer;
  padding: 0 10px;
  border-radius: 30px;
  align-items: center;
  justify-content: center;
  border: 2px solid transparent;
  background: linear-gradient(white, white) padding-box,
    linear-gradient(to right, #0e4969 0.01%, #0173b0 105.19%) border-box;
}

.b_empty_text {
  font-family: RubikMedium;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
  vertical-align: middle;
  background-clip: text;
  background: linear-gradient(to right, #0e4969 0.01%, #0173b0 105.19%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.b_buttonTextStyle {
  font-family: RubikRegular;
  font-size: 16px;
  color: white;
  cursor: pointer;
  text-align: center;
  vertical-align: middle;
  text-wrap: nowrap;
}
