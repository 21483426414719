.ov_container {
    display: flex;
    width: 100%;
    flex-direction: column;

}

.ov_inputsView {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
}

.ov_inputStyle {
    width: 50px;
    height: 50px;
    color: #0B2653;
    background: #FFFFFF;
    border: 1px solid #06496D;
    border-radius: 8px;
    align-items: center;
    justify-content: center;
    text-align: center;
    vertical-align: middle;
    font-weight: 500;
    font-size: 20px;
}

#otp_input:focus-within :focus-visible :active {
    border: 1px solid #06496D;
}

.ov_actionsView {
    display: flex;
    margin-top: 17px;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.ov_showHideView {
    display: flex;
    align-items: center;
    flex-direction: row;
}

.ov_eyeStyle {
    display: flex;
    width: 15px;
    height: 15px;
}

.ov_showHideLabel {
    margin-left: 9px;
    font-weight: 500;
    font-size: 14px;
    background: linear-gradient(270deg, #003049 0.01%, #005887 105.19%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.ov_forgetPinStyle {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-align: right;
    text-decoration-line: underline;
    background: linear-gradient(270deg, #003049 0.01%, #005887 105.19%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;

}

.ov_resultView {}

.ov_matchedStyle {
    font-weight: 500;
    font-size: 14px;
    text-align: right;
    color: #27AE60;
}

.ov_notMatchedStyle {
    font-weight: 500;
    font-size: 14px;
    text-align: right;
    color: #FF0000;
}