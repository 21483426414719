.gdp_container {
  display: flex;
  height: 100vh;
  overflow: hidden;
  background: #f7f8fa;
  flex-direction: column;
  position: relative;
}

.gdp_content_view {
  display: flex;
  width: calc(100% - 48px);
  margin: 0px 24px 24px 24px;
  overflow-y: scroll;
  flex-direction: column;
  margin-bottom: 50px;
}

.gdp_input {
  display: flex;
  width: 429px;
  margin-right: 24px;
}

.gdp_description_view {
  display: flex;
  width: 100%;
  height: calc(100% - 40px);
}

.gdp_input_row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
}

.gdp_sec_row {
  display: flex;
  margin: 33px 0px;
  align-items: center;
}

.gdp_toggle_view {
  display: flex;
  flex-direction: row;
  gap: 55px;
}

.gdp_toggle_text {
  display: flex;
  font-family: "RubikRegular";
  font-weight: 400;
  font-size: 16px;
  max-width: 300px;
  word-wrap: break-word;
  color: #0b2653;
}

.gdp_seperatorStyle {
  margin: 24px 0px;
  border: 1px solid #e0e0e0;
}

.gdp_slider_view {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.gdp_start_year_row {
  display: flex;
  flex-direction: column;
  width: 429px;
}

.gdp_start_text {
  font-family: "RubikRegular";
  font-weight: 400;
  font-size: 16px;
  color: #0b2653;
}

.gdp_slider_content {
  display: flex;
  width: 100%;
  margin: 50px 0px 24px 0px;
}

.gdp_cost_item_view {
  display: flex;
  margin: 24px 0px;
  width: 881px;
}

.gdp_retirement_toggle {
  display: flex;
  margin-bottom: 24px;
  gap: 50px;
}

.gdp_bottomView {
  display: flex;
  width:100%;
  height: 50px;
  background-color: white;
  position: absolute;
  bottom: 0;
  align-items: center;
  justify-content: end;
}

.gdp_buttonView {
  display: flex;
  padding :10px;
}

.gdp_sale_button {
  display: flex;
  width: 252px;
  margin-left: 24px;
}

.gdp_save {
  display: flex;
  width: 252px;
  margin-left: 24px;
}


@media screen and (min-width: 100%) {
  .gdp_container {
    width: 100% 
  }
}