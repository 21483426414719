.card_view_container {
    display: flex;
    width: 429px;
    min-height: 68px;
    border-radius: 8px;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px 2px 8px rgba(1, 63, 96, 0.05);
}

.card_text {
    display: flex;
    margin: 24px;
    color: #51668A;
    font-family: 'RubikRegular';
    font-weight: 400;
    font-size: 16px;
    max-width: 196px;
}

.card_amount {
    margin: 24px;
    font-family: 'RubikRegular';
    font-weight: 500;
    font-size: 24px;
    color: #0B2653;
}