.db_container {
    display: flex;
    height: 100vh;
    overflow: hidden;
    background: #f7f8fa;
    flex-direction: column;
}

@media screen and (min-width: 100%) {
    .db_container {
        width: 100%;
    }
}

.db_content_view {
    display: flex;
    width: calc(100% - 48px);
    height: 100%;
    overflow-y: auto;
    margin: 24px 24px 0;
    padding-bottom: 24px;
    flex-direction: column;
}

.db_content {
    display: flex;
    flex-direction: row;
    gap: 24px;
    justify-content: space-between;
}

.db_networth_view {
    display: flex;
    flex-direction: column;
    background: #FFF1BE;
    box-shadow: 0px 4px 12px rgba(1, 63, 96, 0.1);
    border-radius: 8px;
    padding: 24px;
    align-items: center;
    width: 50%;
    max-width: 100%;
}

@media screen and (max-width: 1200px) {
    .db_networth_view {
        flex-direction: column;
    }
}

.db_valueNchartView {
    display: flex;
    flex-direction: row;
}

.db_newtworth_col {
    display: flex;
    width: 50%;
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
}

@media screen and (max-width: 1200px) {
    .db_newtworth_col {
        align-self: flex-start;
    }
}

.db_networth_title {
    display: flex;
    width: 100%;
    font-family: RubikMedium;
    font-weight: 500;
    font-size: 20px;
    text-align: left;
    text-transform: capitalize;
    color: #0B2653;
    flex-wrap: nowrap;
}

.db_networth_amt {
    margin-top: 24px;
    display: flex;
    font-family: RubikRegular;
    font-weight: 500;
    font-size: 24px;
    text-transform: capitalize;
    color: #0B2653;
}

.db_link {
    display: flex;
    font-family: "RubikRegular";
    font-weight: 500;
    font-size: 16px;
    text-decoration-line: underline;
    color: #0B2653;
    opacity: 0.87;
    cursor: pointer;
    justify-content: center;
    align-self: center;
}

.db_dgchart {
    display: flex;
    flex-direction: row;
    top: 0;
    right: 0;
    align-items: center;
    margin-bottom: 100px;
    position: absolute;
    padding: 12px;
}

@media screen and (max-width: 900px) {
    .db_dgchart {
        display: flex;
        position: absolute;
        top: 0px;
        left: 0px;
        margin-top: -15px;
        margin-left: -10px;
    }
}

.db_date_view {
    font-family: "RubikRegular";
    font-weight: 400;
    font-size: 12px;
    text-transform: capitalize;
    color: #51668A;
    display: flex;
    align-items: center;
}



.db_chart_doughnut_OuterView {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
}

@media screen and (max-width: 1000px) {
    .db_chart_doughnut_OuterView {
        display: flex;
        margin-right: 130px;
        height: 130px;
        width: 200px;
    }
}

.db_port_view {
    display: flex;
    background: #E5F4FF;
    box-shadow: 0px 4px 12px rgba(1, 63, 96, 0.1);
    border-radius: 8px;
    flex-direction: column;
    padding: 24px;
    align-items: center;
    width: 50%;
    max-width: 100%;
}

@media screen and (max-width: 1000px) {
    .db_port_view {
        flex-direction: column;
        align-items: flex-start;
    }
}

@media (max-width: 1000px) {
    .db_port_view {
        width: 50%;
        max-width: none;
        flex-direction: column;
        align-items: flex-start;
        padding: 20px;
    }
}

.db_port_title {
    display: flex;
    width: 100%;
    font-family: RubikMedium;
    font-weight: 500;
    font-size: 20px;
    color: #013F60;
    text-align: left;
}

.db_port_name {
    display: flex;
    font-family: "RubikRegular";
    font-weight: 500;
    font-size: 16px;
    color: #013F60;
    margin-top: 24px;
}

.db_info {
    display: flex;
    width: 20px;
    height: 20px;
}

.db_port_balance_view {
    display: flex;
    flex-direction: row;
    gap: 24px;
    align-items: center;
    width: 100%;
}

@media screen and (min-width: 1200px) {
    .db_port_balance_view {
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
    }
}

.db_link_portfolio {
    display: flex;
    font-family: "RubikRegular";
    font-weight: 500;
    font-size: 16px;
    text-decoration-line: underline;
    color: #0B2653;
    opacity: 0.87;
    cursor: pointer;
    padding-top: 12px;
}

.db_port_balance_container {
    display: flex;
    align-items: flex-start;
    margin: 10px 0;
}

@media screen and (max-width: 1200px) {
    .db_port_balance_container {
        display: flex;
        flex-direction: column;
    }
}

.db_port_balance_text {
    display: flex;
    font-family: "RubikRegular";
    color: #51668A;
    flex-direction: column;
    width: 200px;
    gap: 10px;
    justify-content: flex-start;
    align-items: start;
}

@media screen and (max-width: 1200px) {
    .db_port_balance_text {
        width: 100%;
        padding-bottom: 30px;
    }
}

.db_port_content {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.db_port_balance_title {
    display: flex;
    font-family: RubikRegular;
    font-size: 16px;
    color: #51668A;
    flex-direction: column;
    gap: 10px;
    font-weight: 600;
    margin-bottom: 5px;
}

.db_port_balance_label {
    display: flex;
    width: 120px;
    font-family: RubikRegular;
    color: #051032;
    flex-direction: column;
    font-size: 14px;
}

.db_port_bal_colon {
    display: flex;
    margin: 0 5px;
    font-family: RubikRegular;
    color: #051032;
    flex-direction: column;
}

.db_port_balance {
    display: flex;
    width: 100%;
    font-family: "RubikRegular";
    font-weight: 400;
    font-size: 14px;
    color: #0B2653;
    gap: 20px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.db_toggle_view {
    display: flex;
    flex-direction: row;
    gap: 55px;
}

@media screen and (max-width: 1000px) {
    .db_port_balance {
        gap: 15px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .db_toggle_view {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
}

.db_separatorStyle {
    display: flex;
    border: 1px solid #E0E0E0;
    box-sizing: border-box;
    height: 100%;
    margin: 0 25px;
}

@media screen and (max-width: 1200px) {
    .db_separatorStyle {
        display: none;
    }
}

.db_line_chart_view {
    display: flex;
    width: 100%;
    margin: 24px 0px;
    justify-content: space-between;
}

.db_port_view_title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

@media screen and (max-width: 1000px) {
    .db_port_view_title {
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
    }
}

.db_itemsView {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

@media screen and (max-width: 1000px) {
    .db_itemsView {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
}

.db_chartView {
    display: flex;
    height: 300px;
    align-items: flex-start;
    justify-content: flex-start;
    margin-left: -24px;
}

.db_financialView {
    display: flex;
    width: 65%;
    flex-direction: column;
    align-items: flex-start;
}

.db_gauge_chart {
    background: #FFFFFF;
    box-shadow: 0px 4px 12px rgba(1, 63, 96, 0.1);
    border-radius: 8px;
    display: flex;
    width: 30%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.db_gauge_name {
    display: flex;
    font-family: "RubikRegular";
    font-weight: 500;
    font-size: 16px;
    color: #013F60;
    padding: 24px 0 0 24px;
    text-align: left;
}

.db_gauge_style {
    margin-top: -10px;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
}

.db_bucket_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 24px;
    gap: 24px;
    box-sizing: border-box;
    justify-content: space-between;
    background: #FFF8E9;
    box-shadow: 0px 4px 12px rgba(1, 63, 96, 0.1);
    border-radius: 8px;
    margin: 24px 0;
}

.db_bucketTitleView {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.db_bucketTitleStyle {
    font-family: "RubikRegular";
    font-weight: 500;
    font-size: 16px;
    color: #0B2653;
}

@media screen and (max-width: 1000px) {
    .db_bucket_container {
        flex-direction: column;
        gap: 0;
        padding-right: 0;
    }
}

.db_bucket_view {
    display: flex;
    flex-direction: column;
    margin: 12px 0px;
    justify-items: center;
    align-items: left;
    width: 100%;
    box-shadow: 0px 4px 12px rgba(1, 63, 96, 0.1);
    background: #FFFFFF;
    border-radius: 8px;
    overflow: hidden;
}

@media screen and (max-width: 1000px) {
    .db_bucket_view {
        display: flex;
        max-width: 100%;
        align-items: flex-start;
    }
}

.db_sb_text {
    font-family: "RubikRegular";
    font-weight: 500;
    font-size: 16px;
    color: #0B2653;
    padding: 24px 24px 0px;
}

.db_chartOuterView {
    display: flex;
    width: 95%;
    height: 300px;
    padding: 0px 0px 24px 0px;
    position: relative;
}

@media screen and (max-width: 1000px) {
    .db_chartOuterView {
        width: 100%;
        height: 300px;
        padding-right: 15px;
    }
}

.db_goalView {
    display: flex;
    width: 100%;
    margin: 10px 0;
}

.db_sim_view {
    display: flex;
    background: #FFF4CF;
    border-radius: 8px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 24px;
}

.db_sim_text {
    display: flex;
    font-family: 'RubikMedium';
    font-size: 16px;
    color: #0B2653;
    padding-bottom: 8px;
}

.db_sim_info {
    display: flex;
    font-family: "RubikRegular";
    font-weight: 400;
    font-size: 14px;
    color: #51668A;
}

.db_button_sim {
    display: flex;
    width: 252px;
    height: 48px;
    justify-content: flex-end;
    align-self: flex-end;
}

.db_disclaimerView {
    display: flex;
    width: 70%;
}

.db_disclaimerStyle {
    display: flex;
    font-family: "RubikRegular";
    font-weight: 400;
    font-size: 14px;
    color: #51668A;
}